import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'how-it-works-organizer.header.title',
  },
  pageIntro: {
    id: 'how-it-works-organizer.page-into-text',
  },
  paymentTypes: {
    basicServicesTitle: {
      id: 'how-it-works-organizer.basic-services.title',
    },
    basicServicesPrice: {
      id: 'how-it-works-organizer.basic-services.price',
    },
    basicServicesText: {
      id: 'how-it-works-organizer.basic-services.text',
    },
    additionalServicesTitle: {
      id: 'how-it-works-organizer.additional-services.title',
    },
    additionalServicesText: {
      id: 'how-it-works-organizer.additional-services.text',
    }
  },
  firstSection: {
    title1: {
      id: 'how-it-works.section-1.title-1',
    },
    title2: {
      id: 'how-it-works.section-1.title-2',
    },
    text: {
      id: 'how-it-works.section-1.paragraph-1',
    },
  },
  secondSection: {
    title: {
      id: 'how-it-works.section-2.title-1',
    },
    text: {
      firstParagraph: {
        id: 'how-it-works.section-2.paragraph-1',
      },
      secondParagraph: {
        id: 'how-it-works.section-2.paragraph-2',
      },
      thirdParagraph: {
        id: 'how-it-works.section-2.paragraph-3',
      },
    },
    list: {
      element1: {
        id: 'how-it-works.section-2.list-element-1',
      },
      element2: {
        id: 'how-it-works.section-2.list-element-2',
      },
      element3: {
        id: 'how-it-works.section-2.list-element-3',
      },
      element4: {
        id: 'how-it-works.section-2.list-element-4',
      },
      element5: {
        id: 'how-it-works.section-2.list-element-5',
      },
    },
  },
  thirdSection: {
    title: {
      id: 'how-it-works.section-3.title-1',
    },
    text: {
      id: 'how-it-works.section-3.paragraph-1',
    },
    list: {
      element1: {
        id: 'how-it-works.section-3.list-element-1',
      },
      element2: {
        id: 'how-it-works.section-3.list-element-2',
      },
      element3: {
        id: 'how-it-works.section-3.list-element-3',
      },
      element4: {
        id: 'how-it-works.section-3.list-element-4',
      },
      element5: {
        id: 'how-it-works.section-3.list-element-5',
      },
    },
  },
  registerButton: {
    id: 'how-it-works-organizer.button-text',
  },
});
