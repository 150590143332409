import { defineMessages } from "react-intl";

export default defineMessages({
  explainingMessage: {
    id: "reset-password.explaining-messsage"
  },
  email: {
    placeholder: {
      id: "reset-password.email.placeholder"
    }
  },
  code: {
    placeholder: {
      id: "reset-password.code.placeholder"
    }
  },
  password: {
    placeholder: {
      id: "reset-password.new-password.placeholder"
    }
  },
  verifyModal: {
    title: {
      id: "reset-password.verify-modal.title"
    },
    body: {
      id: "reset-password.verify-modal.body"
    },
    redirectButton: {
      id: "reset-password.verify-modal.button"
    }
  },
  sendCodeButton: {
    id: "reset-password.sendCode.button"
  },
  loginButton: {
    id: "reset-password.backToLogin.button"
  },
  codeSent: {
    id: "reset-password.code-sent"
  },
  UserNotFoundException: {
    id: "login.error.user-not-found"
  },
  InvalidParameterException: {
    id: "login.error.user-not-verified"
  },
  LimitExceededException: {
    id: "login.error.limit-exceeded"
  },
  genericError: {
    id: "login.error.generic-error"
  }
});
