import { call, put } from 'redux-saga/effects';
import { SEND_OUT_SUCCESS, SEND_OUT_FAILED, FETCH_SEND_OUTS_FAILED, FETCH_SEND_OUTS_SUCCESS, FETCH_SEND_OUT_SUCCESS, FETCH_SEND_OUT_FAILED } from "./reducer";
import { sendSendOut, fetchEventSendOuts, fetchEventSendOut } from "./service";

export function* sendSendOutSaga (action) {
  try {
    const response = yield call(() => sendSendOut(action.payload));
    yield put({type: SEND_OUT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: SEND_OUT_FAILED, payload: e});
  }
}

export function* fetchSendOutsSaga (action) {
  try {
    const response = yield call(() => fetchEventSendOuts(action.payload));
    yield put({type: FETCH_SEND_OUTS_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: FETCH_SEND_OUTS_FAILED, payload: e});
  }
}

export function* fetchSendOutSaga (action) {
  try {
    const response = yield call(() => fetchEventSendOut(action.payload));
    yield put({type: FETCH_SEND_OUT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: FETCH_SEND_OUT_FAILED, payload: e});
  }
}