import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'contact.header-title',
  },
  section1: {
    id: 'contact.section1',
  },
  section2: {
    id: 'contact.section2',
  },
});
