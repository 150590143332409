import { connect } from "react-redux";
import { submitGroup, reset } from "../reducer";
import GroupNewBase from "./GroupNewBase";

const mapStateToProps = (state) => {
  const obj = {
    group: state.group.group,
    error: state.group.error,
    status: state.group.status,
    user: state.auth.user,
  };

  return obj;
};

const mapDispatchToProps = (dispatch) => ({
  submitGroup: data => dispatch(submitGroup(data)),
  reset: () => dispatch(reset({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupNewBase);
