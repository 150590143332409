import SendReminderButton from "./SendReminderButton";
import { connect } from "react-redux";
import { sendOfferReminder } from "../../../Offer/reducer";

const mapStateToProps = (state) => ({
  status: state.offers.sendOfferReminderStatus,
  sendOfferReminderGroups: state.offers.sendOfferReminderGroups
});

const mapDispatchToProps = (dispatch) => ({
  sendOfferReminder: data => dispatch(sendOfferReminder(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendReminderButton);
