import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmTitle: {
    id: 'confirm-event.title',
  },
  confirmText1: {
    id: 'confirm-event.text-1',
  },
  confirmText2: {
    id: 'confirm-event.text-2',
  },
  confirmListElement1: {
    id: 'confirm-event.list-element-1',
  },
  confirmListElement2: {
    id: 'confirm-event.list-element-2',
  },
  event: {
    name: {
      id: 'confirm-event.name.placeholder',
    },
    date: {
      id: 'confirm-event.date.placeholder',
    },
    startTime: {
      id: 'confirm-event.startTime.placeholder',
    },
    city: {
      id: 'confirm-event.city.placeholder',
    },
  },
  confirmButton: {
    id: 'confirm-event-button',
  },
  confirmationModalTitle: {
    id: 'confirm-event.confirmation-modal.title',
  },
  confirmationModalText: {
    id: 'confirm-event.confirmation-modal.text',
  },
  eventConfirmedText: {
    id: 'confirm-event.event-confirmed-text',
  },
  cancelConfirmButton: {
    id: 'confirm-event.cancel-confirm-button',
  },
  confirmModalButton: {
    id: 'confirm-event.confirm-button',
  },
});
