export const FETCH_CALENDAR_EVENTS = 'FETCH_CALENDAR_EVENTS';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_FAILED = 'FETCH_CALENDAR_EVENTS_FAILED';

export const fetchEvents = ({startDate, endDate}) => ({
  type: FETCH_CALENDAR_EVENTS,
  payload: {startDate, endDate},
});

export const calendarStatus = {
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  ERROR: 'ERROR',
};

const initialState = {
  events: null,
};

export default function EVENT(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case FETCH_CALENDAR_EVENTS:
      return Object.assign({}, state, {status: calendarStatus.FETCHING});
    case FETCH_CALENDAR_EVENTS_SUCCESS:
      return Object.assign({}, state, {status: calendarStatus.FETCHED, events: action.payload});
    case FETCH_CALENDAR_EVENTS_FAILED:
      return Object.assign({}, state, {status: calendarStatus.ERROR, events: [], error: action.payload});
    default:
      return state;
  }
}
