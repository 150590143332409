import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 75px;
`;

const BarWrapper = styled.div`
  margin: 0 50px;
  position: relative;
  z-index: 1;
`;

const BottomBar = styled.div`
  height: 15px;
  background-color: ${props => props.bg}
`;

const TopBar = styled.div`
  height: 15px;
  top: 0;
  position: absolute;
  background-color: ${props => props.bg};
  width: 25%;
  transition: all 0.5s ease;
`;

const Steps = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  align-items: top;
  margin-top: -29px;
`;

const StepWrapper = styled.div`
  width: 100px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  .step-number {
    display: inline-block;
    text-align: center;
    border: 5px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    border-radius: 100%;
    width: 45px;
    height: 45px;
    line-height: 35px;
    color: ${props => props.color};
    font-weight: bold;
    margin-bottom 13px;
    transition: all 0.3s ease;
  }
  .step-text {
    display: block;
    font-size: 14px;
    transition: all 0.3s ease;
  }
  &.not-active {
    cursor: default;
    .step-number, .step-text {
      color: ${props => props.notActiveColor};
      border-color: ${props => props.notActiveBorder};
    }
  }
`;

const Step = ({ step, currentStep, callback, borderColor, backgroundColor, color, notActiveColor, notActiveBorder }) => {
  const handleClick = () => {
    callback(step.step);
  };

  return (
    <StepWrapper
      className={(step.step > currentStep ? "not-active" : "")}
      onClick={step.step < currentStep ? handleClick : null}
      notActiveBorder={notActiveBorder}
      notActiveColor={notActiveColor}
      color={color}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <span className={"step-number "}>{step.step + 1}</span>
      <span className="step-text">{step.name}</span>
    </StepWrapper>
  );
};

class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.renderStep = this.renderStep.bind(this);
  }

  getBarWidth() {
    if (this.props.currentStep === 0) {
      return 0;
    }

    return (this.props.currentStep / (this.props.states.length - 1)) * 100;
  }

  renderStep(step) {
    return (
      <Step
        key={step.step}
        step={step}
        currentStep={this.props.currentStep}
        callback={this.props.goToStep}
        borderColor={this.props.wrapperBorder}
        backgroundColor={this.props.wrapperBg}
        color={this.props.stepColor}
        notActiveColor={this.props.stepNotActiveColor}
        notActiveBorder={this.props.stepNotActiveBorder}
      />
    );
  }

  render() {
    return (
      <Wrapper>
        <BarWrapper>
          <BottomBar bg={this.props.bottomBarBg} />
          <TopBar
            style={{ width: `${this.getBarWidth()}%` }}
            bg={this.props.topBarBg}
          />
        </BarWrapper>
        <Steps>
          {this.props.states.map(this.renderStep)}
        </Steps>
      </Wrapper>
    );
  }
}

ProgressBar.propTypes = {
  states: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  wrapperBorder: PropTypes.string.isRequired,
  wrapperBg: PropTypes.string.isRequired,
  stepColor: PropTypes.string.isRequired,
  stepNotActiveColor: PropTypes.string.isRequired,
  stepNotActiveBorder: PropTypes.string.isRequired,
  topBarBg: PropTypes.string.isRequired,
  bottomBarBg: PropTypes.string.isRequired,
};

export default ProgressBar;
