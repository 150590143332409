import React, { Component } from "react";
import connect from "react-redux/lib/connect/connect";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Row, Col } from "react-bootstrap/lib";
import {
  H2,
  H3,
  P,
  ItalicP,
  FormGroup,
  ListSelect,
  Input,
  TextLabel,
  TextArea,
  FileUpload,
  GeneralError,
  TextSection,
} from "../../../../../components";
import { SmallGrayLinkButton } from "../../../../../components/Links/index";
import { SmallPinkButton } from "../../../../../components/Buttons";
import messages from "../messages";
import { FormattedMessage, injectIntl } from "react-intl";
import StationsListCheckboxes from "./StationsListCheckboxes";
import {
  required,
  maxLength250,
  minLength3,
  atLeastOneArrayElement,
  validateFileArraySize
} from "../../../../../validators/index";
import { status } from "../reducer";
import moment from "moment";
import styled from "styled-components";

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextLabelNoMargin = TextLabel.extend`
  margin: 0;
`;

const Tag = P.extend`
  margin: 0;
  margin-left: 5px;
  `;

const H2WithLink = H2.extend`
  display: flex;
  justify-content: space-between;
  a {
    margin-top: 0;
  }
`;

const recipientTypes = ["VOLUNTEERS", "CONTACT_PERSONS"];
const totalMaxFileSize = 6.3;

class SendOutsForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mappedRecipientTypes: recipientTypes.map(rt => ({
        id: rt,
        value: this.props.intl.formatMessage({ id: `form.recipientTypeOptions.${rt}` })
      })),
      sendOutSent: false,
    };

    this.handleSendOutSubmit = this.handleSendOutSubmit.bind(this);
    this.validateStations = atLeastOneArrayElement(this.props.intl.formatMessage(messages.form.noStationsError));
    this.validateStations = this.validateStations.bind(this);
    this.validateRecipientTypes = atLeastOneArrayElement(this.props.intl.formatMessage(messages.form.noRecipientTypes));
    this.validateRecipientTypes = this.validateRecipientTypes.bind(this);
    this.validateFileSize = validateFileArraySize(totalMaxFileSize);
    this.getExtraValues = this.getExtraValues.bind(this);
  }

  async componentDidMount() {
    this.props.fetchEventOffers({ eventId: this.props.event.id });

  }

  componentDidUpdate(prevProps) {
    if (this.props.status === status.SEND_SUCCESS && prevProps.status !== status.SEND_SUCCESS) {
      this.props.reset();

      this.setState({ sendOutSent: true }, () => {
        window.setTimeout(() => {
          this.setState({ sendOutSent: false });
        }, 5000);
      });
    }
  }

  getMapCoordinates(stationId) {
    const coordinates = this.props.event.stations.filter(s => s.id === stationId)[0].markerCoordinates;
    return "https://maps.google.com/?q=" + coordinates.lat.toFixed(6) + "," + coordinates.lng.toFixed(6);
  }

  getExtraValues(stations) {
    const extra = stations.map(id => { return {id: id, coordinates: this.getMapCoordinates(id)}})
    return extra; //[{id: 1231, coordinate: 123123, annat: "hej"}, {id: 3123, coordinate: 123128371092, annat: "dasdkhsa"}];
  }

  addExtra = (stations, message, extra) => {
    const keys = ['coordinates']; //Add more keys here to be able to include more tags in a message
    const messages = stations.map(s => {
      let m = message;
      const e = extra.filter(ex => ex.id == s)[0];
      keys.map(k => m = m.replace('\\' + k, e[k]));
      return {id: s, message: m};
    });
    return messages;
  }

  handleSendOutSubmit(fieldValues) {
    const extraValues = this.getExtraValues(fieldValues.stations);
    const messages = this.addExtra(fieldValues.stations, fieldValues.message, extraValues);
    fieldValues.stations.map(id => {
      const newValues = JSON.parse(JSON.stringify(Object.assign(fieldValues, { // This may look bad but is needed to create a DEEP copy of the object.
        eventId: this.props.event.id,
        createdAt: moment().format(),
        stations: [id],
        message: messages.filter(m => m.id == id)[0].message
      })));
      this.props.sendSendOut(newValues);
    })
  }

  renderError() {
    if (this.props.status === status.SEND_FAILED) {
      return (<GeneralError><FormattedMessage {...messages.form.sendError} /></GeneralError>)
    }

    return null;
  }

  renderSuccess() {
    if (this.state.sendOutSent) {
      return (<P><FormattedMessage {...messages.form.sent} /></P>);
    }

    return null;
  }

  render() {
    const linkBase = this.props.isTeamleaderView ? "/my-pages/teamleaders" : "/my-pages/events";
    return (
      <TextSection>
        <FormGroup>
          <H2WithLink><FormattedMessage {...messages.form.title} />
            <SmallGrayLinkButton to={`${linkBase}/${this.props.event.id}/sendouts/list`}><FormattedMessage {...messages.form.previousSendOuts} /></SmallGrayLinkButton></H2WithLink>
          <P><FormattedMessage {...messages.form.info} /></P>
          <ItalicP><FormattedMessage {...messages.form.infoItalic} /></ItalicP>
        </FormGroup>
        <H3><FormattedMessage {...messages.form.receivers.title} /></H3>
        <P><FormattedMessage {...messages.form.receivers.info} /></P>
        <form onSubmit={this.props.handleSubmit(this.handleSendOutSubmit)}>
          <FormGroup>
            <Field
              name='stations'
              stations={this.props.event.stations}
              component={StationsListCheckboxes}
              values={this.props.stations}
              changeValue={this.props.change}
              offers={this.props.eventOffers && this.props.eventOffers.hasOwnProperty(this.props.event.id) ? this.props.eventOffers[this.props.event.id] : []}
              validate={[this.validateStations]}
              groups={this.props.eventOffers[this.props.event.eventId]}
            />
          </FormGroup>
          <Row>
            <Col sm={8}>
              <FormGroup>
                <Field
                  name="recipientTypes"
                  placeholder={this.props.intl.formatMessage(messages.form.recipientTypes)}
                  component={ListSelect}
                  data={this.state.mappedRecipientTypes}
                  valueField="id"
                  textField="value"
                  validate={[this.validateRecipientTypes]}
                />
              </FormGroup>
              <FormGroup>
                <TextLabel><FormattedMessage {...messages.form.subject} /></TextLabel>
                <Field
                  name="subject"
                  placeholder={this.props.intl.formatMessage(messages.form.subject)}
                  type="text"
                  component={Input}
                  validate={[required, minLength3, maxLength250]}
                />
              </FormGroup>
              <FormGroup>
                <TextLabelNoMargin><FormattedMessage {...messages.form.message} /></TextLabelNoMargin>
                <TagWrapper >
                <i className="fas fa-info-circle" title="Tags can be used in the message" styled="margin" ></i>
                  <Tag>Tags: \coordinates</Tag>
                </TagWrapper>
                <Field
                  name="message"
                  placeholder={this.props.intl.formatMessage(messages.form.message)}
                  type="text"
                  component={TextArea}
                  validate={[required]}
                />
              </FormGroup>
              <FormGroup>
                <TextLabel><FormattedMessage {...messages.form.attachments} /></TextLabel>
                <Field
                  name="attachments"
                  placeholder={this.props.intl.formatMessage(messages.form.attachments)}
                  component={FileUpload}
                  buttonText={this.props.intl.formatMessage(messages.form.attachmentsButton)}
                  files={this.props.attachments}
                  changeValue={this.props.change}
                  maxFileSize={totalMaxFileSize}
                  totalFileSize={totalMaxFileSize}
                  validate={[this.validateFileSize]}
                />
              </FormGroup>
            </Col>
          </Row>
          {this.renderError()}
          {this.renderSuccess()}
          <SmallPinkButton
            type="submit"
            busy={this.props.status === status.SENDING}
            label={this.props.intl.formatMessage(messages.form.sendButton)}
          />
        </form>
      </TextSection>
    );
  }

}

SendOutsForm = reduxForm({
  form: "sendOutsForm",
})(SendOutsForm);

const selector = formValueSelector("sendOutsForm");

SendOutsForm = connect(
  state => ({
    initialValues: {
      // Shallow copy of stations and then sort it on officialQuantity
      stations: [],
    },
    stations: selector(state, "stations"),
    attachments: selector(state, "attachments")
  })
)(SendOutsForm);

export default injectIntl(SendOutsForm);
