export const SEND_SEND_OUT = 'SEND_SEND_OUT';
export const SEND_OUT_SUCCESS = 'SEND_OUT_SUCCESS';
export const SEND_OUT_FAILED = 'SEND_OUT_FAILED';
export const FETCH_SEND_OUTS = 'FETCH_SEND_OUTS';
export const FETCH_SEND_OUTS_SUCCESS = 'FETCH_SEND_OUTS_SUCCESS';
export const FETCH_SEND_OUTS_FAILED = 'FETCH_SEND_OUTS_FAILED';
export const FETCH_SEND_OUT = 'FETCH_SEND_OUT';
export const FETCH_SEND_OUT_SUCCESS = 'FETCH_SEND_OUT_SUCCESS';
export const FETCH_SEND_OUT_FAILED = 'FETCH_SEND_OUT_FAILED';

export function sendSendOut (data) {
  return {
    type: SEND_SEND_OUT,
    payload: data,
  };
}

export function fetchSendOuts (data) {
  return {
    type: FETCH_SEND_OUTS,
    payload: data,
  };
}

export function fetchSendOut (data) {
  return {
    type: FETCH_SEND_OUT,
    payload: data,
  };
}

export const status = {
  PENDING: 'PENDING',
  SENDING: 'SENDING',
  SEND_SUCCESS: 'SEND_SUCCESS',
  SEND_FAILED: 'SEND_FAILED',
};

export const fetchStatus = {
  PENDING: 'PENDING',
  FETCHING: 'FETCHING',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
};

export const initialState = {
  status: status.PENDING,
  fetchStatus: fetchStatus.PENDING,
  sendOuts: null,
  error: null,
  sendOut: null,
  fetchSingleStatus: fetchStatus.PENDING,
};


const sortOnCreatedAt = (a,b) => {
  return new Date(b.createdAt) - new Date(a.createdAt);
};

export default function auth(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case SEND_SEND_OUT:
      return Object.assign({}, state, {status: status.SENDING});
    case SEND_OUT_SUCCESS:
      return Object.assign({}, state, {status: status.SEND_SUCCESS});
    case SEND_OUT_FAILED:
      return Object.assign({}, state, {status: status.SEND_FAILED, error: action.payload});
    case FETCH_SEND_OUTS:
      return Object.assign({}, state, {fetchStatus: fetchStatus.FETCHING});
    case FETCH_SEND_OUTS_SUCCESS:
      const sendOuts = action.payload.sort(sortOnCreatedAt);
      return Object.assign({}, state, {fetchStatus: fetchStatus.FETCH_SUCCESS, sendOuts: sendOuts});
    case FETCH_SEND_OUTS_FAILED:
      return Object.assign({}, state, {fetchStatus: fetchStatus.FETCH_FAILED});
    case FETCH_SEND_OUT:
      return Object.assign({}, state, {fetchSingleStatus: fetchStatus.FETCHING});
    case FETCH_SEND_OUT_SUCCESS:
      return Object.assign({}, state, {fetchSingleStatus: fetchStatus.FETCH_SUCCESS, sendOut: action.payload});
    case FETCH_SEND_OUT_FAILED:
      return Object.assign({}, state, {fetchSingleStatus: fetchStatus.FETCH_FAILED});
    default:
      return state;
  }
}