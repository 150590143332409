import { connect } from "react-redux";
import { loginSubmit } from "../../../auth/reducer";
import Login from "./Login";

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => dispatch(loginSubmit(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
