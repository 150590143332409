import StationOffers from "./StationOffers";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  fetchEventOffers,
  fetchAvailableGroups,
  createOffer,
  confirmStationOffers,
  deleteOffer,
  resetAvailableGroups
} from "../../Offer/reducer";

const mapStateToProps = (state, ownProps) => ({
  eventOffers: state.offers.eventOffers,
  availableGroups: state.offers.availableGroups,
  event: ownProps.event,
  deleteOfferError: state.offers.deleteOfferError,
  offerCreateStatus: state.offers.offerCreateStatus,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventOffers: data => dispatch(fetchEventOffers(data)),
  fetchAvailableGroups: data => dispatch(fetchAvailableGroups(data)),
  createOffer: data => dispatch(createOffer(data)),
  //confirmStationOffer: data => dispatch(confirmStationOffer(data)),
  confirmStationOffers: data => dispatch(confirmStationOffers(data)),
  deleteOffer: data => dispatch(deleteOffer(data)),
  resetAvailableGroups: () => dispatch(resetAvailableGroups())
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(StationOffers));
