import React from 'react';
import styled from 'styled-components';
import img from '../../images/VolEx_logo_pos.svg';
import { breakPoints } from '../../constants/styling-constants';

const Wrapper = styled.img`
  width: 150px;
  @media (max-width: ${breakPoints.sm}px) {
    width: 125px;
  }
`;

const Logo = () => (<Wrapper src={img} />);

export default Logo;
