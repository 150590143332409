import { call, put } from 'redux-saga/effects';
import { EVENT_SUCCESS, EVENT_FAILED, EVENT_FETCHED, EVENTS_SUMMARIES_FAILED, EVENTS_SUMMARIES_SUCCESS, EVENT_CONFIRM_FAILED, EVENT_CONFIRM_SUCCESS, EVENT_REMOVE_FAILED, EVENT_REMOVE_SUCCESS } from './reducer';
import { updateEvent, fetchEvent as getEvent, createEvent, getEventsSummaries, confirmEvent, removeEvent as deleteEvent } from './service';

export function* saveEvent (action) {
  try {
    const response = action.payload.id ? yield call(() => updateEvent(action.payload)) : yield call(() => createEvent(action.payload));
    response.id = response.eventId
    yield put({type: EVENT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: EVENT_FAILED, payload: e});
  }
}

export function* confirmEventSaga (action) {
  try {
    const response = action.payload.id ? yield call(() => confirmEvent(action.payload)) : yield call(() => createEvent(action.payload));
    response.id = response.eventId
    yield put({type: EVENT_CONFIRM_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: EVENT_CONFIRM_FAILED, payload: e});
  }
}

export function* fetchEventsSummaries (action) {
  try {
    const response = yield call(() => getEventsSummaries(action.payload)); 
    yield put({type: EVENTS_SUMMARIES_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: EVENTS_SUMMARIES_FAILED, payload: e});
  }
}

export function* fetchEvent (action) {
  try {
    const response = yield call(() => getEvent(action.payload));
    yield put({type: EVENT_FETCHED, payload: response});
  } catch (e) {
    yield put({type: EVENT_FAILED, payload: e});
  }
}

export function* removeEvent (action) {
  try {
    const response = yield call(() => deleteEvent(action.payload));
    response.eventId = action.payload;
    yield put({type: EVENT_REMOVE_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: EVENT_REMOVE_FAILED, payload: e});
  }
}