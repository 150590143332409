import { Auth, API } from 'aws-amplify';

// TODO get current session from store
export const createEvent = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', '/events', request);
    });

export const updateEvent = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: data,
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.put('volex-protected', `/events/${data.id}`, request);
    });

export const confirmEvent = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: {},
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', `/events/${data.id}/confirm`, request);
    });

export const getEventsSummaries = () =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', '/events/summaries', request);
    });

export const removeEvent = (id) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.del('volex-protected', `/events/${id}`, request);
    });

export const fetchEvent = ({id}) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/events/${id}`, request);
    });

export const fetchPublicEvent = ({id}) =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', `/public/events/${id}`, request);
    });

export const getEventsByDateRange = ({startDate, endDate}) =>
  API.get('volex-protected', `/public/events?startDate=${startDate}&endDate=${endDate}`);
