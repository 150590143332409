const devSettings = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:f9014821-b8d0-43e1-a46b-8f6808cc2374',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_7ahzB90Ud',
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '5i944ue34eadh1edvflo7fb7fn',
  },
  API: {
    endpoints: [
      {
        name: 'volex-protected',
        // endpoint: '/dev',
        // For local development use either configs below
        // endpoint: 'https://dev.volex.org/api'
        endpoint: 'http://localhost:3001/api'
      },
    ],
  },
  Storage: {
    bucket: 'volex-dev-uploads', //REQUIRED -  Amazon S3 bucket
    identityPoolId: 'eu-west-1:f9014821-b8d0-43e1-a46b-8f6808cc2374',
    region: 'eu-west-1',
  }
}

const testSettings = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:5acb29b2-c1e5-48cf-85fb-39d5568108a0',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_ZxE3d9Bty',
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '1h3dagdnd2l7subb1b64c9258b',
  },
  API: {
    endpoints: [
      {
        name: 'volex-protected',
        endpoint: '/api',
      },
    ],
  },
  Storage: {
    bucket: 'volex-test-uploads', //REQUIRED -  Amazon S3 bucket
    identityPoolId: 'eu-west-1:5acb29b2-c1e5-48cf-85fb-39d5568108a0',
    region: 'eu-west-1',
  }
};

const prodSettings = {
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:19594308-470a-464d-8bb7-7dbc8030e908',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_NsvBPjzV9',
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: '1ttrklt08d6fc2gul3vqua0l20',
  },
  API: {
    endpoints: [
      {
        name: 'volex-protected',
        endpoint: '/api',
      },
    ],
  },
  Storage: {
    bucket: 'volex-prod-uploads', //REQUIRED -  Amazon S3 bucket
    identityPoolId: 'eu-west-1:19594308-470a-464d-8bb7-7dbc8030e908',
    region: 'eu-west-1',
  }
};

const stage = process.env.REACT_APP_STAGE || 'dev';

export default stage === "dev" ? devSettings : stage === "test" ? testSettings : prodSettings;
