import { Auth, API } from 'aws-amplify';

export const submitAttendance = data =>
  Auth.currentSession()
    .then((session) => {
      const request = {
        body: {
          adultAttendance: data.adultAttendance,
          childAttendance: data.childAttendance,
        },
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.post('volex-protected', `/events/${data.eventId}/attendance/${data.stationId}_${data.groupId}`, request);
    });