import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  H3,
  H4,
  P,
  Input,
  TextSection,
  BorderTable,
  TextLabel,
  FormGroup,
  GeneralError
} from "../../../../../components/index";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { Field, reduxForm } from "redux-form";
import { required, positiveNumbers, integer } from "../../../../../validators/index";
import { Row, Col } from "react-bootstrap/lib";
import { Button } from "../../../../../components/Buttons/index";
import { statuses } from "../reducer";
import moment from "moment";

class GroupAttendance extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isStationOpen: this.isStationOpen(),
      hasValidationError: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  /**
   * Station is open if it is today or in the past
   * @returns {boolean}
   */
  isStationOpen() {
    const today = moment().format("YYYY-MM-DD");
    const start = moment(this.props.station.start).format("YYYY-MM-DD");
    const diff = moment(today).diff(start);

    return diff >= 0;
  }

  onSubmit(fieldValues) {
    try {
      if(parseInt(this.props.offer.volunteers) >= parseInt(fieldValues.adultAttendance) + parseInt(fieldValues.childAttendance)) {
        this.setState({ hasValidationError: false })
        this.props.submitAttendance({
          ...fieldValues,
          eventId: this.props.offer.eventId,
          stationId: this.props.offer.stationId,
          groupId: this.props.offer.groupId,
        });
      } else {
        this.setState({ hasValidationError: true })
      }
      
    } catch (e) {
      console.error(`Failed parsing attendance string with err`)
    }
  }

  renderError() {
    if (this.props.attendance.groups.hasOwnProperty(this.props.offer.groupId) && this.props.attendance.groups[this.props.offer.groupId] === statuses.FAILED) {
      return (
        <GeneralError>
          <FormattedMessage {...messages.groupAttendance.submitError} />
        </GeneralError>
      );
    }
  }

  renderContactPersons() {
    return (
      <FormGroup>
        <TextLabel><FormattedMessage {...messages.groupAttendance.contactPersons} /></TextLabel>
        <BorderTable>
          <thead>
            <tr>
              <th>{this.props.intl.formatMessage(messages.groupAttendance.tableHeader.name)}</th>
              <th>{this.props.intl.formatMessage(messages.groupAttendance.tableHeader.email)}</th>
              <th>{this.props.intl.formatMessage(messages.groupAttendance.tableHeader.phone)}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.offer.group.contactPersons.map((c, index) => {
              return (
                <tr key={index}>
                  <td>{c.name}</td>
                  <td>{c.email.toLowerCase()}</td>
                  <td>{c.phone}</td>
                </tr>
              )
            })}
          </tbody>
        </BorderTable>
      </FormGroup>
    );
  }

  renderForm() {
    return (
      <Row>
        <Col sm={5}>
          <form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            noValidate
          >
            <H4><FormattedMessage {...messages.groupAttendance.formTitle} /></H4>
            {!this.state.isStationOpen
              ? <P><FormattedMessage {...messages.groupAttendance.attendanceNotAvailable} /></P>
              : null
            }
            <Field
              name="adultAttendance"
              placeholder={this.props.intl.formatMessage(messages.groupAttendance.form.adultAttendance)}
              type="number"
              component={Input}
              disabled={!this.state.isStationOpen}
              validate={[required, positiveNumbers, integer]}
            />
            <Field
              name="childAttendance"
              placeholder={this.props.intl.formatMessage(messages.groupAttendance.form.childAttendance)}
              type="number"
              component={Input}
              disabled={!this.state.isStationOpen}
              validate={[required, positiveNumbers, integer]}
            />
            {this.state.hasValidationError &&
              <GeneralError>
                <FormattedMessage {...messages.groupAttendance.attendanceExceedVolunteers} />
              </GeneralError>
            }
            <Button
              busy={this.props.attendance.groups.hasOwnProperty(this.props.offer.groupId) && this.props.attendance.groups[this.props.offer.groupId] === statuses.SUBMITTING}
              label={this.props.intl.formatMessage(messages.groupAttendance.form.submit)}
              disabled={!this.state.isStationOpen}
              />
            {this.renderError()}
          </form>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <TextSection>
        <H3>{this.props.offer.group.name}</H3>
        <Input
          initialValue={this.props.offer.volunteers}
          placeholder={this.props.intl.formatMessage(messages.groupAttendance.volunteers)}
          readonly
          meta={{}}
        />
        {this.renderForm()}
        {this.renderContactPersons()}
      </TextSection>
    );
  }

}

GroupAttendance.propTypes = {
  offer: PropTypes.object.isRequired,
  station: PropTypes.object.isRequired,
};

GroupAttendance = reduxForm({
  enableReinitialize: true,
})(GroupAttendance);

export default injectIntl(GroupAttendance);
