import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap/lib";
import { FormattedMessage, injectIntl } from "react-intl";
import SendReminderButton from "./SendReminderButton";
import SendInvitationButton from "./SendInvitationButton";
import { AddVolunteersFirst } from "./ModalsContent";
import { P, H2, PopUpModal } from "../../../../components";
import messages from "./messages";
import BootstrapTable from "react-bootstrap-table-next";

export const filterStationOffers = ({ offers = [], eventId, stationId }) =>
  (Array.isArray(offers[eventId]) &&
    offers[eventId].filter(offer => offer.stationId === stationId)) ||
  undefined;

class Stations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      field: 'officialQuantity'
    };
    this.toggleAddVolunteersModal = this.toggleAddVolunteersModal.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  toggleAddVolunteersModal(id) {
    this.setState({
      [`showAddVolunteersModal${id}`]: !this.state[
        `showAddVolunteersModal${id}`
      ]
    });
  }

  nextAddVolunteersModal = (currId, nextId) => {
    this.setState({
      [`showAddVolunteersModal${currId}`]: !this.state[
        `showAddVolunteersModal${currId}`
      ],
      [`showAddVolunteersModal${nextId}`]: !this.state[
        `showAddVolunteersModal${nextId}`
        ]
    });
  }

  componentDidMount() {
    this.props.fetchEventOffers({ eventId: this.props.eventId });
  }

  componentWillUnmount() {
    this.props.resetAvailableGroups();
  }

  formatModals(stations) {
    const { availableGroups, ...restProps } = this.props;
    const {
      eventOffers,
      eventId
    } = this.props;

    const mod = (n, m) => {
      return ((n % m) + m) % m;
    }

    return stations.map((station, index) => {
      const modal = (
        <PopUpModal
          title={this.props.intl.formatMessage(messages.addGroup.title)}
          bgColor="#f7f0ee"
          id={`showAddVolunteersModal${station.id}`}
          titleSize={"28px"}
          show={this.state[`showAddVolunteersModal${station.id}`]}
          handleHide={() => this.toggleAddVolunteersModal(station.id)}
          backdrop='static'
        >
          <AddVolunteersFirst
            officialsAllocated={station.officialsAllocated}
            officialsProposed={station.officialsProposed}
            availableGroups={availableGroups[`${eventId}_${station.id}`]}
            officialsDraft={station.officialsDraft}
            officialsMissing={station.officialsMissing}
            station={station}
            offers={station.offers}
            allOffers={eventOffers[eventId] || []}
            toggleModal={() => this.toggleAddVolunteersModal(station.id)}
            nextModal={() => this.nextAddVolunteersModal(station.id, stations[mod(index+1, stations.length)].id)}
            prevModal={() => this.nextAddVolunteersModal(station.id, stations[mod(index-1, stations.length)].id)}
            {...restProps}
          />
        </PopUpModal>)

      return {
        modal,
        ...station
      };
    });
  }

  formatStations() {
    const {
      initialValues: { stations = [] } = {},
      eventOffers,
      eventId
    } = this.props;

    return stations.map((station, index) => {
      const offers = filterStationOffers({
        offers: eventOffers,
        eventId,
        stationId: station.id
      });
      const officialsAllocated = offers
        ? offers
            .filter(offer => offer.status === "ACCEPTED")
            .reduce((allocated, offer) => allocated + +offer.volunteers, 0)
        : 0;
      const officialsDraft = offers
        ? offers
            .filter(offer => offer.status === "DRAFT")
            .reduce((allocated, offer) => allocated + +offer.volunteers, 0)
        : 0;
      const officialsProposed = offers
        ? offers
            .filter(offer => offer.status === "PROPOSAL")
            .reduce((allocated, offer) => allocated + +offer.volunteers, 0)
        : 0;
      let officialsMissing =
        station.officialQuantity - officialsAllocated < 0
          ? 0
          : station.officialQuantity - officialsAllocated;

      return {
        offers,
        officialsAllocated,
        officialsDraft,
        officialsProposed,
        officialsMissing,
        ...station
      };
    });
  }

  handleSort(field, order) {
    this.setState({
      field: field,
      order: order
    });
  }

  render() {
    let formattedStations = this.formatStations();
    formattedStations.sort((a, b) => (a[this.state.field] > b[this.state.field] ? 1 : -1))
    if (this.state.order === "desc") formattedStations.reverse();
    formattedStations = this.formatModals(formattedStations);

    const columns = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
        sort: true
      },
      {
        dataField: "name",
        text: this.props.intl.formatMessage(messages.station.workStation),
        headerStyle: {
          textAlign: "center"
        },
        sort: true,
        onSort: this.handleSort
      },
      {
        dataField: "officialQuantity",
        text: this.props.intl.formatMessage(messages.station.officialQuantity),
        headerStyle: {
          textAlign: "center"
        },
        sort: true,
        onSort: this.handleSort
      },
      {
        dataField: "officialsDraft",
        text: this.props.intl.formatMessage(
          messages.station.officialPreliminary
        ),
        headerStyle: {
          textAlign: "center"
        },
        sort: true,
        onSort: this.handleSort
      },
      {
        dataField: "officialsProposed",
        text: this.props.intl.formatMessage(
          messages.station.officialsAwaitingAnswer
        ),
        headerStyle: {
          textAlign: "center"
        },
        sort: true,
        onSort: this.handleSort
      },
      {
        dataField: "officialsAllocated",
        text: this.props.intl.formatMessage(
          messages.station.officialsAllocated
        ),
        headerStyle: {
          textAlign: "center"
        },
        sort: true,
        onSort: this.handleSort
      },
      {
        dataField: "officialsMissing",
        text: this.props.intl.formatMessage(messages.station.officialsMissing),
        headerStyle: {
          textAlign: "center",
        },
        sort: true,
        onSort: this.handleSort
      }
    ];

    const { initialValues: { stations = [] } = {} } = this.props;

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.toggleAddVolunteersModal(row.id);
      },
    };

    if (this.props.event.status !== "CONFIRMED") {
      return (
        <P>
          <FormattedMessage {...messages.notConfirmedWarning} />
        </P>
      );
    }

    return (
      <Fragment>
        {formattedStations.map(s => {return s.modal})}
        <H2>
          <FormattedMessage {...messages.header.stations} />
        </H2>
        <Row style={{ display: "flex" }}>
          <div style={{marginLeft: "50px"}}> 
            <Row style={{ textAlign: "left" }}>
              <SendInvitationButton 
                eventId={this.props.eventId} 
                eventOffers={this.props.eventOffers}
                confirmStationOffers={this.props.confirmStationOffers}
                intl={this.props.intl}
              />
            </Row>
          </div>
          <div style={{marginLeft: "auto", marginRight: "140px"}}>
            <Row style={{ textAlign: "right" }}>
              <SendReminderButton eventId={this.props.eventId} intl={this.props.intl} />
            </Row>
          </div>
        </Row>
        <br />
        <br />
        {stations.length > 0 && (
          
            <BootstrapTable
              id="mapping_table"
              classes="table-test"
              keyField="id"
              data={formattedStations}
              columns={columns}
              wrapperClasses="table-responsive"
              rowEvents={rowEvents}
              hover
            />
          
        )}
      </Fragment>
    );
  }
}

// This shall change according to how the server serves stationAllocations
let StationsForm = injectIntl(Stations);

StationsForm = connect(state => ({
  initialValues: {
    // Shallow copy of stations and then sort it on officialQuantity
    stations: state.event.event.stations
      ? state.event.event.stations
          .map(s => Object.assign({}, s))
      : [],
  },
}))(StationsForm);

export default StationsForm;
