import { defineMessages } from 'react-intl';

export default defineMessages({
  offers: {
    title: {
      id: 'volunteer-start.my-events.list.title',
    },
    button: {
      id: 'volunteer-start.my-events.handle-event-button',
    },
    rejectedButton: {
      id: 'volunteer-start.my-events.handle-event.rejected-button',
    },
    acceptedButton: {
      id: 'volunteer-start.my-events.handle-event.accepted-button',
    },
    pendingButton: {
      id: 'volunteer-start.my-events.handle-event.pending-proposal-button',
    },
    noOffersMessage: {
      id: 'volunteer-start.my-events.no-offers-message',
    },
    showPreviousEvents: {
      id: 'volunteer-start.my-events.show-previous-events'
    }

  },
});


