import React, { Fragment } from "react";
import styled from "styled-components";
import messages from "./messages";
import { colors } from "../../../../constants/styling-constants";
import { P } from "../../../../components";
import { Row, Col } from "react-bootstrap/lib";

const TextElement = styled(P)`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  padding-top: ${props => props.paddingTop};
`;

function containsNumber(string) {
  if (!string) return false;
  const numberRegEx = /[0-9]/g;
  return string.match(numberRegEx);
}

function containsUpperCase(string) {
  if (!string) return false;
  const upperCase = /^(?=.*[A-Z])/;
  return string.match(upperCase);
}

function containsLowerCase(string) {
  if (!string) return false;
  const lowerCase = /^(?=.*[a-z])/;
  return string.match(lowerCase);
}

function checkLength(string) {
  if (!string) return false;
  return string.length >= 8;
}

const PasswordVerification = ({ password, intl }) => {

  return (
    <Fragment>
      <TextElement
        paddingTop="20px"
        color="white"
        textAlign="center"
      >{intl.formatMessage(messages.passwordRules.title)}</TextElement>
      <Row>
        <Col
          xs={12}
          sm={6}
        >
          <TextElement
            fontSize="12px"
            color={containsLowerCase(password) ? colors.greenEndGradient : colors.lightGray3}
            textAlign="left"
          >{intl.formatMessage(messages.passwordRules.lowerCase)}</TextElement>
          <TextElement
            fontSize="12px"
            color={containsUpperCase(password) ? colors.greenEndGradient : colors.lightGray3}
            textAlign="left"
          >{intl.formatMessage(messages.passwordRules.upperCase)}</TextElement>
        </Col>
        <Col
          xs={12}
          sm={6}
        >
          <TextElement
            fontSize="12px"
            color={containsNumber(password) ? colors.greenEndGradient : colors.lightGray3}
            textAlign="left"
          >{intl.formatMessage(messages.passwordRules.number)}</TextElement>
          <TextElement
            fontSize="12px"
            color={checkLength(password) ? colors.greenEndGradient : colors.lightGray3}
            textAlign="left"
          >{intl.formatMessage(messages.passwordRules.length)}</TextElement>
        </Col>
      </Row>
    </Fragment>
  );

};

export default PasswordVerification;
