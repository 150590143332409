import { connect } from "react-redux";
import { createUser, resetUser } from "../../../containers/User/reducer";
import AddUserForm from "./AddUserForm";

const mapStateToProps = (state, ownProps) => ({
  event: ownProps.event,
  status: state.user.newUserStatus,
  user: state.user.newUser,
  newUserError: state.user.newUserError,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: data => dispatch(createUser(data)),
  resetUser: () => dispatch(resetUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserForm);
