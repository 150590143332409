import React, { Component } from "react";
import {
  H2,
  H4,
  BoldP,
  TableRowWithClick,
  WhiteTableWithLastToRight,
  TableRow,
} from "../../../components/index";
import styled from "styled-components";
import messages from "./messages";
import { colors } from "../../../constants/styling-constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { breakPoints } from "../../../constants/styling-constants";
import { withRouter } from "react-router-dom";
import { TableButton, ToggleButton } from "../../../components/Buttons/index";
import moment from "moment";

const Wrapper = styled.div`
  margin-bottom: 85px;
`;

const EventWrapper = styled.div`
  margin-bottom: 40px;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 13px;
`;

const Table = styled(WhiteTableWithLastToRight)`
  @media (max-width: ${breakPoints.sm}px) {
    .table-button-col {
      display: none;
    }
  }
`;

const TableColumn = styled.td`
  word-wrap: break-word;
  width: 520px;
  max-width: 520px;
  min-width: 520px;
`;

const RedButton = styled(TableButton)`
  background: ${colors.lightPink3};
  border: ${colors.darkPink};
  width: 134px;
  &:hover {
    background: ${colors.darkPink};
  }
`;

const GreyButton = styled(TableButton)`
  background: ${colors.lightGray4};
  border: ${colors.mediumLightGray};
  width: 134px;
  &:hover {
    background: ${colors.mediumLightGray};
  }
`;

const GreenButton = styled(TableButton)`
  background: ${colors.lightGreen};
  border: ${colors.darkGreen};
  color: ${colors.gray};
  width: 134px;

  &:hover {
    background: ${colors.darkGreen};
  }
`;

const DarkGreyButton = styled(TableButton)`
  width: 134px;
`;

class GroupOffers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupOffers: this.filterOffersOnStatus(this.props.groupOffers),
      past: false,
    };

    this.renderRow = this.renderRow.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.togglePast = this.togglePast.bind(this);
  }

  componentDidMount() {
    this.props.fetchGroupsOffers();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      groupOffers: this.filterOffersOnStatus(nextProps.groupOffers),
    });
  }

  filterOffersOnStatus(offers) {
    return offers ? offers.filter((o) => o.status !== "DRAFT") : null;
  }

  handleClick(row) {
    this.props.history.push(
      `/my-pages/volunteers/engagement/${row.eventId}/${row.groupId}/${row.stationId}`
    );
  }
  togglePast() {
    this.setState({past: !this.state.past})
  }


  renderStatus(status) {
    return status;
  }

  sortingFunction(offerA, offerB) {
    const statusOrder = ["PROPOSAL", "ACCEPTED", "REJECTED"];
    if (offerA.status !== offerB.status)
      return statusOrder.indexOf(offerA.status) >
        statusOrder.indexOf(offerB.status)
        ? 1
        : -1;
    if (
      offerA.status === "ACCEPTED" &&
      moment(offerA.station.start).isAfter(new Date())
    )
      return moment(offerA.station.start).isAfter(moment(offerB.station.start)) ? -1 : 1;
    return moment(offerA.station.start).isAfter(moment(offerB.station.start)) ? 1 : -1;
  }

  renderProposalButton(status, startDate) {
    switch (status) {
      case "ACCEPTED":
        if (moment(startDate).isAfter(new Date())) {
          return (
            <GreenButton
              type="button"
              label={this.props.intl.formatMessage(
                messages.offers.acceptedButton
              )}
            />
          );
        } else {
          return (
            <DarkGreyButton
              type="button"
              label={this.props.intl.formatMessage(
                messages.offers.acceptedButton
              )}
            />
          );
        }
      case "REJECTED":
        return (
          <GreyButton
            type="button"
            label={this.props.intl.formatMessage(
              messages.offers.rejectedButton
            )}
          />
        );
      case "PROPOSAL":
        return (
          <RedButton
            type="button"
            label={this.props.intl.formatMessage(messages.offers.pendingButton)}
          />
        );
      default:
        return (
          <TableButton
            type="button"
            label={this.props.intl.formatMessage(messages.offers.button)}
          />
        );
    }
  }

  renderRow(row) {
    return (
      <TableRowWithClick
        key={row.stationIdGroupId}
        row={row}
        callback={this.handleClick}
      >
        <TableColumn>{row.station.name}</TableColumn>
        <td className="right">
          {this.renderProposalButton(
            row.status,
            row.station ? moment(row.station.start).format("YYYY-MM-DD") : null
          )}
        </td>
      </TableRowWithClick>
    );
  }

  renderLoader() {
    return (
      <TableRow>
        <td>
          <i className="fas fa-spinner fa-pulse" />
        </td>
      </TableRow>
    );
  }

  renderNoOffers() {
    return (
      <TableRow>
        <td>
          <FormattedMessage {...messages.offers.noOffersMessage} />
        </td>
      </TableRow>
    );
  }

  renderEventList(offerList, past=false) {
   
    const sortedEvents = offerList.sort((e0, e1) => e0.eventId.localeCompare(e1.eventId));
    const eventList = past ? sortedEvents.filter((offer, index, array) => !index || offer.eventId != array[index - 1].eventId)
    .sort((a,b) => moment(a.station.start).isAfter(moment(b.station.start)) ? -1 : 1) : sortedEvents.filter((offer, index, array) => !index || offer.eventId != array[index - 1].eventId)
    .sort((a,b) => moment(a.station.start).isAfter(moment(b.station.start)) ? 1 : -1);
    return (
      <Wrapper>
        {eventList.map(e => this.renderEvent(e, sortedEvents.filter(offer => offer.eventId === e.eventId)))}
      </Wrapper>
    )

  }

  renderOffers() {
    if (!this.state.groupOffers) {
      return <i className="fas fa-spinner fa-pulse" />;
    }
    if (this.state.groupOffers.length < 1) {
      return (
        <Wrapper>
          <FormattedMessage {...messages.offers.noOffersMessage} />
        </Wrapper>
        )
      }
    else{
      const futureList = this.state.groupOffers && this.state.groupOffers[0].station ? this.state.groupOffers.filter(offer => offer.station.start.localeCompare(new Date().toISOString()) >= 0 && offer.status !== "REJECTED") : [];
      const pastList = this.state.groupOffers && this.state.groupOffers[0].station ? this.state.groupOffers.filter(offer => offer.station.start.localeCompare(new Date().toISOString()) < 0 || offer.status === "REJECTED") : [];
      return(
        <Wrapper>
          {this.renderEventList(futureList)}
          <ToggleButton icon={this.state.past ? "fas fa-chevron-down" : "fas fa-chevron-up"} callback={this.togglePast} label={this.props.intl.formatMessage(messages.offers.showPreviousEvents)} />
          {this.state.past ? this.renderEventList(pastList, true) : ""}
        </Wrapper>
      )
    }
    if (!this.state.groupOffers[0].station) {
      return <i className="fas fa-spinner fa-pulse" />;
    }
    const sortedEvents = this.state.groupOffers.sort((e0, e1) => e0.eventId.localeCompare(e1.eventId));
    const eventList = sortedEvents.filter((offer, index, array) => !index || offer.eventId !== array[index - 1].eventId);
    return (
      <Wrapper>
        {eventList.map(e => this.renderEvent(e, sortedEvents.filter(offer => offer.eventId === e.eventId)))}
      </Wrapper>
    )

  }

  renderEvent(event, offers) {
    const sortedGroups = offers.sort((g0, g1) => g0.groupId.localeCompare(g1.groupId));
    const groupList = sortedGroups.filter((offer, index, array) => !index || offer.groupId !== array[index - 1].groupId).map(g => g.groupName);
    return (
      <EventWrapper key={event.eventId}>
        <EventNameWrapper>
          <H4>
            {event.eventName}
          </H4>
          <H4>
            {event.station ? moment(event.station.start).format("YYYY-MM-DD") : null}
          </H4>
        </EventNameWrapper>
        {groupList.map(groupName => this.renderGroupOffers(groupName, sortedGroups.filter(offer => offer.groupName === groupName)))}
      </EventWrapper>
    )
  }  
  
  renderGroupOffers(groupName, groupOffers) {
    const sortedOffers = groupOffers.sort(this.sortingFunction);
    return (
      <GroupWrapper key={groupName}>
        <BoldP>
          {groupName}
        </BoldP>
        <Table>
          <tbody>
            {sortedOffers.map(this.renderRow)}
          </tbody>
        </Table>
      </GroupWrapper>
    );
  }

  render() {
    return (
      <Wrapper>
        <H2>
          <FormattedMessage {...messages.offers.title} />
        </H2>
        {this.renderOffers()}
      </Wrapper>
    );
  }
}

export default withRouter(injectIntl(GroupOffers));
