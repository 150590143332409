import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../constants/styling-constants";

const StyledCheckbox = styled.input`
  display: none;
  &+ label:before {
    font-family: Font Awesome\\ 5 Free;
    display: inline-block;
    content: "\\F0C8";
    font-size: 20px;
    cursor: pointer;
  }
  &:checked + label:before {
    content: "\\F14A";
  }
  &:disabled + label:before {
    color: ${colors.mediumLightGray};
  }
  &.error + label {
    color: ${colors.darkPink}
  }
`;

// TODO: This can currently not be used as a Redux Form Field Compoenent as it is used as a normal checkbox (see
// StationsListCheckboxes)

const Checkbox = ({ checked, id, label = null, name, className, onChange, disabled = false }) => {
  return (
    <Fragment>
      <StyledCheckbox
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        className={className}
        onChange={onChange}
        disabled={disabled}
      />
      {<label htmlFor={id}>{label}</label>}
    </Fragment>
  )
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired
};

export default Checkbox;
