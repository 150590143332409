import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import VolunteerContextStart from './VolunteerContextStart';
import GroupBase from './GroupBase';
import GroupNewBase from './GroupNewBase';

import styled from 'styled-components';
import { colors } from '../../../constants/styling-constants';
import GroupEngagement from './GroupEngagement';

const Wrapper = styled.div`
  background-color: ${colors.lightGreen};
  flex: 1;
  width: 100%;
`;

const VolunteerRouter = ({ match }) => {
  localStorage.setItem('homePath', 'volunteers');
  return (
    <Wrapper>
      <Switch>
        <Route exact path={`${match.url}`} component={VolunteerContextStart} />
        <Route path={`${match.url}/new`} component={GroupNewBase} />
        <Route path={`${match.url}/engagement/:eventId/:groupId/:stationId`} component={GroupEngagement} />
        <Route path={`${match.url}/:id`} component={GroupBase} />
      </Switch>
    </Wrapper>
  );
};

export default VolunteerRouter;
