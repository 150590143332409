import { defineMessages } from 'react-intl';

export default defineMessages({
  sendReminder: {
    buttonText: {
      id: 'send-reminder.button-text',
    },
    error: {
      id: 'send-reminder.error',
    },
    success: {
      id: 'send-reminder.success',
    },
    noGroupsMatched: {
      id: 'send-reminder.no-groups-matched',
    }
  }
});