import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'team-leader-start.title',
  },
  text: {
    id: 'team-leader-start.text',
  },
  list: {
    title: {
      id: 'team-leader.list.title',
    },
    showButton: {
      id: 'team-leader.list.show-button',
    },
    confirmed: {
      id: 'team-leader.list.confirmed',
    },
    draft: {
      id: 'team-leader.list.draft',
    },
    noEvents: {
      id: 'team-leader.list.no-events',
    },
  },
});
