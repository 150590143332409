import React, { Component, Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import { required, email, integer } from "../../../../validators/index";
import { groupStatus } from "../reducer";
import messages from "./messages";
import { Row, Col } from "react-bootstrap/lib";
import { positiveNumbers } from "../../../../validators/index";
import {
  Input,
  FormSectionTitle,
  FormGroup,
  GeneralError,
} from "../../../../components";
import {
  BigGreenButton,
  BigGrayButton
} from "../../../../components/Buttons/index";
//import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository
//const { foodOptions, shirtSizes } = lists;

function validateEmailComparison(newVolunteer, volunteers, volunteerToEdit) {
  const errors = {};
  if (
    !newVolunteer.email ||
    (volunteers.find(v => v.email === newVolunteer.email) &&
      (!volunteerToEdit ||
        (volunteerToEdit && volunteerToEdit.email !== newVolunteer.email)))
  ) {
    errors.email = (
      <GeneralError>
        <FormattedMessage
          {...messages.volunteers.form.emailAlreadyExistError}
        />
      </GeneralError>
    );
  }
  return Object.keys(errors).length > 0 ? errors : null;
}

/*const withLoadedProps = (LoadedComponent, loadedProps) => props => (
  <LoadedComponent {...loadedProps} {...props} />
);*/

class VolunteerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readonly: false, // !this.isNew(),
      newVolunteerErrors: null,
      submitting: false,
      emailInvalid: null,
      buttonDisabled: false
    };

    this.handleVolunteerSubmit = this.handleVolunteerSubmit.bind(this);
    this.onContactFieldChange = this.onContactFieldChange.bind(this);
    this.fillFormWithVolunteer = this.fillFormWithVolunteer.bind(this);
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.pathname === "/my-pages/volunteers/new"
    ) {
      window.scrollTo(0, 0);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.volunteerToEdit !== this.props.volunteerToEdit &&
      nextProps.volunteerToEdit !== null &&
      !this.state.submitting
    ) {
      this.fillFormWithVolunteer(nextProps.volunteerToEdit);
    }
    if (nextProps.status === groupStatus.SUCCESS && this.state.submitting) {
      this.setState({
        submitting: false
      });
      this.props.reset();
    }
  }

  isNew() {
    return !this.props.match.params.id;
  }

  handleVolunteerSubmit(fieldValues) {
    this.setState({buttonDisabled: true});
    setTimeout(()=>this.setState({buttonDisabled: false}), 500);
    const specialDiets = (fieldValues.specialDiets || []).filter(v => !!v);
    const newVolunteer = Object.assign({}, fieldValues, { specialDiets });
    const volunteersList = this.props.group.volunteers
      ? this.props.group.volunteers.slice()
      : [];
    const errors = validateEmailComparison(
      newVolunteer,
      volunteersList,
      this.props.volunteerToEdit
    );
    if (!errors && !this.state.emailInvalid) {
      this.setState({
        newVolunteerErrors: null,
        submitting: false
      });

      if (this.props.volunteerToEdit) {
        const updatedVolunteerList = volunteersList.map(v =>
          v.id === this.props.volunteerToEdit.id
            ? (v = Object.assign({}, newVolunteer, {
                id: this.props.volunteerToEdit.id
              }))
            : v
        );
        this.props.saveGroup(updatedVolunteerList);
      } else {
        newVolunteer.id = Math.floor(Math.random() * 10000);
        volunteersList.push(newVolunteer);
        this.props.saveGroup(volunteersList);
        if (typeof this.props.goBackToList === "function") {
          this.props.goBackToList();
        }
      }
    } else {
      this.setState({
        newVolunteerErrors: errors
      });
    }
  }

  onContactFieldChange(e) {
    this.setState({
      emailInvalid: e.target.validity.typeMismatch
    });
  }

  fillFormWithVolunteer(volunteer) {
    this.props.change("name", volunteer.name);
    this.props.change("email", volunteer.email);
    this.props.change("phone", volunteer.phone);
    this.props.change("age", volunteer.age);
    //this.props.change("specialDiets", volunteer.specialDiets);
    //this.props.change("shirtSize", volunteer.shirtSize);
  }

  renderVolunteerFormGroup() {
    if (this.state.readonly) {
      return this.renderVolunteers();
    }
    /*const includedSpecialDietsTranslated = foodOptions.map(opt => ({
      id: opt,
      label: this.props.intl.formatMessage({ id: `food.${opt.toLowerCase()}` })
    }));
    const includedShirtSizesTranslated = shirtSizes.map(opt => ({
      id: opt,
      label: this.props.intl.formatMessage({
        id: `shirt-size.${opt.toLowerCase()}`
      })
    }));*/

    const styleRow =
      typeof this.props.goBackToList === "function"
        ? { display: "flex", justifyContent: "center" }
        : {};

    const errors = this.state.newVolunteerErrors || {};
    return (
      <form
        noValidate
        onSubmit={this.props.handleSubmit(this.handleVolunteerSubmit)}
      >
        <Row style={styleRow}>
          <Col sm={8}>
            <FormGroup>
              <FormSectionTitle>
                <FormattedMessage {...messages.volunteers.personalInfoTitle} />
              </FormSectionTitle>
              <Field
                name="name"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.name
                )}
                type="text"
                component={Input}
                validate={[required]}
                readonly={this.state.readonly}
              />
              <Field
                name="email"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.email
                )}
                type="email"
                component={Input}
                validate={[required, email]}
                onChange={this.onContactFieldChange}
                readonly={this.state.readonly}
              />
              {errors.email || null}
              <Field
                name="phone"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.phone
                )}
                type="tel"
                component={Input}
                validate={[required]}
                readonly={this.state.readonly}
              />
              <Field
                name="age"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.age
                )}
                type="number"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={this.state.readonly}
              />
            </FormGroup>
            {/*
            <FormGroup>
              <FormSectionTitle>
                <FormattedMessage {...messages.volunteers.otherInfoTitle} />
              </FormSectionTitle>
              <Field
                name="specialDiets"
                component={withLoadedProps(ListSelect, {
                  display: "inline-block"
                })}
                valueField="id"
                textField="label"
                data={includedSpecialDietsTranslated}
              />
              <Field
                name="shirtSize"
                placeholder={this.props.intl.formatMessage(
                  messages.volunteers.form.shirtSize
                )}
                component={Select}
                valueField="id"
                textField="label"
                data={includedShirtSizesTranslated}
              />
            </FormGroup>
            */}
            {!this.state.readonly ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {typeof this.props.goBackToList === "function" && (
                  <BigGrayButton
                    onClick={() => this.props.goBackToList()}
                    label={this.props.intl.formatMessage(
                      messages.volunteers.form.goBack
                    )}
                  />
                )}
                <BigGreenButton
                  disabled={this.state.buttonDisabled}
                  busy={this.state.submitting}
                  label={this.props.intl.formatMessage(
                    messages.volunteers.form.addButton
                  )}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </form>
    );
  }

  render() {
    return <Fragment>{this.renderVolunteerFormGroup()}</Fragment>;
  }
}

export default reduxForm({
  form: "volunteer"
})(injectIntl(VolunteerForm));
