import { call, put } from 'redux-saga/effects';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  NEW_PASSWORD_REQUIRED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  AUTH_STORED_USER_FAILED,
  REGISTRATION_CONFIRM_SUCCESS,
  REGISTRATION_CONFIRM_FAILED,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILED,
  GET_USER_INFO,
  CHANGE_PASSWORD_FAILED,
  REGISTRATION_RESEND_CODE_SUCCESS,
  REGISTRATION_RESEND_CODE_FAILED,
} from './reducer';
import { login, logout, loadAuthenticatedUser, confirmRegistration, resendConfirmationCode, loadUserInfo, updateUserAttributes, changePassword } from './service';

// payload is an object where code is an exception in https://docs.aws.amazon.com/AWSJavaSDK/latest/javadoc/com/amazonaws/services/cognitoidp/model/AWSCognitoIdentityProviderException.html

export function* loginSaga (action) {
  try {
    const response = yield call(() => login(action.payload));
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put({type: NEW_PASSWORD_REQUIRED, payload: { user: response, email: action.payload.email.toLowerCase() }});
    } else {
      yield put({type: LOGIN_SUCCESS, payload: response});
    }
  } catch (e) {
    yield put({type: LOGIN_FAILED, payload: e});
  }
}

export function* resendConfirmationSaga (action) {
  try {
    const response = yield call(() => resendConfirmationCode(action.payload));
    yield put({type: REGISTRATION_RESEND_CODE_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: REGISTRATION_RESEND_CODE_FAILED, payload: e});
  }
}

export function* confirmRegistrationSaga (action) {
  try {
    const response = yield call(() => confirmRegistration(action.payload));
    yield put({type: REGISTRATION_CONFIRM_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: REGISTRATION_CONFIRM_FAILED, payload: e});
  }
}

export function* logoutSaga () {
  try {
    const response = yield call(() => logout());
    yield put({type: LOGOUT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: LOGOUT_FAILED, payload: e});
  }
}

export function* loadAuthenticatedUserSaga () {
  try {
    const response = yield call(() => loadAuthenticatedUser());
    yield put({type: LOGIN_SUCCESS, payload: response});
  } catch (e) {
    // has not signed in
    yield put({type: AUTH_STORED_USER_FAILED, payload: null});
  }
}

export function* getUserInfoSaga () {
  try {
    const response = yield call(() => loadUserInfo());
    yield put({type: GET_USER_INFO_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GET_USER_INFO_FAILED, payload: e});
  }
}

export function* updateUserInfo (action) {
  try {
    const response = yield call(() => updateUserAttributes(action.payload));
    yield put({type: UPDATE_USER_INFO_SUCCESS, payload: response});
    yield put({type: GET_USER_INFO});
  } catch (e) {
    yield put({type: UPDATE_USER_INFO_FAILED, payload: e});
  }
}

export function* changePasswordSaga (action) {
  try {
    const response = yield call(() => changePassword(action.payload));
    yield put({type: LOGIN_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: CHANGE_PASSWORD_FAILED, payload: e});
  }
}
