import { defineMessages } from 'react-intl';

export default defineMessages({
  form: {
    email: {
      id: 'change-password.form.email.placeholder',
    },
    newPassword: {
      id: 'change-password.form.new-password.placeholder',
    },
  },
  loginButton: {
    id: 'change-password.login-button',
  },
  changePasswordButton: {
    id: 'change-password.change-password-button',
  },
  forgotPassword: {
    id: 'login.forgot-password.link',
  },
  error: {
    UserNotFoundException: {
      id: 'login.error.user-not-found',
    },
    ResourceNotFoundException: {
      id: 'login.error.cognito-service-not-found',
    },
    UserNotConfirmedException: {
      id: 'login.error.user-not-confirmed',
    },
    default: {
      id: 'login.error.unknown-error',
    },
    NotAuthorizedException: {
      id: 'login.error.wrong-password',
    },
    InvalidPasswordException: {
      id: 'change-password.error.password-does-not-meet-policy',
    }
  },
  infoText: {
    id: 'change-password.info-text',
  }
});
