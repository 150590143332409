import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors, breakPoints } from "../../constants/styling-constants";
import { status } from "../../auth/reducer";
import DropDownMenuItem from "./DropDownMenuItem/LanguageContainer";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Wrapper = styled.nav`
  text-align: right;
  @media (max-width: ${breakPoints.md}px) {
    text-align: left;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  transition: transform 0.5s ease;
  @media (max-width: ${breakPoints.md}px) {
    transform: translateX(100%); 
    display: block;
    background-color: ${colors.lightGray3};
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%
    left: 0;
    margin-top: 75px;
    padding-top: 30px;
    z-index: 1000;
    overflow-y: scroll;
    li {
      display: block;
    }
  }
  &.mobile-menu {
    transform: translateX(0);
  }
`;

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 45px;
  padding: 30px 0;
  @media (max-width: ${breakPoints.md}px) {
    margin-left: 25px;
  }
  @media (max-width: ${breakPoints.md}px) {
    margin-left: 50px;
  }
  &.only-mobile {
    @media (min-width: ${breakPoints.md + 1}px) {
      display: none;
    }
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 40px;
  @media (max-width: ${breakPoints.md}px) {
    display: inline-block;
  }
`;

const StyledLink = styled(NavLink)`
  font-size: 16px;
  color: ${colors.text}
  &.active {
    color: ${colors.pink}
  }
  &:hover {
    text-decoration: none;
    color: ${colors.pink};
  }
`;

const tempItems = [
  {
    id: 1,
    labelKey: messages.menuItems.language.swe
  },
  {
    id: 2,
    labelKey: messages.menuItems.language.eng
  },
];

class Menu extends Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.handleOnMobileMenuClick = this.handleOnMobileMenuClick.bind(this);
  }

  handleLogout(e) {
    e.preventDefault();
    this.props.logout();
    if (this.props.location.pathname === "/") {
      window.location.reload();
    } else {
      this.props.history.push("/");
    }
  }

  handleOnMobileMenuClick() {
    if (window.innerWidth <= breakPoints.md) {
      this.props.toggleMobileMenu();
    }
  }

  renderLoggedInLinks() {
    return (
      <Fragment>
        <MenuItem onClick={this.handleOnMobileMenuClick}><StyledLink
          to="/my-pages"
        ><FormattedMessage {...messages.menuItems.myPages} /></StyledLink></MenuItem>
        <MenuItem onClick={this.handleOnMobileMenuClick}><StyledLink
          to="/profile"
        ><FormattedMessage {...messages.menuItems.myProfile} /></StyledLink></MenuItem>
        <MenuItem onClick={this.handleOnMobileMenuClick}><StyledLink
          to="/logout"
          onClick={this.handleLogout}
        ><FormattedMessage {...messages.menuItems.logOut} /></StyledLink></MenuItem>
      </Fragment>
    );
  }

  render() {
    const langListTranslated = tempItems.map(opt => Object.assign({}, opt, { label: this.props.intl.formatMessage(opt.labelKey) }));
    return (
      <Wrapper>

        <MenuList className={`menu-list ${this.props.showMobileMenu ? "mobile-menu" : ""}`}>
          <DropDownMenuItem
            items={langListTranslated}
            selectedItem={1}
          />
          <MenuItem onClick={this.handleOnMobileMenuClick}>
            <StyledLink to="/how-it-works-volunteer"><FormattedMessage {...messages.menuItems.volunteer} /></StyledLink>
          </MenuItem>
          <MenuItem onClick={this.handleOnMobileMenuClick}>
            <StyledLink to="/how-it-works-organizer"><FormattedMessage {...messages.menuItems.organizer} /></StyledLink>
          </MenuItem>
          <MenuItem
            className="only-mobile"
            onClick={this.handleOnMobileMenuClick}
          >
            <StyledLink to="/contact"><FormattedMessage {...messages.menuItems.contact} /></StyledLink>
          </MenuItem>
          {this.props.auth.status !== status.LOGGED_IN
            ? <Fragment>
              <MenuItem onClick={this.handleOnMobileMenuClick}><StyledLink to="/register"><FormattedMessage {...messages.menuItems.register} /></StyledLink></MenuItem>
              <MenuItem onClick={this.handleOnMobileMenuClick}><StyledLink to="/login"><FormattedMessage {...messages.menuItems.login} /></StyledLink></MenuItem></Fragment>
            : this.renderLoggedInLinks()
          }
        </MenuList>
        <MobileMenuButton onClick={this.props.toggleMobileMenu}>
          <i className={`fas ${this.props.showMobileMenu ? "fa-times" : "fa-bars"}`} />
        </MobileMenuButton>
      </Wrapper>
    );
  }
}

export default injectIntl(Menu);
