
import { Auth, API } from 'aws-amplify';

export const getEventsSummaries = () => 
  Auth.currentSession()
    .then((session) => {
      const request = {
        headers: {
          Authorization: session.idToken.jwtToken,
        },
      };
      return API.get('volex-protected', '/teamleader/events/summaries', request);
    });

    export const fetchEvent = (data) => 
    Auth.currentSession()
      .then((session) => {
        const request = {
          headers: {
            Authorization: session.idToken.jwtToken,
          },
        };
        return API.get('volex-protected', `/teamleader/events/${data.id}`, request);
      });