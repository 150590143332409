import React, { Component } from "react";
import styled from "styled-components";
import { H2, WhiteTableWithLastToRight, TableStatus, TableRowWithClick, P } from "../../../../components/index";
import { TableButton } from "../../../../components/Buttons/index";
import messages from "../messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Wrapper = styled.div`
  margin-top: 90px;
  margin-bottom: 85px;
`;

class TeamLeaderList extends Component {
  constructor(props) {
    super(props);
    this.renderRow = this.renderRow.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchTeamleaderEvents();
  }

  handleRowClick(row) {
    this.props.history.push(`/my-pages/teamleaders/${row.id}`);
  }

  renderStatus(status) {
    switch (status) {
      case "CONFIRMED":
        return (<TableStatus><FormattedMessage {...messages.list.confirmed} /></TableStatus>);
      case "DRAFT":
      default:
        return (<TableStatus className="draft"><FormattedMessage {...messages.list.draft} /></TableStatus>);
    }
  }

  renderRow(row) {
    return (
      <TableRowWithClick
        key={row.id}
        row={row}
        callback={this.handleRowClick}
      >
        <td>{row.name}</td>
        <td className="right">{this.renderStatus(row.status)}</td>
        <td><TableButton label={this.props.intl.formatMessage(messages.list.showButton)} /></td>
      </TableRowWithClick>
    );
  }

  renderContent() {
    if (this.props.eventsSummaries === null) {
      return <i className="fas fa-spinner fa-pulse" />;
    } else if (this.props.eventsSummaries.length === 0) {
      return <P><FormattedMessage {...messages.list.noEvents} /></P>
    }

    return (
      <WhiteTableWithLastToRight>
        <tbody>
          {this.props.eventsSummaries.sort((a, b) => a && b && a.name && b.name ? a.name.localeCompare(b.name) : 0).filter(event => event.status === "CONFIRMED").map(this.renderRow)}
        </tbody>
      </WhiteTableWithLastToRight>
    );
  }

  render() {
    return (
      <Wrapper>
        <H2><FormattedMessage {...messages.list.title} /></H2>
        {this.renderContent()}
      </Wrapper>
    );
  }
}

export default injectIntl(TeamLeaderList);
