import React, { Fragment } from "react";
import {
  ContentWrapper,
  Container,
  WhiteH1,
  H2,
  P,
  TextSection,
  ImageBackground
} from "../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import img from "../../images/Volex_bild_undersida9.jpg";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Background = ImageBackground.extend`
  background-image: url(${img});
`;

const PrivacyPolicy = ({ intl }) => (
  <Fragment>
    <Background>
      <Container>
        <WhiteH1><FormattedMessage {...messages.title} /></WhiteH1>
      </Container>
    </Background>
    <ContentWrapper>
      <Container>
        <Row>
          <Col sm={9}>
            <PrivacyPolicyGenerator sections={[...generateSections(intl.formatMessage)]} />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  </Fragment>
);

function* generateParagraphs(section, formatMessage) {
  for (let i = 1; true; i++) {
    const listElementCode = `privacy-policy.section-${section}.paragraph-${i}`;
    const paragraph = formatMessage({ id: listElementCode });
    if (listElementCode === paragraph) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: paragraph
    };
  }
}

function* generateSections(formatMessage) {
  for (let section = 1; true; section++) {
    const sectionTitleKey = `privacy-policy.section-${section}.title`;
    const title = formatMessage({ id: sectionTitleKey });
    if (sectionTitleKey === title) {
      return;
    }
    yield {
      id: section,
      title,
      paragraphs: [...generateParagraphs(section, formatMessage)]
    };
  }
}

const Paragraph = ({ paragraph }) => (
  <P>
    {paragraph.label}
  </P>
);

const Section = ({ section }) => (
  <TextSection>
    <H2>{section.title}</H2>
    {section.paragraphs.map(paragraph => (<Paragraph
      key={paragraph.id}
      paragraph={paragraph}
    />))}
  </TextSection>
);

const PrivacyPolicyGenerator = ({ sections }) => (
  <Fragment>
    {sections.map(section => (<Section
      key={section.id}
      section={section}
    />))}
  </Fragment>
);

export default injectIntl(PrivacyPolicy);
