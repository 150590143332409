import React from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import TeamLeaderStart from './TeamLeaderStart';
import TeamLeaderEvent from './Event';
import styled from 'styled-components';
import { colors } from '../../../constants/styling-constants';

const Wrapper = styled.div`
  flex: 1;
  background-color: ${colors.lightPink};
`;

const TeamLeaderRouter = ({ match }) => {
  localStorage.setItem('homePath', 'teamleaders');
  return (
    <Wrapper>
      <Switch>
        <Route exact path={`${match.url}`} component={TeamLeaderStart} />
        <Route path={`${match.url}/:id`} component={TeamLeaderEvent} />
      </Switch>
    </Wrapper>
  );
};

export default TeamLeaderRouter;
