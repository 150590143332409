import { call, put } from 'redux-saga/effects';
import { TEAMLEADER_EVENTS_SUCCESS, TEAMLEADER_EVENTS_FAILED, TEAMLEADER_EVENT_SUCCESS, TEAMLEADER_EVENT_FAILED } from './reducer';
import { getEventsSummaries, fetchEvent } from './service';

export function* fetchTeamleaderEvents (action) {
  try {
    const response = yield call(() => getEventsSummaries(action.payload));
    yield put({type: TEAMLEADER_EVENTS_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: TEAMLEADER_EVENTS_FAILED, payload: e});
  }
}

export function* fetchTeamleaderEvent (action) {
  try {
    const response = yield call(() => fetchEvent(action.payload));
    yield put({type: TEAMLEADER_EVENT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: TEAMLEADER_EVENT_FAILED, payload: e});
  }
}