import { connect } from "react-redux";
import MyGroupsList from "./MyGroupsList";
import { withRouter } from "react-router";
import { getGroupSummaries } from "../reducer";

const mapDispatchToProps = (dispatch) => ({
  getGroupsSummaries: data => dispatch(getGroupSummaries(data)),
});

const mapStateToProps = state => ({
  groupsSummaries: state.group.groupsSummaries,
  statusGroupSummaries: state.group.statusGroupSummaries
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MyGroupsList));
