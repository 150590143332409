import { call, put } from 'redux-saga/effects';
import { GROUP_AVAIL_SAVE_SUCCESS, GROUP_AVAIL_SAVE_FAILED, GROUP_AVAIL_FETCH_SUCCESS, GROUP_AVAIL_FETCH_FAILED } from './reducer';
import { putGroupAvailabilities, getGroupAvailability } from './service';

export function* saveGroupAvailabilitySaga (action) {
  try {
    const response = yield call(() => putGroupAvailabilities(action.payload));
    yield put({type: GROUP_AVAIL_SAVE_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_AVAIL_SAVE_FAILED, payload: e});
  }
}

export function* getGroupAvailabilitySaga (action) {
  try {
    const response = yield call(() => getGroupAvailability(action.payload));
    yield put({type: GROUP_AVAIL_FETCH_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_AVAIL_FETCH_FAILED, payload: e});
  }
}
