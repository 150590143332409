import { Auth, API } from "aws-amplify";

export const fetchGroupOffers = ({ groupId, eventId }) =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get("volex-protected", eventId ? `/groups/${groupId}/offers?eventId=${eventId}` : `/groups/${groupId}/offers`, request);
  });

export const fetchGroupOffer = ({ eventId, stationId, groupId }) =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get(
      "volex-protected",
      `/offers/groups/${groupId}/${eventId}/${stationId}`,
      request
    );
  });

export const fetchEventOffers = ({ eventId }) =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get("volex-protected", `/events/${eventId}/offers`, request);
  });

export const fetchStationOffers = ({ eventId, stationId }) =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get(
      "volex-protected",
      `/offers/station/${eventId}/${stationId}`,
      request
    );
  });

export const fetchAllOffers = () =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get("volex-protected", "/offers", request);
  });

export const fetchGroupsOffers = () =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get("volex-protected", "/offers/groups", request);
  });

export const fetchAvailableGroups = params => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(param =>
    searchParams.append(param, params[param])
  );
  return Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.get(
      "volex-protected",
      `/groups/available?${searchParams.toString()}`,
      request
    );
  });
};

export const createOffer = data =>
  Auth.currentSession().then(session => {
    const request = {
      body: data,
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.post("volex-protected", "/offers", request);
  });

export const deleteOffer = data =>
  Auth.currentSession().then(session => {
    const request = {
      body: data,
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.del(
      "volex-protected",
      `/offers/${data.eventId}/${data.groupId}/${data.stationId}`,
      request
    );
  });

export const confirmStationOffers = data =>
  Auth.currentSession().then(session => {
      const request = {
      body: data,
      headers: {
        Authorization: session.idToken.jwtToken
        }
      };
    return API.post(
      "volex-protected",
      `/offers/${data.eventId}/confirm`,
      request
    );
  });

export const acceptGroupOffer = data =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.post(
      "volex-protected",
      `/offers/${data.eventId}/${data.groupId}/${data.stationId}/accept`,
      request
    );
  });

export const rejectGroupOffer = data =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.post(
      "volex-protected",
      `/offers/${data.eventId}/${data.groupId}/${data.stationId}/reject`,
      request
    );
  });

export const sendOfferReminder = data =>
  Auth.currentSession().then(session => {
    const request = {
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.post(
      "volex-protected",
      `/offers/${data.eventId}/send-reminder`,
      request
    );
  });

export const updateOfferAttendees = data =>
  Auth.currentSession().then(session => {
    const request = {
      body: data,
      headers: {
        Authorization: session.idToken.jwtToken
      }
    };
    return API.post(
      "volex-protected",
      `/offers/groups/${data.groupId}/${data.eventId}/${data.stationId}`,
      request
    );
  });

// comment out when Paul prepares the endpoint

// export const sendOfferInvitation = data =>
//   Auth.currentSession()
//     .then((session) => {
//       const request = {
//         headers: {
//           Authorization: session.idToken.jwtToken,
//         },
//       };
//       return API.post("volex-protected", `/offers/${data.eventId}/send-invitation`, request);
//     });
