import { defineMessages } from 'react-intl';

export default defineMessages({
  groupAttendance: {
    title: {
      id: 'attendance.group.title'
    },
    volunteers: {
      id: 'attendance.group.volunteers'
    },
    contactPersons: {
      id: 'attendance.group.contact-persons'
    },
    tableHeader: {
      name: {
        id: 'attendance.group.table-header.name'
      },
      email: {
        id: 'attendance.group.table-header.email'
      },
      phone: {
        id: 'attendance.group.table-header.phone'
      }
    },
    formTitle: {
      id: 'attendance.group.form-title'
    },
    form: {
      adultAttendance: {
        id: 'attendance.group.form.adult-attendance'
      },
      childAttendance: {
        id: 'attendance.group.form.child-attendance'
      },
      submit: {
        id: 'attendance.group.form.submit'
      }
    },
    attendanceExceedVolunteers: {
      id: 'attendance.group.form.attendance-exceed-volunteers'  
    },
    submitError: {
      id: 'attendance.group.form.submit-error'
    },
    attendanceNotAvailable: {
      id: 'attendance.group.form.attendance-not-available'
    }
  }

});
