import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap/lib";
import { ContentWrapper, Container, H1, GeneralError } from "../../../../components/index";
import { NavLinkButton } from "../../../../components/Links";
import { status } from "../reducer";
import {
  Switch,
  Route
} from "react-router-dom";
import EventEdit from "../Edit";
import StationsEdit from "../Station";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import StationOffers from "../StationOffers";
import SendOutsForm from "../SendOuts/SendOutsForm";
import SendOutsList from "../SendOuts/SendOutsList";
import SendOut from "../SendOuts/SendOut";
import AttendanceList from "../Attendance";
import StationAttendance from "../Attendance/StationAttendance";

const NavigationWrapper = styled.nav`
  margin-bottom: 90px;
`;

class EventBase extends Component {
  componentDidMount() {
    this.props.fetchEvent({ id: this.props.match.params.id });
  }

  componentWillUnmount() {
    this.props.reset();
  }

  renderContent() {
    const isTeamleaderView = this.props.isTeamleaderView;

    if (this.props.status === status.FETCHING) {
      return (
        <i className="fas fa-spinner fa-pulse" />
      );
    }

    if (this.props.status === status.FAILED) {
      return <GeneralError><FormattedMessage {...messages.error} /></GeneralError>;
    }

    if (this.props.event) {
      const linkBase = isTeamleaderView ? "/my-pages/teamleaders" : "/my-pages/events";

      return (
        <Fragment>
          <Row>
            <Col sm={12}>
              <H1>{this.props.event.name}</H1>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <NavigationWrapper>
                <NavLinkButton
                  to={`${linkBase}/${this.props.event.id || this.props.event.eventId}`}
                  exact
                ><FormattedMessage {...messages.eventDataLink} /></NavLinkButton>
                <NavLinkButton to={`${linkBase}/${this.props.event.id || this.props.event.eventId}/stations`}><FormattedMessage {...messages.stationLink} /></NavLinkButton>
                {this.props.isTeamleaderView
                  ? null
                  :
                  <NavLinkButton to={`${linkBase}/${this.props.event.id || this.props.event.eventId}/match`}><FormattedMessage {...messages.matchLink} /></NavLinkButton>
                }
                <NavLinkButton to={`${linkBase}/${this.props.event.id || this.props.event.eventId}/sendouts`}><FormattedMessage {...messages.sendOutsLink} /></NavLinkButton>
                <NavLinkButton to={`${linkBase}/${this.props.event.id || this.props.event.eventId}/attendance`}><FormattedMessage {...messages.attendanceLink} /></NavLinkButton>
              </NavigationWrapper>
            </Col>
          </Row>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              render={() => <EventEdit
                event={this.props.event}
                eventId={this.props.match.params.id}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            <Route
              exact
              path={`${this.props.match.url}/stations`}
              render={() => <StationsEdit
                event={this.props.event}
                eventId={this.props.match.params.id}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            {this.props.isTeamleaderView
              ? null
              : <Route
                exact
                path={`${this.props.match.url}/match`}
                render={() => <StationOffers
                  event={this.props.event}
                  eventId={this.props.match.params.id}
                />}
              />
            }
            <Route
              exact
              path={`${this.props.match.url}/sendouts/list`}
              render={() => <SendOutsList
                event={this.props.event}
                eventId={this.props.match.params.id}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            <Route
              exact
              path={`${this.props.match.url}/sendouts/:sendOutId`}
              render={() => <SendOut
                event={this.props.event}
                eventId={this.props.match.params.id}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            <Route
              exact
              path={`${this.props.match.url}/sendouts`}
              render={() => <SendOutsForm
                event={this.props.event}
                eventId={this.props.match.params.id}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            <Route
              exact
              path={`${this.props.match.url}/attendance/:stationId`}
              render={({ match }) => <StationAttendance
                event={this.props.event}
                eventId={this.props.match.params.id}
                stationId={match.params.stationId}
                isTeamleaderView={isTeamleaderView}
              />}
            />
            <Route
              exact
              path={`${this.props.match.url}/attendance`}
              render={() => <AttendanceList
                event={this.props.event}
                eventId={this.props.match.params.id}
              />}
              isTeamleaderView={isTeamleaderView}
            />
          </Switch>
        </Fragment>
      );
    }
  }

  render() {
    return (
      <ContentWrapper>
        <Container>
          {this.renderContent()}
        </Container>
      </ContentWrapper>
    );
  }
}

EventBase.propTypes = {
  isTeamleaderView: PropTypes.bool.isRequired,
}

export default EventBase;
