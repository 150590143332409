import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'privacy-policy.header-title',
  },
  section1: {
    title: {
      id: 'privacy-policy.section-1.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-1.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-1.paragraph-2',
    },
  },
  section2: {
    title: {
      id: 'privacy-policy.section-2.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-2.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-2.paragraph-2',
    },
    paragraph3: {
      id: 'privacy-policy.section-2.paragraph-3',
    },
    paragraph4: {
      id: 'privacy-policy.section-2.paragraph-4',
    },
  },
  section3: {
    title: {
      id: 'privacy-policy.section-3.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-3.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-3.paragraph-2',
    },
    paragraph3: {
      id: 'privacy-policy.section-3.paragraph-3',
    },
  },
  section4: {
    title: {
      id: 'privacy-policy.section-4.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-4.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-4.paragraph-2',
    },
  },
  section5: {
    title: {
      id: 'privacy-policy.section-5.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-5.paragraph-1',
    },
  },
  section6: {
    title: {
      id: 'privacy-policy.section-6.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-6.paragraph-1',
    },
  },
  section7: {
    title: {
      id: 'privacy-policy.section-7.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-7.paragraph-1',
    },
  },
  section8: {
    title: {
      id: 'privacy-policy.section-8.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-8.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-8.paragraph-2',
    },
  },
  section9: {
    title: {
      id: 'privacy-policy.section-9.title',
    },
    paragraph1: {
      id: 'privacy-policy.section-9.paragraph-1',
    },
    paragraph2: {
      id: 'privacy-policy.section-9.paragraph-2',
    },
    paragraph3: {
      id: 'privacy-policy.section-9.paragraph-3',
    },
  },
});
