import { connect } from "react-redux";
import { fetchEvent, submitEvent, reset } from "../reducer";
import EventBase from "./EventBase";

const mapStateToProps = (state) => {
  const obj = {
    event: state.event.event,
    error: state.event.error,
    status: state.event.status,
    isTeamleaderView: false,
  };

  return obj;
};

const mapDispatchToProps = (dispatch) => ({
  fetchEvent: data => dispatch(fetchEvent(data)),
  submitEvent: data => dispatch(submitEvent(data)),
  reset: () => dispatch(reset({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventBase);
