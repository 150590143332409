import React, { Component, Fragment } from "react";
import { Button, BigGrayButton } from "../../../../components/Buttons";
import { eventStatus } from "../reducer";
import { status as userEmailStatus } from "../../../User/reducer";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import { municipalities } from "../../../../constants/municipalities";
import {
  required,
  unique,
  positiveNumbers,
  integer,
  maxLength250
} from "../../../../validators/index";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import {
  H2,
  TextLabel,
  FormGroup,
  Input,
  TextArea,
  MultiSelect,
  DatePicker,
  Select,
} from "../../../../components/index";
import { FormattedMessage, injectIntl } from "react-intl";
import AddUserForm from "../../../../components/ContactForm/AddUserForm/index";
import messages from "./messages";
import { getUserByEmail } from "../../../User/service";
import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository
import MapsComponent, {
  formatCoordinates,
  getCoordinatesFromLocation,
  getLocationFromCoordinates
} from '../../../../components/Map';

const { skills, payments, currencies } = lists;

const FormWrapper = styled.form`
  margin-top: 80px;
`;

const dateRangeValidation = stationIndex => (value, event) => {
  const start = moment(event.stations[stationIndex].start).format();
  const end = moment(value).format();

  return end < start ? "Slutdatum måste vara senare än startdatum" : undefined;
};

class StationEditForm extends Component {
  constructor(props) {
    super(props);

    const markerLocation = this.props.event.stations[this.props.stationKey].markerLocation;
    const markerCoordinates = this.props.event.stations[this.props.stationKey].markerCoordinates;

    this.state = {
      submitting: false,
      uniqueNameValidator: this.uniqueNameValidator(),
      showAddUserModal: false,
      showMarker: !!markerCoordinates,
      markerLocation: markerLocation,
      markerCoordinates: markerCoordinates,
      center: markerCoordinates
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleMarkerChange = this.handleMarkerChange.bind(this);
    this.handleMarkerRemove = this.handleMarkerRemove.bind(this);
    this.renderEditForm = this.renderEditForm.bind(this);
    this.renderAddUserModal = this.renderAddUserModal.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderSkills = this.renderSkills.bind(this);
    this.handleStationSubmit = this.handleStationSubmit.bind(this);
    this.uniqueNameValidator = this.uniqueNameValidator.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.toggleAddUserModal = this.toggleAddUserModal.bind(this);

    this.dataRangeIndexValidation = dateRangeValidation(this.props.stationKey);

    if (!this.state.center) {
      this.setCoordinates(this.props.event.city);
    }
  }

  uniqueNameValidator() {
    return this.props.event
      ? unique(
        this.props.event.stations.filter(
          s =>
            s.name !== this.props.event.stations[this.props.stationKey].name
        ),
        this.props.intl.formatMessage(messages.stationNameAlreadyExistError)
      )
      : () => {};
  }

  handleStationSubmit(fieldValues) {
    if (this.state.markerCoordinates ||
      (this.props.event.stations[this.props.stationKey].markerCoordinates && !this.state.markerCoordinates)) {
      fieldValues.stations[this.props.stationKey].markerLocation = this.state.markerLocation;
      fieldValues.stations[this.props.stationKey].markerCoordinates = this.state.markerCoordinates;
    }

    if(fieldValues.stations[this.props.stationKey].teamLeaderEmail) {
      getUserByEmail({email: fieldValues.stations[this.props.stationKey].teamLeaderEmail})
      .then(resp => {
        this.setState({
          submitting: true,
        });
        this.props.submitEvent(fieldValues);
      }).catch(e => this.toggleAddUserModal());
    } else {
      this.setState({
        submitting: true,
      });
      this.props.submitEvent(fieldValues);
    }
  }

  /**
   * Call touch on the start or end to validate when one changes
   */
  handleChangeStart(event, newValue, previousValue, name) {
    const nameStart = name.substring(0, name.lastIndexOf("][") + 1);
    this.props.touch(`${nameStart}[end]`);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      uniqueNameValidator: this.uniqueNameValidator()
    })
    if (nextProps.newUserStatus === userEmailStatus.SUBMIT_SUCCESS && this.props.newUserStatus !== userEmailStatus.SUBMIT_SUCCESS) {
      this.toggleAddUserModal();
    }
    if (
      nextProps.status === eventStatus.SUCCESS &&
      this.props.status === eventStatus.SUBMITTING &&
      this.state.submitting
    ) {
      this.setState({
        submitting: false,
      });
      this.props.toggleEditForm();
    }
  }

  toggleAddUserModal() {
    this.setState({ showAddUserModal: !this.state.showAddUserModal });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.stationKey !== this.props.stationKey) {
      const markerLocation = this.props.event.stations[this.props.stationKey].markerLocation;
      const markerCoordinates = this.props.event.stations[this.props.stationKey].markerCoordinates;

      this.setState({
        center: markerCoordinates,
        markerLocation: markerLocation,
        showMarker: !!markerCoordinates,
        markerCoordinates: markerCoordinates
      });

      if (!markerCoordinates) {
        this.setCoordinates(this.props.event.city);
      }
    }
  }

  renderMunicipalities(id) {
    return (
      <Field
        placeholder={this.props.intl.formatMessage(
          messages.station.form.municipality
        )}
        data={municipalities}
        valueField="id"
        filter
        textField="name"
        validate={[required]}
        name={`[stations][${id}][municipality]`}
        component={Select}
        readonly={this.props.isTeamleaderView}
        onChange={(fieldValue) => this.setCoordinates(municipalities.find(m => m.id === fieldValue).name)}
      />
    );
  }

  renderSkills(id) {
    const skillOptions = skills.map(skill => ({
      id: skill,
      name: this.props.intl.formatMessage({ id: `lists.skills.${skill}` })
    }));
    return (
      <Field
        placeholder={this.props.intl.formatMessage(
          messages.volunteer.form.skills
        )}
        data={skillOptions}
        valueField="id"
        textField="name"
        name={`[stations][${id}][skills]`}
        component={MultiSelect}
        readonly={this.props.isTeamleaderView}
      />
    );
  }

  setCoordinates(location) {
    getCoordinatesFromLocation(location).then(coordinates =>
      this.setState({center: coordinates})
    )
  }

  handleMarkerChange(coordinates) {
    getLocationFromCoordinates(coordinates).then(location => {
      this.setState({
        markerLocation: location,
        markerCoordinates: coordinates,
        showMarker: true
      })
    })
  }
  
  handleMarkerRemove() {
    this.setState({
      markerLocation: null,
      markerCoordinates: null,
      showMarker: false
    });
  }

  handleSearch(place) {
    this.setState({
      center: place.geometry.location
    });
  }

  handlePaymentChange(PaymentTypeFieldValue) {
    switch (PaymentTypeFieldValue) {
      case "GROUPAYMENT":
        this.props.change(`[stations][${this.props.stationKey}][paymentAdult]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentAdult]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentChild]`, "");
        this.props.change(`[stations][${this.props.stationKey}][compensationByAgreement]`, "");
        break;
      case "COMPENSATIONBYAGREEMENT":
        this.props.change(`[stations][${this.props.stationKey}][groupPayment]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentAdult]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentChild]`, "");
        this.props.change(`[stations][${this.props.stationKey}][currency]`, "");
        break;
      case "NOCOMPENSATION":
        this.props.change(`[stations][${this.props.stationKey}][groupPayment]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentAdult]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentChild]`, "");
        this.props.change(`[stations][${this.props.stationKey}][currency]`, "");
        break;
      case "PERPERSON":
        this.props.change(`[stations][${this.props.stationKey}][groupPayment]`, "");
        this.props.change(`[stations][${this.props.stationKey}][compensationByAgreement]`, "");
        break;
      case "HOURLYRATE":
        this.props.change(`[stations][${this.props.stationKey}][groupPayment]`, "");
        this.props.change(`[stations][${this.props.stationKey}][compensationByAgreement]`, "");
        break;
      default: // Same as COMPENSATIONBYAGREEMENT
        this.props.change(`[stations][${this.props.stationKey}][groupPayment]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentAdult]`, "");
        this.props.change(`[stations][${this.props.stationKey}][paymentChild]`, "");
        this.props.change(`[stations][${this.props.stationKey}][currency]`, "");
        break;
    }
    this.props.handlePaymentChange(PaymentTypeFieldValue);
  }

  renderAddUserModal() {
    const email = this.state.teamLeaderEmail

    return (
      <AddUserForm
        show={this.state.showAddUserModal}
        hide={this.toggleAddUserModal}
        intl={this.props.intl}
        newEmail={email}
        type={this.props.type}
      />
    )
  }

  renderEditForm(id) {
    const paymentOptions = payments.map(payment => ({
      id: payment,
      name: this.props.intl.formatMessage({ id: `lists.payments.${payment}` })
    }));
    const currencyOptions = currencies.map(currency => ({
      id: currency,
      name: this.props.intl.formatMessage({
        id: `lists.currencies.${currency}`
      })
    }));

    return (
      <FormWrapper
        onSubmit={this.props.handleSubmit(this.handleStationSubmit)}
        noValidate
      >
        <Row>
          <Col sm={6}>
            <H2>
              <FormattedMessage {...messages.stationEdit.formTitle} />
            </H2>
            {this.props.isTeamleaderView ? null : (
              <TextLabel>
                <FormattedMessage {...messages.station.form.title} />
              </TextLabel>
            )}
            <FormGroup>
              <Field
                name={`[stations][${id}][name]`}
                placeholder={this.props.intl.formatMessage(
                  messages.stationEdit.form.id
                )}
                type="text"
                component={Input}
                validate={[
                  required,
                  this.state.uniqueNameValidator,
                  maxLength250
                ]}
                readonly={this.props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][description]`}
                placeholder={this.props.intl.formatMessage(
                  messages.stationEdit.form.description
                )}
                type="text"
                component={TextArea}
                validate={[required]}
                readonly={this.props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][start]`}
                placeholder={this.props.intl.formatMessage(
                  messages.stationEdit.form.startTime
                )}
                component={DatePicker}
                validate={[required]}
                time
                onChange={this.handleChangeStart}
                readonly={this.props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][end]`}
                placeholder={this.props.intl.formatMessage(
                  messages.stationEdit.form.endTime
                )}
                component={DatePicker}
                validate={[required, this.dataRangeIndexValidation]}
                time
                readonly={this.props.isTeamleaderView}
              />
              {this.renderMunicipalities(id)}
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.station.form.mapTitle} />
              </TextLabel>
              {this.state.center && <MapsComponent
                center={this.state.center}
                onMarkerChange={this.handleMarkerChange}
                onMarkerRemove={this.handleMarkerRemove}
                zoom={this.props.event.stations[this.props.stationKey].markerCoordinates ? 15 : 11}
                showSearchBox={true}
                onSearch={this.handleSearch}
                showMarker={this.state.showMarker}
                markerLocation={this.state.markerCoordinates}
                readOnly={this.props.isTeamleaderView}
              />}
              <TextLabel>
                <FormattedMessage {...messages.station.form.markerTitle} />
              </TextLabel>
              <TextLabel>
                {this.state.markerLocation ? this.state.markerLocation : <FormattedMessage {...messages.station.form.noMarker} /> }
              </TextLabel>
              <TextLabel>
                {this.state.markerCoordinates ? formatCoordinates(this.state.markerCoordinates) : "" }
              </TextLabel>
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.teamLeader.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][teamLeaderEmail]`}
                placeholder={this.props.intl.formatMessage(
                  messages.teamLeader.form.email
                )}
                type="email"
                component={Input}
                readonly={this.props.isTeamleaderView}
                onChange={(event, newValue, previousValue, name) => this.setState({teamLeaderEmail: newValue})}
              />
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.volunteer.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][officialQuantity]`}
                placeholder={this.props.intl.formatMessage(
                  messages.volunteer.form.officialQuantity
                )}
                type="number"
                min="0"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={this.props.isTeamleaderView}
              />
              <Field
                name={`[stations][${id}][officialMiniumumAge]`}
                placeholder={this.props.intl.formatMessage(
                  messages.volunteer.form.officialMinimumAge
                )}
                type="number"
                min="0"
                component={Input}
                validate={[required, positiveNumbers, integer]}
                readonly={this.props.isTeamleaderView}
              />
              {this.renderSkills(id)}
            </FormGroup>
            <FormGroup>
              <TextLabel>
                <FormattedMessage {...messages.payment.form.title} />
              </TextLabel>
              <Field
                name={`[stations][${id}][paymentType]`}
                placeholder={this.props.intl.formatMessage(
                  messages.payment.form.paymentType
                )}
                component={Select}
                validate={[required]}
                valueField="id"
                textField="name"
                onChange={this.handlePaymentChange}
                data={paymentOptions}
                readonly={this.props.isTeamleaderView}
              />
              {!this.props.compensationByAgreement ? (
                <Fragment>
                  <Field
                    name={`[stations][${id}][currency]`}
                    placeholder={this.props.intl.formatMessage(
                      messages.payment.form.currency
                    )}
                    component={Select}
                    validate={[required]}
                    valueField="id"
                    textField="name"
                    data={currencyOptions}
                    readonly={this.props.isTeamleaderView}
                  />
                  {this.props.groupPayment ? (
                    <Field
                      name={`[stations][${id}][groupPayment]`}
                      placeholder={this.props.intl.formatMessage(
                        messages.payment.form.groupPayment
                      )}
                      type="number"
                      min="0"
                      component={Input}
                      validate={[required, positiveNumbers]}
                      readonly={this.props.isTeamleaderView}
                    />
                  ) : (
                    <Fragment>
                      <Field
                        name={`[stations][${id}][paymentAdult]`}
                        placeholder={this.props.intl.formatMessage(
                          messages.payment.form.paymentAdult
                        )}
                        type="number"
                        min="0"
                        component={Input}
                        validate={[required, positiveNumbers]}
                        readonly={this.props.isTeamleaderView}
                      />
                      <Field
                        name={`[stations][${id}][paymentChild]`}
                        placeholder={this.props.intl.formatMessage(
                          messages.payment.form.paymentChild
                        )}
                        type="number"
                        min="0"
                        component={Input}
                        validate={[required, positiveNumbers]}
                        readonly={this.props.isTeamleaderView}
                      />
                    </Fragment>
                  )}
                </Fragment>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={9}>{this.renderButtons()}</Col>
        </Row>
        {this.state.showAddUserModal && this.renderAddUserModal()}
      </FormWrapper>
    );
  }

  renderButtons() {
    if (this.props.isTeamleaderView) {
      return null;
    }

    return (
      <Fragment>
        <BigGrayButton
          type="button"
          label={this.props.intl.formatMessage(
            messages.stationEdit.cancelEditButton
          )}
          onClick={this.props.toggleEditForm}
        />
        <Button
          busy={this.state.submitting}
          label={this.props.intl.formatMessage(
            messages.stationEdit.editStationButton
          )}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <div id="station-edit-form">
        {this.renderEditForm(this.props.stationKey)}
      </div>
    );
  }
}

export default reduxForm({
  form: "stationEdit"
})(injectIntl(StationEditForm));
