import { connect } from "react-redux";
import { sendSendOut } from "../reducer";
import { withRouter } from "react-router";
import { fetchEventOffers } from "../../../Offer/reducer";
import SendOutsForm from "./SendOutsForm";

const mapStateToProps = (state) => ({
  status: state.sendOuts.status,
  eventOffers: state.offers.eventOffers,
});

const mapDispatchToProps = (dispatch) => ({
  sendSendOut: data => dispatch(sendSendOut(data)),
  fetchEventOffers: data => dispatch(fetchEventOffers(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SendOutsForm));
