import React, { Component, Fragment } from "react";
import {
  ContentWrapper,
  Container,
  WhiteH1,
  TextLabel,
  P,
  H2,
  TextSection,
  ImageBackground
} from "../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import img from "../../images/Volex_bild_undersida6.jpg";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";

const Background = ImageBackground.extend`
  background-image: url(${img});
`;

class FAQ extends Component {
  renderQuestions(role) {
    const questions = [];

    for (let i = 1; i <= 40; i++) {
      const questionCode = `faq-${role}.${i}.question`;
      const question = this.props.intl.formatMessage({ id: questionCode });
      const answerCode = `faq-${role}.${i}.answer`;
      const answer = this.props.intl.formatMessage({ id: answerCode });

      if (questionCode !== question && answerCode !== answer) {
        questions.push({
          id: i,
          question,
          answer
        });
      }
    }

    return questions.map(question => (
      <TextSection key={question.id}>
        <TextLabel>{question.question}</TextLabel>
        <P>{question.answer}</P>
      </TextSection>
    ));
  }

  render() {
    return (
      <Fragment>
        <Background>
          <Container>
            <WhiteH1><FormattedMessage {...messages.headerTitle} /></WhiteH1>
          </Container>
        </Background>
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={9}>
                <H2><FormattedMessage {...messages.volunteerPageTitle} /></H2>
                {this.renderQuestions("volunteer")}
                <H2><FormattedMessage {...messages.organizerPageTitle} /></H2>
                {this.renderQuestions("organizer")}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default injectIntl(FAQ);
