import { connect } from "react-redux";
import { submitGroup, reset } from "../reducer";
import GroupForm from "./GroupForm";
import { resetUser } from "../../../User/reducer";

const mapStateToProps = state => ({
  group: state.group.group,
  status: state.group.status,
  user: state.auth.user,
  initialValues: state.group.group,
  userEmail: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  submitGroup: data => dispatch(submitGroup(data)),
  resetGroup: () => dispatch(reset()),
  resetUser: () => dispatch(resetUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupForm);
