import { defineMessages } from 'react-intl';

export default defineMessages({
  progressBar: {
    registerGroup: {
      id: 'new-group.progressbar.register-group.placeholder',
    },
    registerVolunteers: {
      id: 'new-group.progressbar.register-volunteers.placeholder',
    },
    registerAvailability: {
      id: 'group.progressbar.register-availability.placeholder',
    },
  },
  title: {
    id: 'volunteer-start.title',
  },
  text: {
    id: 'volunteer-start.text',
  },
  newGroupLink: {
    id: 'volunteer-start.new-group-link',
  },
  answerRequestLink: {
    id: 'volunteer-start.answer-request-link',
  },
  updateVolunteersLink: {
    id: 'volunteer-start.update-volunteers-link',
  },
  myGroups: {
    title: {
      id: 'volunteer-start.my-groups.list.title',
    },
    button: {
      id: 'volunteer-start.my-groups.list.update-button',
    },
  },
  myEvents: {
    title: {
      id: 'volunteer-start.my-events.list.title',
    },
    button: {
      id: 'volunteer-start.my-events.list.update-button',
    },
  },
  statusApproved: {
    id: 'group.status-approved',
  },
  statusUnconfirmed: {
    id: 'group.status-unconfirmed',
  },
  statusUnknown: {
    id: 'group.status-unknown',
  },
  noGroupsMessage: {
    id: 'group.no-groups',
  }
});
