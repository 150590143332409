import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { submitAttendance, reset } from '../reducer';
import GroupAttendance from './GroupAttendance';

const mapStateToProps = (state, ownProps) => ({
  attendance: state.attendance,
  initialValues: {
    adultAttendance: ownProps.offer.adultAttendance || '',
    childAttendance: ownProps.offer.childAttendance || '',
  }
});

const mapDispatchToProps = dispatch => ({
  submitAttendance: data => dispatch(submitAttendance(data)),
  reset: () => dispatch(reset()),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupAttendance));
