import { connect } from "react-redux";
import { registerSubmit, reset } from "./reducer";
import Register from "./Register";

const mapStateToProps = state => ({
  status: state.register.status,
  registerError: state.register.error,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: data => dispatch(registerSubmit(data)),
  resetRegister: () => dispatch(reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
