
export const TEAMLEADER_EVENTS_FETCH = 'TEAMLEADER_EVENTS_FETCH';
export const TEAMLEADER_EVENTS_SUCCESS = 'TEAMLEADER_EVENTS_SUCCESS';
export const TEAMLEADER_EVENTS_FAILED = 'TEAMLEADER_EVENTS_FAILED';
export const TEAMLEADER_EVENT_FETCH = 'TEAMLEADER_EVENT_FETCH';
export const TEAMLEADER_EVENT_SUCCESS = 'TEAMLEADER_EVENT_SUCCESS';
export const TEAMLEADER_EVENT_FAILED = 'TEAMLEADER_EVENT_FAILED';
export const TEAMLEADER_RESET = 'TEAMLEADER_RESET';

export const status = {
  INIT: 'INIT',
  FETCHING: 'FETCHING',
  FETCHED: 'FETCHED',
  FAILED: 'FAILED',
};

export function fetchTeamleaderEvents () {
  return {
    type: TEAMLEADER_EVENTS_FETCH,
  };
}

export function fetchTeamleaderEvent (data) {
  return {
    type: TEAMLEADER_EVENT_FETCH,
    payload: data
  };
}

export function reset () {
  return {
    type: TEAMLEADER_RESET,
  };
}

const initialState = {
  status: status.INIT,
  error: null,
  event: null,
  eventsSummaries: null,
};

export default function teamleader(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case TEAMLEADER_EVENT_SUCCESS:
      return Object.assign({}, state, {event: action.payload, status: status.FETCHED});
    case TEAMLEADER_EVENT_FAILED:
      return Object.assign({}, state, {error: action.payload, status: status.FAILED});
    case TEAMLEADER_EVENT_FETCH:
    case TEAMLEADER_EVENTS_FETCH:
      return Object.assign({}, state, {status: status.FETCHING});
    case TEAMLEADER_EVENTS_SUCCESS:
      return Object.assign({}, state, {eventsSummaries: action.payload});
    case TEAMLEADER_EVENTS_FAILED:
      return Object.assign({}, state, {eventsSummaries: []});
    case TEAMLEADER_RESET:
      return initialState;
    default:
      return state;
  }
}