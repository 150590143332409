import React, { Fragment, Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import styled from "styled-components";
import { colors } from "../../constants/styling-constants";
import { Row, Col } from "react-bootstrap/lib";
import { Container, P, H2 } from "../";
import { SmallGreenButton } from "../Buttons";

let cookiesAgreed = localStorage.getItem("cookiesAgreed");
cookiesAgreed = JSON.parse(cookiesAgreed);

const Wrapper = styled.div`
  background-color: ${colors.lightGreen};
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 9999;
`;

const StyledTitle = styled(H2)`
  padding: 23px 0 0 0;
  margin-bottom: 10px;
`;

const StyledButton = styled(SmallGreenButton)`
  margin: 0 0 20px 0;
 
`;

class CookiesMessage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      cookiesAgreed: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    if (cookiesAgreed) {
      this.setState({
        cookiesAgreed: true,
      });
    }
  }

  handleSubmit() {
    localStorage.setItem("cookiesAgreed", true);
    this.setState({
      cookiesAgreed: true,
    });
  }

  render() {
    return (
      <Fragment>
        {!this.state.cookiesAgreed
          ? (<Wrapper>
            <Container>
              <Row>
                <Col sm={9}>
                  <StyledTitle><FormattedMessage {...messages.title} /></StyledTitle>
                  <P><FormattedMessage {...messages.text} /></P>
                  <StyledButton onClick={this.handleSubmit}><FormattedMessage {...messages.button} /></StyledButton>
                </Col>
              </Row>
            </Container>
          </Wrapper>)
          : null
        }
      </Fragment>
    );
  }

}

export default injectIntl(CookiesMessage);
