import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'cookies.title',
  },
  text: {
    id: 'cookies.text',
  },
  button: {
    id: 'cookies.agree-button',
  },
});