import React, { Fragment } from "react";
import { connect } from "react-redux"
import { reset } from "redux-form";
import StationNewForm from "./StationNewForm";
import StationsList from "./StationsList";
import {
  H1,
  P,
  GeneralError,
} from "../../../../components/index";
import { Button } from "../../../../components/Buttons/index";
import { Row, Col } from "react-bootstrap/lib";
import messages from "./messages";
import moment from "moment";
import { injectIntl, FormattedMessage } from "react-intl";

class StationNewBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stations: this.props.event && this.props.event.stations ? this.props.event.stations : [],
      addingStation: false,
      clickedNext: false,
      showError: false,
    };

    this.handleSubmitStation = this.handleSubmitStation.bind(this);
    this.handleDeleteStation = this.handleDeleteStation.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
  }

  submitEvent(stationList) {
    this.setState({
      stations: stationList,
    });
    this.props.submitEvent({
      ...this.props.event,
      stations: stationList,
    });
  }

  handleSubmitStation(station) {
    this.submitEvent([
      ...this.state.stations,
      {
        ...station,
        id: Math.floor(Math.random() * 10000),
      },
    ]);
    // this.props.resetForm()
  }

  handleDeleteStation(stationsList) {
    this.submitEvent(stationsList);
  }

  handleClickNext() {
    if (this.state.stations.length < 1) {
      this.setState({
        showError: true,
      });
    } else {
      this.props.onClickNext(2);
    }
  }

  render() {
    const initialValues = {
      start: moment(this.props.event.date)
        .set({
          hour: 8,
          minute: 0
        })
        .format("YYYY-MM-DD HH:mm"),
      end: moment(this.props.event.date)
        .set({
          hour: 18,
          minute: 0
        })
        .format("YYYY-MM-DD HH:mm"),
    };

    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1><FormattedMessage {...messages.title} /></H1>
            <P><FormattedMessage {...messages.text} /></P>
          </Col>
        </Row>
        <StationNewForm {...this.props} onSubmitStation={this.handleSubmitStation}
                        initialValues={initialValues}
        />
        <StationsList {...this.props} onDeleteStation={this.handleDeleteStation}
                      stationsList={this.state.stations}
        />
        {this.state.showError && this.state.stations.length < 1 ?
          <GeneralError><FormattedMessage {...messages.station.noStationsError} /></GeneralError> : null}
        <Row>
          <Col sm={10}>
            <Button
              type="button"
              onClick={this.handleClickNext}
              label={this.props.intl.formatMessage(messages.nextButton)}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetForm: () => dispatch(reset("station"))
});

export default connect(null, mapDispatchToProps)(injectIntl(StationNewBase));
