import React, { Fragment } from "react";
import { SelectList } from "react-widgets";
import styled from "styled-components";
import { colors } from "../../constants/styling-constants";
import { TextLabel } from "../index";
import { Row, Col } from "react-bootstrap/lib";
import { withObjectValues } from "./selectValuesHoc";
import { SmallError } from "./Error";

const StyledSelect = styled(SelectList)`
  font-size: 17px;
  margin-bottom: 15px;
  background-color: transparent;
  &.rw-widget-container {
    border: none;
    outline: none;
    box-shadow: none;
    &.rw-state-focus {
      box-shadow: none;
    }
  }
  li {
    padding-left: 0;
    display: ${props => props.display};
    &.rw-state-focus {
      border: none;
    }
  }
  &.rw-state-focus:hover {
    background-color: transparent;
  }
  &.rw-state-focus {
    background-color: transparent;
  }
  &.has-error {
    margin-bottom: 5px;
    background-color: ${colors.lightPink2}
    &::placeholder {
      color: ${colors.pink};
    }
  }
  .rw-list-option:hover {
    background-color: transparent;
    border-color: transparent;
  }
`;

const ReadonlyItem = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const ListSelect = ({ className = "", multiple = true, placeholder = null, readonly = false, input, data, valueField, textField, meta: { touched, error, warning }, display }) => {
  if (readonly) {
    return (
      <Row>
        <Col md={5}>
          {placeholder ? <TextLabel>{placeholder}</TextLabel> : null}
        </Col>
        <Col md={7}>
          {input && input.value ? input.value.map((v) => {
              const item = data.find(d => v[valueField] === d[valueField]);

              if (item) {
                return (<ReadonlyItem key={item[valueField]}>{item[textField]}</ReadonlyItem>);
              }

              return null;
            })
            : null}
        </Col>
      </Row>
    );
  }
  return (
    <Fragment>
      {placeholder ? <TextLabel>{placeholder}</TextLabel> : null}
      <StyledSelect
        className={`${className} ${touched && error ? "has-error" : ""}`}
        {...input}
        multiple={multiple}
        display={display}
        data={data}
        valueField={valueField}
        textField={textField}
      />
      {touched &&
      ((error && <SmallError error={error} />) ||
        (warning && <Fragment>{warning}</Fragment>))}
    </Fragment>
  );
};

export default withObjectValues(ListSelect);
