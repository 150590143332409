import { defineMessages } from 'react-intl';

export default defineMessages({
  eventDataLink: {
    id: 'event-edit.edit-data-link',
  },
  stationLink: {
    id: 'event-edit.edit-stations-link',
  },
  groupLink: {
    id: 'event-edit.edit-group-link',
  },
  paymentLink: {
    id: 'event-edit.edit-payment-link',
  },
  matchLink: {
    id: 'event-edit.match-link',
  },
  sendOutsLink: {
    id: 'event-edit.send-outs-link',
  },
  attendanceLink: {
    id: 'event-edit.attendance-link',
  },
  error: {
    id: 'event-edit.error',
  }
});
