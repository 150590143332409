import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const IconBase = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 100%;
  background-color: ${colors.pink};
  &.green {
    background-color: ${colors.green};
  }
  color: #FFF;
  font-size: 12px;
`;

const CircleIcon = ({icon = "fas fa-chevron-right", className}) => (<IconBase className={className}><i className={icon}></i></IconBase>);

const GrayCircleIcon = styled(CircleIcon)`
  background-color: ${colors.gray};
`;

export {
  CircleIcon,
  GrayCircleIcon
};
