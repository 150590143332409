import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'register-group.title',
  },
  form: {
    groupInfo: {
      title: {
        id: 'register-group.group-info.form.title',
      },
      name: {
        id: 'register-group.form.group-info.name.placeholder',
      },
      orgNumber: {
        id: 'register-group.form.group-info.org-number.placeholder',
      },
      orgName: {
        id: 'register-group.form.group-info.org-name.placeholder',
      },
      municipality: {
        id: 'register-group.form.group-info.municipality.placeholder',
      },
    },
    contact: {
      title: {
        id: 'register-group.contact.form.title',
      },

    },
    members: {
      title: {
        id: 'register-group.members.form.title',
      },
      numberOfMembers: {
        id: 'register-group.form.members.number-of-members.placeholder',
      },
    },
    ages: {
      title: {
        id: 'register-group.ages.form.title',
      },
      ages: {
        id: 'register-group.form.ages.ages.placeholder',
      },
    },
    alignment: {
      sport: {
        id: 'register-group.form.alignment.sport.placeholder',
      },
      skills: {
        id: 'register-group.form.alignment.skills.placeholder',
      },
    },
    payment: {
      title: {
        id: 'register-group.payment.form.title',
      },
      bank: {
        id: 'register-group.form.payment.bank.placeholder',
      },
      accountNumber: {
        id: 'register-group.form.payment.account-number.placeholder',
      },
    },
  },
  editGroup: {
    form: {
      contact: {
        id: 'edit-group.form.contact.placeholder',
      },
      contactExistMessage: {
        id: 'edit-group.form.contact-exist-message',
      },
      contactEmptyMessage: {
        id: 'edit-group.form.contact-empty-message',
      },
      userNotFound: {
        id: 'edit-group.form.contact-user-not-found',
      },
      addContactButton: {
        id: 'edit-group.form.add-contact-button',
      },
    },
    contactList: {
      name: {
        id: 'edit-group.contacts.name.placeholder',
      },
      email: {
        id: 'edit-group.contacts.email.placeholder',
      },
      phone: {
        id: 'edit-group.contacts.phone.placeholder',
      },
    },
  },
  volunteers: {
    title: {
      id: 'edit-group.volunteers.form.title',
    },
    form: {
      name: {
        id: 'edit-group.volunteers.name.placeholder',
      },
      email: {
        id: 'edit-group.volunteers.email.placeholder',
      },
      phone: {
        id: 'edit-group.volunteers.phone.placeholder',
      },
      age: {
        id: 'edit-group.volunteers.age.placeholder',
      },
      addButton: {
        id: 'edit-group.volunteers.add-button',
      },
    },
  },
  saveGroupButton: {
    id: 'save-group-button',
  },
  nextButton: {
    id: 'next-step-button',
  },
  editGroupButton: {
    id: 'edit-group-button',
  },
  deleteContactText: {
    id: 'group.delete-contact.text',
  },
});
