import React, { Component } from "react";
import { H2, TableRowWithClick, TableRow, TableStatus, WhiteTableWithLastToRight } from "../../../../components/index";
import { TableButton } from "../../../../components/Buttons/index";
import { breakPoints } from "../../../../constants/styling-constants";
import styled from "styled-components";
import { withRouter } from "react-router";
import messages from "../messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { status } from "../reducer";

const Wrapper = styled.div`
  margin-bottom: 85px;
`;

const Table = styled(WhiteTableWithLastToRight)`
  @media (max-width: ${breakPoints.sm}px) {
    .table-button-col {
      display: none;
    }
  }
`;

class MyGroupsList extends Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.props.getGroupsSummaries();
  }

  handleClick(row) {
    this.props.history.push(`/my-pages/volunteers/${row.id}`);
  }

  renderStatus(status) {
    switch (status) {
      case "CONFIRMED":
        return (<TableStatus><FormattedMessage {...messages.statusApproved} /></TableStatus>);
      case "DRAFT":
        return (<TableStatus className="draft"><FormattedMessage {...messages.statusUnconfirmed} /></TableStatus>);
      default:
        return (<TableStatus className="done"><FormattedMessage {...messages.statusUnknown} /></TableStatus>);
    }
  }

  renderRow(row) {
    return (
      <TableRowWithClick
        key={row.id}
        row={row}
        callback={this.handleClick}
      >
        <td>{row.name}</td>
        <td>{row.organizationName}</td>
        <td className={"right "}>{this.renderStatus(row.status)}</td>
        <td className="table-button-col"><TableButton
          type="button"
          label={this.props.intl.formatMessage(messages.myGroups.button)}
        /></td>
      </TableRowWithClick>
    );
  }

  renderContent() {
    if (this.props.statusGroupSummaries === status.FETCHING) {
      return <i className="fas fa-spinner fa-pulse" />;
    }

    return (
      <Table>
        <tbody>
          {this.props.groupsSummaries.length > 0
            ? this.props.groupsSummaries.sort((a, b) => a && b && a.name && b.name ? a.name.localeCompare(b.name) : 0).map(this.renderRow)
            : <TableRow>
              <td><FormattedMessage {...messages.noGroupsMessage} /></td>
            </TableRow>
          }
        </tbody>
      </Table>
    )
  }

  render() {
    return (
      <Wrapper>
        <H2><FormattedMessage {...messages.myGroups.title} /></H2>
        {this.renderContent()}
      </Wrapper>
    );
  }
}

export default withRouter(injectIntl(MyGroupsList));
