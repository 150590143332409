import React, { Component, Fragment } from "react";
import { ContentWrapper, Container, H1 } from "../../../../components";
import { Row, Col } from "react-bootstrap/lib";
import GroupForm from "../GroupForm";
import Volunteers from "../Volunteers";
import GroupConfirm from "../GroupConfirm";
import messages from "../messages";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { GreenNavLinkButton } from "../../../../components/Links";
import { groupStatus } from "../reducer";
import GroupAvailability from "../GroupAvailability";
import {
  Switch,
  Route
} from "react-router-dom";

const states = [
  {
    step: 0,
    name: <FormattedMessage {...messages.progressBar.registerGroup} />,
  },
  {
    step: 1,
    name: <FormattedMessage {...messages.progressBar.registerVolunteers} />,
  },
  {
    step: 2,
    name: <FormattedMessage {...messages.progressBar.registerAvailability} />,
  },
];

const NavigationWrapper = styled.nav`
  margin-bottom: 90px;
`;

class GroupBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      clickedNext: false,
      nextStep: 1,
    };

    this.goToStep = this.goToStep.bind(this);
    this.handleGroupSubmit = this.handleGroupSubmit.bind(this);
  }

  componentDidMount() {
    if (!this.isNew()) {
      this.props.fetchGroup({ id: this.props.match.params.id });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === groupStatus.SUCCESS && this.props.status === groupStatus.SUBMITTING && this.state.clickedNext) {
      this.goToStep(this.state.nextStep);
      this.setState({
        clickedNext: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  goToStep(step) {
    if (step >= 0 && step <= states.length) {
      this.setState({
        currentStep: step,
      });

      if (states[step].link) {
        this.props.history.push(states[step].link);
      }
    }
  }

  handleGroupSubmit(step) {
    this.setState({
      clickedNext: true,
      nextStep: step,
    });
  }

  isNew() {
    return !this.props.match.params.id;
  }

  render() {
    if (!this.props.group) {
      return (
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={12}>
                <H1><FormattedMessage id="common.loading.message" /><i className="fa fa-spinner fa-pulse fa-1x fa-fw" /></H1>
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      );
    }

    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <H1>{this.props.group.name}</H1>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <NavigationWrapper>
                <GreenNavLinkButton
                  to={`/my-pages/volunteers/${this.props.group.groupId}`}
                  exact
                ><FormattedMessage id="change.me.group-edit" /></GreenNavLinkButton>
                <GreenNavLinkButton to={`/my-pages/volunteers/${this.props.group.groupId}/confirm`}><FormattedMessage id="change.me.group-confirm" /></GreenNavLinkButton>
                <GreenNavLinkButton to={`/my-pages/volunteers/${this.props.group.groupId}/volunteers`}><FormattedMessage id="change.me.group-volunteers" /></GreenNavLinkButton>
              </NavigationWrapper>
            </Col>
          </Row>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}`}
              render={() => <GroupForm {...this.props} />}
            />
            <Route
              exact
              path={`${this.props.match.url}/confirm`}
              render={() => (<Fragment>
                <GroupAvailability {...this.props} groupId={this.props.group.id || this.props.group.groupId} /><GroupConfirm {...this.props} onSubmit={this.handleConfirmSubmit}
                                                                                                                             onClickNext={this.goToStep}
              /></Fragment>)}
            />
            <Route
              exact
              path={`${this.props.match.url}/volunteers`}
              render={() => <Volunteers {...this.props} onSubmit={this.handleStationSubmit}
                                        onClickNext={this.goToStep}
              />}
            />
          </Switch>
        </Container>
      </ContentWrapper>
    );
  }
}

export default GroupBase;
