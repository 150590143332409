import { defineMessages } from 'react-intl';

export default defineMessages({
  attendanceList: {
    title: {
      id: 'attendance.list.title',
    },
    description: {
      id: 'attendance.list.description',
    },
    showButton: {
      id: 'attendance.list.show-button',
    },
    offerError: {
      id: 'attendance.list.offer-error'
    },
    noStations: {
      id: 'attendance.list.no-stations'
    }
  },

});
