import React, { Component, Fragment } from "react";
import {
  ContentWrapper,
  Container,
  WhiteH1,
  TextLabel,
  P,
  ItalicP,
  H2,
  ImageBackground
} from "../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import img from "../../images/Volex_bild_undersida6.jpg";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";

const Background = ImageBackground.extend`
  background-image: url(${img});
`;

const StyledList = styled.ul`
  list-style: circle;
  padding-left: 2em;
  line-height: 1.5;
`;

const ItalicPWithMargin = styled(ItalicP)`
  margin-bottom: 50px;
`;

const TermSection = styled.section`
  margin-top: 40px;
`;

const H2WithMargin = styled(H2)`
  margin-top: 60px;
`;

class Terms extends Component {

  render() {
    return (
      <Fragment>
        <Background>
          <Container>
            <WhiteH1><FormattedMessage {...messages.headerTitle} /></WhiteH1>
          </Container>
        </Background>
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={9}>
                <ItalicP><FormattedMessage {...messages.pageIntro1} /></ItalicP>
                <ItalicPWithMargin><FormattedMessage {...messages.pageIntro2} /></ItalicPWithMargin>
                <HowItWorksGenerator sections={[...generateSections(this.props.intl.formatMessage, "general")]} />
                <H2WithMargin><FormattedMessage {...messages.volunteerTitle} /></H2WithMargin>
                <HowItWorksGenerator sections={[...generateSections(this.props.intl.formatMessage, "volunteer")]} />
                <H2WithMargin><FormattedMessage {...messages.organizerTitle} /></H2WithMargin>
                <HowItWorksGenerator sections={[...generateSections(this.props.intl.formatMessage, "organizer")]} />
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Fragment>
    );
  }
}

function* generateSections(formatMessage, termType) {
  for (let section = 1; true; section++) {
    const sectionTitleKey = `terms-${termType}.section-${section}.title-1`;
    const title = formatMessage({ id: sectionTitleKey });
    if (sectionTitleKey === title) {
      return;
    }
    yield {
      id: section,
      titles: [...generateTitles(section, formatMessage, termType)],
      listItems: [...generateListItems(section, formatMessage, termType)],
      paragraphs: [...generateParagraphs(section, formatMessage, termType)],
    };
  }
}

function* generateParagraphs(section, formatMessage, termType) {
  for (let i = 1; true; i++) {
    const listElementCode = `terms-${termType}.section-${section}.paragraph-${i}`;
    const paragraph = formatMessage({ id: listElementCode });
    if (listElementCode === paragraph) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: paragraph
    };
  }
}

function* generateTitles(section, formatMessage, termType) {
  for (let i = 1; true; i++) {
    const listElementCode = `terms-${termType}.section-${section}.title-${i}`;
    const title = formatMessage({ id: listElementCode });
    if (listElementCode === title) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: title
    };
  }
}

function* generateListItems(section, formatMessage, termType) {
  for (let i = 1; true; i++) {
    const listItemCode = `terms-${termType}.section-${section}.list-element-${i}`;
    const item = formatMessage({ id: listItemCode });
    if (listItemCode === item) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: item
    };
  }
}

const Paragraph = ({ paragraph }) => (
  <P>
    {paragraph.label}
  </P>
);

const Title = ({ title }) => (
  <TextLabel id={title.label.toLowerCase()}>
    {title.label}
  </TextLabel>
);

const ListItem = ({ item }) => (
  <li>
    {item.label}
  </li>
);

const ListItems = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StyledList>
      {items.map(item => <ListItem
        key={item.id}
        item={item}
      />)}
    </StyledList>
  );
};

const Section = ({ section }) => (
  <TermSection>
    {section.titles.map(paragraph => (<Title
      key={paragraph.id}
      title={paragraph}
    />))}
    {section.paragraphs.map(paragraph => (<Paragraph
      key={paragraph.id}
      paragraph={paragraph}
    />))}
    <ListItems items={section.listItems} />
  </TermSection>
);

const HowItWorksGenerator = ({ sections }) => (
  <Fragment>
    {sections.map(section => (<Section
      key={section.id}
      section={section}
    />))}
  </Fragment>
);

export default injectIntl(Terms);
