import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { colors, breakPoints, headerHeight } from "../../../constants/styling-constants";
import styled from "styled-components";

const ItemWrapper = styled.li`
  display: inline-block;
  margin-left: 45px;
  position: relative;
  padding: 30px 0;
  i {
    margin-left: 7px;
    transition: transform 0.2s ease;
    @media (max-width: ${breakPoints.md}px) {
      display: none;
    }
  }
  &:hover {
    ul {
      display: inline-block;
    }
    i {
      transform: rotate(90deg);
    }
  }
  @media (max-width: ${breakPoints.md}px) {
    margin-left: 50px;
    padding: 0;
    .selected-item-wrapper {
      display: none;
    }
  }
`;

const DropDownUl = styled.ul`
  position: absolute;
  display: none;
  z-index: 3;
  top: ${headerHeight}px;
  left: -23px;
  @media (max-width: ${breakPoints.md}px) {
    display: block;
    position: relative;
    top: 0;
    left: 0;
  }
`;


const StyledItem = styled.li`
  padding: 25px;
  background-color: #FFF;
  cursor: pointer;
  transition: background-color 0.1s ease;
  &:hover {
    background-color: ${colors.lightPink2};
  }
  @media (max-width: ${breakPoints.md}px) {
    background-color: transparent;
    padding: 15px 0;
    &.active {
      color: ${colors.pink}
    }
    &:hover {
      background-color: transparent;
      color: ${colors.pink}
    }
  }
`;

const MobileBorder = styled.div`
  display: none;
  height: 2px;
  width: 40px;
  margin: 20px 0;
  background-color: ${colors.gray};
  @media (max-width: ${breakPoints.md}px) {
    display: block;
  }
`;

/* TODO: Replace icon with thin version from FontAwesome Pro */

const Item = ({ item, callback, selectedItem }) => {
  const onClick = () => {
    callback(item);
  };

  return (
    <StyledItem
      onClick={onClick}
      className={selectedItem === item.id ? "active" : ""}
    >
      {item.label}
    </StyledItem>
  );
};

class DropDownMenuItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(item) {
    this.props.onSelect(item);
  }

  render() {
    if (!this.props.items || this.props.items.length === 0) {
      return null;
    }

    const selectedItem = this.props.items.find(i => i.id === this.props.selectedItem);

    if (!selectedItem) {
      return null;
    }

    return (
      <ItemWrapper>
        <div className="selected-item-wrapper">
          <Fragment>{selectedItem.label}</Fragment>
          <i className="fas fa-angle-right" />
        </div>
        <MobileBorder />
        <DropDownUl>
          {this.props.items.map(i => <Item
            key={i.id}
            item={i}
            callback={this.handleClick}
            selectedItem={this.props.selectedItem}
          />)}
        </DropDownUl>
      </ItemWrapper>
    );
  }
}

DropDownMenuItem.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default DropDownMenuItem;
