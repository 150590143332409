
// actions
export const REGISTER_SUBMIT = 'REGISTER_SUBMIT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const REGISTER_RESET = 'REGISTER_RESET';

export const status = {
  NOT_REGISTERED: 'NOT_REGISTERED',
  REGISTERING: 'REGISTERING',
  REGISTER_FAILED: 'REGISTER_FAILED',
  REGISTERED: 'REGISTERED',
};

export function registerSubmit (data) {
  return {
    type: REGISTER_SUBMIT,
    payload: data,
  };
}

export function reset () {
  return {
    type: REGISTER_RESET,
  };
}

// reducer with initial state
const initialState = {
  status: status.NOT_REGISTERED,
  email: '',
  password: '',
};

export default function auth(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case REGISTER_SUBMIT:
      return Object.assign({}, state, {email: action.payload.email.toLowerCase(), password: action.payload.password, status: status.REGISTERING});
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {status: status.REGISTERED});
    case REGISTER_FAILED:
      return Object.assign({}, state, {error: action.payload}, {status: status.REGISTER_FAILED});
    case REGISTER_RESET:
      return initialState;
    default:
      return state;
  }
}
