
// actions
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export function toggleMobileMenu () {
  return {
    type: TOGGLE_MOBILE_MENU,
  };
}

export function selectLanguage (data) {
  return {
    type: SELECT_LANGUAGE,
    payload: data,
  };
}

// reducer with initial state
const initialState = {
  showMobileMenu: false,
  selectedLanguage: 1, //  TODO: Not sure this is the best location for the language value. Maybe create an 'App'-reducer?
};

export default function auth(state = initialState, action) {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return Object.assign({}, state, {showMobileMenu: !state.showMobileMenu});
    case SELECT_LANGUAGE:
      return Object.assign({}, state, {selectedLanguage: action.payload.id});
    default:
      return state;
  }
}
