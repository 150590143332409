import { defineMessages } from 'react-intl';

export default defineMessages({
  sendInvitation: {
    buttonText: {
      id: 'send-invitation.button-text',
    },
    error: {
      id: 'send-invitation.error',
    },
    success: {
      id: 'send-invitation.success',
    },
    noInvitationSent: {
      id: 'send-invitation.noInvitationSent',
    },
    noGroupsMatched: {
      id: 'send-invitation.no-groups-matched',
    }
  }
});