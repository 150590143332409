import { connect } from "react-redux";
import GroupEngagement from "./GroupEngagement";
import { fetchGroupOffer, fetchGroupOffers, acceptStationOffer, rejectStationOffer, resetGroupOfferError, updateOfferAttendees } from "../../Offer/reducer";
import { fetchGroup, reset } from "../reducer";

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  initialValues: state.offers.groupOffer,
  groupOffer: state.offers.groupOffer,
  groupOffers: state.offers.groupOffers,
  group: state.group.group,
  groupOfferError: state.offers.groupOfferError,
  groupOfferAcceptError: state.offers.groupOfferAcceptError,
  groupOfferRejectError: state.offers.groupOfferRejectError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupOffer: data => dispatch(fetchGroupOffer(data)),
  fetchGroupOffers: data => dispatch(fetchGroupOffers(data)),
  fetchGroup: data => dispatch(fetchGroup(data)),
  acceptStationOffer: data => dispatch(acceptStationOffer(data)),
  rejectStationOffer: data => dispatch(rejectStationOffer(data)),
  updateOfferAttendees: data => dispatch(updateOfferAttendees(data)),
  resetGroupOfferError: data => dispatch(resetGroupOfferError(data)),
  resetGroup: () => dispatch(reset())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupEngagement);

