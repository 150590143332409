import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const H1 = styled.h1`
  color: ${colors.text};
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 40px;
`;

export const WhiteH1 = H1.extend`
  color: #FFF;
`;

export default H1;
