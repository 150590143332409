import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Input,
  FormGroup,
  GeneralError,
  H2,
  P,
  Select,
} from "../../index";
import { ModalWrapper, Content, Footer } from "../../ConfirmationModal/index";
import { Modal } from "react-bootstrap/lib";
import { MediumGrayButton } from "../../Buttons/index";
import messages from "../messages";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required, minLength3, email, onlyNumbers } from "../../../validators/index";
import styled from "styled-components";
import { connect } from "react-redux"
import { status } from "../../../containers/User/reducer";
import { Row, Col } from "react-bootstrap/lib";
import { countryCodes } from "../../../constants/country-codes";

const ButtonNoMarginRight = styled(MediumGrayButton)`
  margin-right: 0px;
`;

class AddUserForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  /**
   * Custom handling of form values since this form will also submit parent form if we use normal redux-form way.
   */
  handleSubmit() {
    this.props.touch("email", "firstName", "lastName", "countryCode", "phoneNumber");

    if (this.props.valid) {
      const user = {
        email: this.props.email,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        phoneNumber: this.props.countryCode + this.props.phoneNumber,
        type: this.props.type,
      };
      this.props.createUser(user);
    }
  }

  renderError() {
    if (this.props.newUserError) {
      return (
        <GeneralError>{this.props.intl.formatMessage(messages.addUserForm.error)}</GeneralError>
      );
    }

    return null;
  }

  renderForm() {
    return (
      <FormGroup>
        <Field
          name="email"
          placeholder={this.props.intl.formatMessage(messages.addUserForm.form.email)}
          type="email"
          component={Input}
          validate={[required, email]}
          className='border'
        />
        <Field
          name="firstName"
          placeholder={this.props.intl.formatMessage(messages.addUserForm.form.firstName)}
          type="text"
          component={Input}
          validate={[required, minLength3]}
          className='border'
        />
        <Field
          name="lastName"
          placeholder={this.props.intl.formatMessage(messages.addUserForm.form.lastName)}
          type="text"
          component={Input}
          validate={[required, minLength3]}
          className='border'
        />
        <Row>
          <Col xs={5}>
            <Field
              name="countryCode"
              placeholder={this.props.intl.formatMessage(messages.addUserForm.form.countryCode)}
              component={Select}
              validate={[required]}
              data={countryCodes}
              className='border'
            />
          </Col>
          <Col xs={7}>
            <Field
              name="phoneNumber"
              placeholder={this.props.intl.formatMessage(messages.addUserForm.form.phoneNumber)}
              type="tel"
              component={Input}
              validate={[required, minLength3, onlyNumbers]}
              className='border'
            />
          </Col>
        </Row>
      </FormGroup>
    );
  }

  render() {
    const { show, intl } = this.props;

    return (
      <ModalWrapper
        show={show}
        bsSize="large"
      >
        <Modal.Body>
          <form noValidate>
            <Content>
              <H2>{intl.formatMessage(messages.addUserForm.title)}</H2>
              <P>{intl.formatMessage(messages.addUserForm.info)}</P>
              {this.renderForm()}
              {this.renderError()}
            </Content>
            <Footer>
              <MediumGrayButton
                label={intl.formatMessage(messages.addUserForm.cancelButton)}
                type="button"
                onClick={this.props.hide}
              />
              <ButtonNoMarginRight
                label={intl.formatMessage(messages.addUserForm.confirmButton)}
                busy={this.props.status === status.SUBMITTING}
                type='button'
                onClick={this.handleSubmit}
              />
            </Footer>
          </form>
        </Modal.Body>
      </ModalWrapper>
    );
  }

}

AddUserForm.propTypes = {
  show: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

AddUserForm = reduxForm({
  form: "add-user-form",
})(AddUserForm);

const selector = formValueSelector("add-user-form"); // <-- same as form name

AddUserForm = connect(
  (state, ownProps) => {
    const firstName = selector(state, "firstName");
    const email = selector(state, "email") || ownProps.newEmail;
    const lastName = selector(state, "lastName");
    const phoneNumber = selector(state, "phoneNumber");
    const countryCode = selector(state, "countryCode");

    return {
      firstName,
      email,
      lastName,
      phoneNumber,
      countryCode,
      initialValues: {
        email: ownProps.newEmail,
      }
    };
  }
)(AddUserForm);

export default AddUserForm;
