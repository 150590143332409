import { defineMessages } from "react-intl";

export default defineMessages({
  slogan: {
    id: "front-page.slogan",
  },
  sloganLoggedIn: {
    id: "front-page.slogan-logged-in",
  },
  organizerButton: {
    id: "front-page.organizer-button",
  },
  volunteerButton: {
    id: "front-page.volunteer-button",
  },
  volunteerButtonLoggedIn: {
    id: "front-page.volunteer-button-logged-in",
  },
  currentEventsTitle: {
    id: "front-page.current-events-title",
  },
  InfoSection: {
    section1: {
      id: "start-page.text-section-1"
    },
    section2: {
      id: "start-page.text-section-2"
    },
    section3: {
      id: "start-page.text-section-3"
    },
    section4: {
      id: "start-page.text-section-4"
    },
    section5: {
      id: "start-page.text-section-5"
    },
  }
});
