import { call, put } from 'redux-saga/effects';
import { GROUP_SUCCESS, GROUP_FAILED, GROUP_FETCHED, GROUPS_SUMMARIES_FAILED, GROUPS_SUMMARIES_SUCCESS, FETCH_PUBLIC_EVENTS_SUCCESS, FETCH_PUBLIC_EVENTS_FAILED, GROUP_CONFIRM_SUCCESS, GROUP_CONFIRM_FAILED, FETCH_COMPLETE_EVENT_SUCCESS, FETCH_COMPLETE_EVENT_FAILED } from './reducer';
import { createGroup, updateGroup, fetchGroup as getGroup, getGroupsSummaries, confirmGroup } from './service';
import { getEventsByDateRange, fetchPublicEvent } from '../Event/service';

export function* groupSaga(action) {
  try {
    const response = action.payload.id ? yield call(() => updateGroup(action.payload)) : yield call(() => createGroup(action.payload));
    yield put({type: GROUP_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_FAILED, payload: e});
  }
}

export function* fetchGroup(action) {
  try {
    const response = yield call(() => getGroup(action.payload));
    yield put({type: GROUP_FETCHED, payload: response});
  } catch (e) {
    yield put({type: GROUP_FAILED, payload: e});
  }
}

export function* fetchGroupsSummaries (action) {
  try {
    const response = yield call(() => getGroupsSummaries(action.payload));
    yield put({type: GROUPS_SUMMARIES_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUPS_SUMMARIES_FAILED, payload: e});
  }
}


export function* fetchPublicEvents (action) {
  try {
    const response = yield call(() => getEventsByDateRange(action.payload));
    yield put({type: FETCH_PUBLIC_EVENTS_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: FETCH_PUBLIC_EVENTS_FAILED, payload: e});
  }
}

export function* confirmGroupSaga (action) {
  try {
    const response = yield call(() => confirmGroup(action.payload));
    yield put({type: GROUP_CONFIRM_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GROUP_CONFIRM_FAILED, payload: e});
  }
}


export function* fetchCompleteEvent (action) {
  try {
    const response = yield call(() => fetchPublicEvent(action.payload));
    yield put({type: FETCH_COMPLETE_EVENT_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: FETCH_COMPLETE_EVENT_FAILED, payload: e});
  }
}