import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap/lib";
import {
  H2,
  TextLabel,
  FormGroup,
  Input,
  TextArea,
  DatePicker,
  Select,
  MultiSelect
} from "../../../../components/index";
import { Button } from "../../../../components/Buttons/index";
import { Field, reduxForm } from "redux-form";
import {
  required,
  unique,
  positiveNumbers,
  integer,
  maxLength250
} from "../../../../validators/index";
import styled from "styled-components";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { municipalities } from "../../../../constants/municipalities";
import { eventStatus } from "../reducer";
import AddUserForm from "../../../../components/ContactForm/AddUserForm/index";
import { status as userEmailStatus } from "../../../User/reducer";
import { getUserByEmail } from "../../../User/service";
import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository
import moment from "moment";
import MapsComponent, { 
  formatCoordinates, 
  getCoordinatesFromLocation, 
  getLocationFromCoordinates 
} from '../../../../components/Map';

const { skills, payments, currencies } = lists;

const FormWrapper = styled.form`
  margin-top: 80px;
`;

const dateRangeValidation = (value, allValues) => {
  const start = moment(allValues.start).format();
  const end = moment(value).format();

  return end < start ? "Slutdatum måste vara senare än startdatum" : undefined; //TODO: Read from messages.js instead of fixed
};

const uniqueNameValidator = (stations = [], formatMessage) =>
  unique(stations, formatMessage(messages.stationNameAlreadyExistError));

class StationNewForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleMarkerChange = this.handleMarkerChange.bind(this);
    this.handleMarkerRemove = this.handleMarkerRemove.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
    this.renderSkills = this.renderSkills.bind(this);
    this.renderMunicipalities = this.renderMunicipalities.bind(this);
    this.onStationSubmit = this.onStationSubmit.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.toggleAddUserModal = this.toggleAddUserModal.bind(this);

    this.state = {
      showAddUserModal: false,
      compensationByAgreement: false,
      hourRate: false,
      submitting: false,
      showMarker: false,
      uniqueNameValidator: uniqueNameValidator(
        props.event && Array.isArray(props.event.stations)
          ? props.event.stations
          : [],
        props.intl.formatMessage
      )
    };
  }

  componentDidMount() {
    if (
      this.props.location &&
      this.props.location.pathname === "/my-pages/events/new"
    ) {
      window.scrollTo(0, 0);
    }
    this.setCoordinates(this.props.event.city);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.newUserStatus === userEmailStatus.SUBMIT_SUCCESS && this.props.newUserStatus !== userEmailStatus.SUBMIT_SUCCESS) {
      this.toggleAddUserModal();
    }
    if (
      nextProps.status === eventStatus.SUCCESS &&
      this.props.status === eventStatus.SUBMITTING
    ) {
      this.setState({
        submitting: false,
        uniqueNameValidator: uniqueNameValidator(
          nextProps.event.stations,
          nextProps.intl.formatMessage
        )
      });
    }
  }

  /**
   * Call touch on the start or end to validate when one changes
   */
  handleChangeStart() {
    this.props.touch("end");
  }

  renderMunicipalities() {
    return (
      <Field
        placeholder={this.props.intl.formatMessage(
          messages.station.form.municipality
        )}
        data={municipalities}
        valueField="id"
        textField="name"
        filter
        validate={[required]}
        name="municipality"
        component={Select}
        onChange={fieldValue => this.setCoordinates(municipalities.find(m => m.id === fieldValue).name)}
      />
    );
  }

  renderSkills() {
    const skillOptions = skills.map(skill => ({
      id: skill,
      name: this.props.intl.formatMessage({ id: `lists.skills.${skill}` })
    }));
    return (
      <Field
        placeholder={this.props.intl.formatMessage(
          messages.volunteer.form.skills
        )}
        data={skillOptions}
        valueField="id"
        textField="name"
        name="skills"
        component={MultiSelect}
      />
    );
  }

  renderAddUserModal() {
    const email = this.state.teamLeaderEmail

    return (
      <AddUserForm
        show={this.state.showAddUserModal}
        hide={this.toggleAddUserModal}
        intl={this.props.intl}
        newEmail={email}
        type={this.props.type}
      />
    )
  }

  setCoordinates(location) {
    getCoordinatesFromLocation(location).then(coordinates =>
      this.setState({center: coordinates})
    )
  }

  handleMarkerChange(coordinates) {
    getLocationFromCoordinates(coordinates).then(location => {
      this.setState({
        markerLocation: location,
        markerCoordinates: coordinates,
        showMarker: true
      })
    })
  }

  handleMarkerRemove() {
    this.setState({
      markerLocation: null,
      markerCoordinates: null,
      showMarker: false
    });
  }

  handleSearch(place) {
    this.setState({
      center: place.geometry.location
    });
  }

  handlePaymentChange(paymentTypeFieldValue) {
    switch (paymentTypeFieldValue) {
      case "COMPENSATIONBYAGREEMENT":
        this.props.change("compensationByAgreement", "");
        this.props.change("groupPayment", "");
        this.props.change("paymentAdult", "");
        this.props.change("paymentChild", "");
        this.setState({
          hourRate: false,
          compensationByAgreement: true
        });
        return;
      case "NOCOMPENSATION":
        this.props.change("compensationByAgreement", "");
        this.props.change("groupPayment", "");
        this.props.change("paymentAdult", "");
        this.props.change("paymentChild", "");
        this.setState({
          hourRate: false,
          compensationByAgreement: true
        });
        return;
      case "HOURLYRATE":
        this.props.change("groupPayment", "");
        this.setState({
          hourRate: false,
          compensationByAgreement: false
        });
        return;
      case "GROUPPAYMENT":
        this.props.change("paymentAdult", "");
        this.props.change("paymentChild", "");
        this.setState({
          hourRate: true,
          compensationByAgreement: false
        });
        return;
      case "PERPERSON":
        this.props.change("groupPayment", "");
        this.setState({
          hourRate: false,
          compensationByAgreement: false
        });
        return;
      default:
        return;
    }
  }

  toggleAddUserModal() {
    this.setState({ showAddUserModal: !this.state.showAddUserModal });
  }

  onStationSubmit(station) {
    if (this.state.submitting) return;

    if (this.state.markerCoordinates) {
      station = {...station, markerLocation: this.state.markerLocation, markerCoordinates: this.state.markerCoordinates};
    }

    if (station.teamLeaderEmail) {
      getUserByEmail({email: station.teamLeaderEmail})
      .then(resp => {
        const newStation = station;
        this.setState({
          submitting: true
        });
        this.props.onSubmitStation(newStation);
      }).catch(e => this.toggleAddUserModal());
    } else {
      const newStation = station;
      this.setState({
        submitting: true
      });
      this.props.onSubmitStation(newStation);
    }
  }

  render() {
    const paymentOptions = payments.map(payment => ({
      id: payment,
      name: this.props.intl.formatMessage({ id: `lists.payments.${payment}` })
    }));
    const currencyOptions = currencies.map(currency => ({
      id: currency,
      name: this.props.intl.formatMessage({
        id: `lists.currencies.${currency}`
      })
    }));
    return (
      <Fragment>
        <FormWrapper
          onSubmit={this.props.handleSubmit(this.onStationSubmit)}
          noValidate
        >
          <Row>
            <Col sm={5}>
              <H2>
                <FormattedMessage {...messages.station.title} />
              </H2>
              <TextLabel>
                <FormattedMessage {...messages.station.form.title} />
              </TextLabel>
              <FormGroup>
                <Field
                  name="name"
                  placeholder={this.props.intl.formatMessage(
                    messages.station.form.id
                  )}
                  type="text"
                  component={Input}
                  validate={[
                    required,
                    this.state.uniqueNameValidator,
                    maxLength250
                  ]}
                />
                <Field
                  name="description"
                  placeholder={this.props.intl.formatMessage(
                    messages.station.form.description
                  )}
                  type="text"
                  component={TextArea}
                  validate={[required]}
                />
                <Field
                  name="start"
                  placeholder={this.props.intl.formatMessage(
                    messages.station.form.startTime
                  )}
                  component={DatePicker}
                  validate={[required]}
                  onChange={this.handleChangeStart}
                  time
                />
                <Field
                  name="end"
                  placeholder={this.props.intl.formatMessage(
                    messages.station.form.endTime
                  )}
                  component={DatePicker}
                  validate={[required, dateRangeValidation]}
                  time
                />
                {this.renderMunicipalities()}
              </FormGroup>
              <FormGroup>
                <TextLabel>
                  <FormattedMessage {...messages.station.form.mapTitle} />
                </TextLabel>
                {this.state.center && <MapsComponent
                  center={this.state.center}
                  onMarkerChange={this.handleMarkerChange}
                  onMarkerRemove={this.handleMarkerRemove}
                  zoom={11}
                  showSearchBox={true}
                  onSearch={this.handleSearch}
                  showMarker={this.state.showMarker}
                  markerLocation={this.state.markerCoordinates}
                />}
                <TextLabel>
                  <FormattedMessage {...messages.station.form.markerTitle} />
                </TextLabel>
                <TextLabel>
                  {this.state.markerLocation ? this.state.markerLocation : <FormattedMessage {...messages.station.form.noMarker} /> }
                </TextLabel>
                <TextLabel>
                  {this.state.markerCoordinates ? formatCoordinates(this.state.markerCoordinates) : "" }
                </TextLabel>
              </FormGroup>
              <FormGroup>
                <TextLabel>
                  <FormattedMessage {...messages.teamLeader.form.title} />
                </TextLabel>
                <Field
                  name="teamLeaderEmail"
                  placeholder={this.props.intl.formatMessage(
                    messages.teamLeader.form.email
                  )}
                  type="email"
                  component={Input}
                  onChange={(event, newValue, previousValue, name) => this.setState({teamLeaderEmail: newValue})}
                />
              </FormGroup>
              <FormGroup>
                <TextLabel>
                  <FormattedMessage {...messages.volunteer.form.title} />
                </TextLabel>
                <Field
                  name="officialQuantity"
                  placeholder={this.props.intl.formatMessage(
                    messages.volunteer.form.officialQuantity
                  )}
                  type="number"
                  component={Input}
                  min="0"
                  validate={[required, positiveNumbers, integer]}
                />
                <Field
                  name="officialMiniumumAge"
                  placeholder={this.props.intl.formatMessage(
                    messages.volunteer.form.officialMinimumAge
                  )}
                  type="number"
                  min="0"
                  component={Input}
                  validate={[required, positiveNumbers, integer]}
                />
                {this.renderSkills()}
              </FormGroup>
              <FormGroup>
                <TextLabel>
                  <FormattedMessage {...messages.payment.form.title} />
                </TextLabel>
                <Field
                  name="paymentType"
                  placeholder={this.props.intl.formatMessage(
                    messages.payment.form.paymentType
                  )}
                  component={Select}
                  validate={[required]}
                  valueField="id"
                  textField="name"
                  onChange={this.handlePaymentChange}
                  data={paymentOptions}
                />
                {!this.state.compensationByAgreement ? (
                  <Fragment>
                    <Field
                      name="currency"
                      placeholder={this.props.intl.formatMessage(
                        messages.payment.form.currency
                      )}
                      component={Select}
                      validate={[required]}
                      valueField="id"
                      textField="name"
                      data={currencyOptions}
                    />
                    {this.state.hourRate ? (
                      <Field
                        name="groupPayment"
                        placeholder={this.props.intl.formatMessage(
                          messages.payment.form.groupPayment
                        )}
                        type="number"
                        min="0"
                        component={Input}
                        validate={[required, positiveNumbers]}
                      />
                    ) : (
                      <Fragment>
                        <Field
                          name="paymentAdult"
                          placeholder={this.props.intl.formatMessage(
                            messages.payment.form.paymentAdult
                          )}
                          type="number"
                          min="0"
                          component={Input}
                          validate={[required, positiveNumbers]}
                        />
                        <Field
                          name="paymentChild"
                          placeholder={this.props.intl.formatMessage(
                            messages.payment.form.paymentChild
                          )}
                          type="number"
                          min="0"
                          component={Input}
                          validate={[required, positiveNumbers]}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                ) : null}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Button
                type="submit"
                busy={this.state.submitting}
                label={this.props.intl.formatMessage(messages.addStationButton)}
              />
            </Col>
          </Row>
          {this.state.showAddUserModal && this.renderAddUserModal()}
        </FormWrapper>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "station"
})(injectIntl(StationNewForm));
