import React, { Component, Fragment } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap/lib";
import { status } from "../reducer";
import {
  H2,
  Input,
  DatePicker,
  MultiSelect,
  FormGroup,
  ConfirmationModal,
  ContactForm
} from "../../../../components";
import { Button, BigGrayButton } from "../../../../components/Buttons";
import { required, minLength3, maxLength250, organizerNumber } from "../../../../validators";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import { lists } from "../../../../constants/lists.js"; // TODO: Add updated volex-data repository
import { TimePicker } from '../../../../components/Form/DatePicker';

const { foodOptions, shoeSizes, shirtSizes } = lists;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

/*const withLoadedProps = (LoadedComponent, loadedProps) => props => (
  <LoadedComponent {...loadedProps} {...props} />
);*/

class EventEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      readonly: true,
      contactList: this.props.initialValues.contactPersons,
      showContactModal: false,
      deleting: false,
      contactError: null,
      contactFieldValue: null,
      emailInvalid: null,
      contactToDelete: null,
      confirming: false,
      showConfirmModal: false,
      submitting: false,
      eventStatus: this.props.event ? this.props.event.status : {}
    };

    this.toggleReadonly = this.toggleReadonly.bind(this);
    this.handleEventSubmit = this.handleEventSubmit.bind(this);
    this.handleConfirmEvent = this.handleConfirmEvent.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.updateContactListFromForm = this.updateContactListFromForm.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.status === status.SUCCESS &&
      this.props.status === status.SUBMITTING &&
      this.state.submitting
    ) {
      this.toggleReadonly();
      this.setState({
        submitting: false
      });
    }

    if (
      nextProps.status === status.SUCCESS &&
      this.props.status === status.SUBMITTING &&
      this.state.deleting
    ) {
      this.toggleReadonly();
      this.setState({
        deleting: false
      });
    }

    if (
      nextProps.status === status.SUCCESS &&
      this.props.status === status.SUBMITTING &&
      this.state.confirming
    ) {
      this.toggleConfirmModal();
      this.setState({
        confirming: false,
        eventStatus: nextProps.event.status
      });
    }
  }

  componentWillUnmount() {
    this.props.resetUser();
  }

  updateContactListFromForm(contactList) {
    this.setState({ contactList });
  }

  toggleReadonly(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      readonly: !this.state.readonly
    });
  }

  renderShirtSizes() {
    if (!this.props.included) {
      return null;
    }

    const hasShirt = this.props.included.includes("CLOTHES");

    //if (hasShirt !== null && hasShirt !== undefined) { // save this if we need to add it again (2018-08-16)
    if (hasShirt) {
      const shirtSizesTranslated = shirtSizes.map(opt => ({
        id: opt,
        name: this.props.intl.formatMessage({
          id: `shirt-size.${opt.toLowerCase()}`
        })
      }));
      return (
        <FormGroup>
          <H2>
            <FormattedMessage {...messages.event.clothesChoices.title} />
          </H2>
          <Field
            placeholder={this.props.intl.formatMessage(
              messages.event.clothesChoices.clothes
            )}
            data={shirtSizesTranslated}
            valueField="id"
            textField="name"
            name="shirtSizes"
            component={MultiSelect}
            readonly={this.state.readonly}
          />
        </FormGroup>
      );
    }

    return null;
  }

  renderFoodOptions() {
    if (!this.props.included) {
      return null;
    }

    const hasFood = this.props.included.includes("FOOD");

    if (hasFood) {
      const foodOptionsTranslated = foodOptions.map(opt => ({
        id: opt,
        name: this.props.intl.formatMessage({ id: `food.${opt.toLowerCase()}` })
      }));
      return (
        <FormGroup>
          <H2>
            <FormattedMessage {...messages.event.foodOptions.title} />
          </H2>
          <Field
            placeholder={this.props.intl.formatMessage(
              messages.event.foodOptions.food
            )}
            data={foodOptionsTranslated}
            valueField="id"
            textField="name"
            name="foodOptions"
            component={MultiSelect}
            readonly={this.state.readonly}
          />
        </FormGroup>
      );
    }

    return null;
  }

  renderShoeSizes() {
    if (!this.props.included) {
      return null;
    }

    const hasShoes = this.props.included.includes("SHOE");
    const shoeSizeOptions = shoeSizes.map(size => ({
      id: size,
      name: size
    }));

    if (hasShoes) {
      return (
        <FormGroup>
          <H2>
            <FormattedMessage {...messages.event.shoeSizeChoices.title} />
          </H2>
          <Field
            placeholder={this.props.intl.formatMessage(
              messages.event.shoeSizeChoices.shoeSizes
            )}
            data={shoeSizeOptions}
            valueField="id"
            textField="name"
            name="shoeSizes"
            component={MultiSelect}
            readonly={this.state.readonly}
          />
        </FormGroup>
      );
    }

    return null;
  }

  handleConfirmEvent() {
    this.setState({
      confirming: true
    });

    this.props.confirmEvent(this.props.event);
  }

  toggleConfirmModal(e) {
    e && e.preventDefault();
    this.setState({
      showConfirmModal: !this.state.showConfirmModal
    });
  }

  renderButtons() {
    if (this.props.isTeamleaderView) {
      return null;
    }
    if (this.state.readonly) {
      return (
        <ButtonWrapper>
          <BigGrayButton
            onClick={this.toggleReadonly}
            label={this.props.intl.formatMessage(messages.editButton)}
            type="button"
          />
          {this.state.eventStatus === "DRAFT" && this.props.event.stations ? (
            <Button
              onClick={this.toggleConfirmModal}
              label={this.props.intl.formatMessage(messages.confirmButton)}
              type="button"
            />
          ) : null}
        </ButtonWrapper>
      );
    }

    return (
      <Button
        type="submit"
        busy={this.state.submitting}
        label={this.props.intl.formatMessage(messages.saveButton)}
      />
    );
  }

  handleEventSubmit(fieldValues) {
    this.setState({
      submitting: true
    });

    const fieldValuesWithContactList = Object.assign({}, fieldValues, {
      contactPersons: this.state.contactList.filter(c => !c.toBeDeleted)
    });
    if (this.props.event) {
      fieldValuesWithContactList.id = this.props.event.id;
    }

    this.props.submitEvent(fieldValuesWithContactList);
  }

  render() {
    /*const additionalQuestionOptions = additionalQuestions.map(q => ({
      id: q,
      value: this.props.intl.formatMessage({
        id: `lists.additionalQuestions.${q}`
      })
    }));*/
    return (
      <Fragment>
        <form onSubmit={this.props.handleSubmit(this.handleEventSubmit)}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Field
                  name="name"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.name
                  )}
                  type="text"
                  component={Input}
                  validate={[required, minLength3, maxLength250]}
                  readonly={this.state.readonly}
                />
                <Field
                  name="date"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.date
                  )}
                  type="text"
                  component={DatePicker}
                  validate={[required, minLength3]}
                  readonly={this.state.readonly}
                />
                <Field
                  name="startTime"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.startTime
                  )}
                  type="text"
                  component={TimePicker}
                  validate={[required, minLength3]}
                  readonly={this.state.readonly}
                />
                <Field
                  name="city"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.city
                  )}
                  component={Input}
                  type="text"
                  validate={[required, maxLength250]}
                  readonly={this.state.readonly}
                />
              </FormGroup>
              {/*
                <FormGroup>
                  <Field
                    name="included"
                    placeholder={this.props.intl.formatMessage(
                      messages.event.included.title
                    )}
                    component={withLoadedProps(ListSelect, {
                      display: "block"
                    })}
                    valueField="id"
                    textField="value"
                    data={additionalQuestionOptions}
                    readonly={this.state.readonly}
                  />
                </FormGroup>
              </FormGroup>
              {this.renderShirtSizes()}
              {this.renderFoodOptions()}
              {this.renderShoeSizes()}
              */}
              <FormGroup>
                <H2>
                  <FormattedMessage {...messages.event.organizer.title} />
                </H2>
                <Field
                  name="organizerNumber"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.organizer.organizerNumber
                  )}
                  type="text"
                  component={Input}
                  validate={[required, organizerNumber]}
                  readonly={this.state.readonly}
                />
                <Field
                  name="organizerName"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.organizer.organizerName
                  )}
                  type="text"
                  component={Input}
                  validate={[required]}
                  readonly={this.state.readonly}
                />
                <Field
                  name="organizerWebsite"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.organizer.organizerWebsite
                  )}
                  type="text"
                  component={Input}
                  readonly={this.state.readonly}
                />
                <Field
                  name="organizerEmail"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.organizer.organizerEmail
                  )}
                  type="text"
                  component={Input}
                  readonly={this.state.readonly}
                />
                <Field
                  name="invoiceAddress"
                  placeholder={this.props.intl.formatMessage(
                    messages.event.organizer.invoiceAddress
                  )}
                  type="text"
                  component={Input}
                  readonly={this.state.readonly}
                />
              </FormGroup>
              <ContactForm
                intl={this.props.intl}
                readonly={this.state.readonly}
                contactList={this.state.contactList}
                updateContactList={this.updateContactListFromForm}
                type="event"
              />
              {this.renderButtons()}
            </Col>
          </Row>
          <ConfirmationModal
            title={this.props.intl.formatMessage(messages.confirmModalTitle)}
            label={this.props.intl.formatMessage(
              messages.cancelConfirmModalButton
            )}
            show={this.state.showConfirmModal}
            handleConfirm={this.handleConfirmEvent}
            buttonText={this.props.intl.formatMessage(
              messages.confirmModalButton
            )}
            handleHide={this.toggleConfirmModal}
            text={this.props.intl.formatMessage(messages.confirmModalText, {
              name: this.props.event ? this.props.event.name : ""
            })}
            busy={this.state.confirming}
            theme="pink"
          />
        </form>
      </Fragment>
    );
  }
}

EventEdit = reduxForm({
  form: "editEvent",
  enableReinitialize: true
  // destroyOnUnmount: false,
})(EventEdit);

// Decorate with connect to read form values
const selector = formValueSelector("editEvent"); // <-- same as form name

EventEdit = connect(state => {
  // Included is needed to know if we should render shirt sizes, food options, and shoe sizes.
  const included = selector(state, "included");

  return {
    included
  };
})(EventEdit);

export default injectIntl(EventEdit);
