import React, { Component, Fragment } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col } from "react-bootstrap/lib";
import { Field, reduxForm } from "redux-form";
import messages from "./messages";
import { GeneralError } from "../../../../components/index";

import {
  P,
  FormGroup,
  Input,
  TextSection,
  ContentWrapper,
  H2,
  Container,
  DatePicker,
  TextArea,
  ConfirmationModal,
  TextLabel
} from "../../../../components";
import { BigGreenButton, BigGrayButton } from "../../../../components/Buttons";
import GroupAttendees from "./GroupAttendees";
import MapsComponent, { formatCoordinates } from '../../../../components/Map'

class GroupEngagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showConfirmModal: false,
      showRejectModal: false,
      confirming: false,
      rejecting: false
    };

    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.toggleRejectModal = this.toggleRejectModal.bind(this);
    this.onConfirmOffer = this.onConfirmOffer.bind(this);
    this.onRejectOffer = this.onRejectOffer.bind(this);
  }

  componentDidMount() {
    this.props.fetchGroup({ id: this.props.match.params.groupId });
    this.props.fetchGroupOffers({
	groupId: this.props.match.params.groupId,
        eventId: this.props.match.params.eventId,
    });
    this.props.fetchGroupOffer({
     eventId: this.props.match.params.eventId,
	stationId: this.props.match.params.stationId,
	groupId: this.props.match.params.groupId,
    });
  }

  componentWillUnmount() {
    this.props.resetGroupOfferError();
    this.props.resetGroup();
  }

  componentWillReceiveProps(nextProps) {
    // Handle accept error
    if (!this.props.groupOfferAcceptError && nextProps.groupOfferAcceptError) {
      this.setState({
        showConfirmModal: false,
        confirming: false
      });
    }

    // Handle reject error
    if (!this.props.groupOfferRejectError && nextProps.groupOfferRejectError) {
      this.setState({
        showRejectModal: false,
        rejecting: false
      });
    }
    if (
      this.props.groupOffer &&
      this.props.groupOffer.status !== nextProps.groupOffer.status
    ) {
      this.setState({
        showRejectModal: false,
        rejecting: false,
        showConfirmModal: false,
        confirming: false
      });
    }
  }

  toggleConfirmModal() {
    this.setState({
      showConfirmModal: !this.state.showConfirmModal
    });
  }

  onConfirmOffer() {
    this.setState({
      confirming: true
    });

    this.props.acceptStationOffer({
      eventId: this.props.match.params.eventId,
      groupId: this.props.match.params.groupId,
      stationId: this.props.match.params.stationId
    });
  }

  toggleRejectModal() {
    this.setState({
      showRejectModal: !this.state.showRejectModal
    });
  }

  onRejectOffer() {
    this.setState({
      rejecting: true
    });

    this.props.rejectStationOffer({
      eventId: this.props.match.params.eventId,
      groupId: this.props.match.params.groupId,
      stationId: this.props.match.params.stationId
    });
  }

  renderAcceptError() {
    if (this.props.groupOfferAcceptError) {
      return (
        <GeneralError>{this.props.groupOfferAcceptError.message}</GeneralError>
      );
    }

    return null;
  }

  renderRejectError() {
    if (this.props.groupOfferRejectError) {
      return (
        <GeneralError>{this.props.groupOfferRejectError.message}</GeneralError>
      );
    }

    return null;
  }

  renderStatusBasedContent(status) {
    switch (status) {
      case "REJECTED":
        return (
          <TextLabel>
            <FormattedMessage {...messages.offerRejectedText} />
          </TextLabel>
        );
      case "ACCEPTED":
        return (
          <TextLabel>
            <FormattedMessage {...messages.offerConfirmedText} />
          </TextLabel>
        );
      case "PROPOSAL":
      default:
        return (
          <Fragment>
            <BigGrayButton
              onClick={this.toggleRejectModal}
              label={this.props.intl.formatMessage(messages.rejectButton)}
            />
            <BigGreenButton
              onClick={this.toggleConfirmModal}
              label={this.props.intl.formatMessage(messages.confirmButton)}
            />
          </Fragment>
        );
    }
  }

  renderContent() {
    if (this.props.groupOfferError) {
      return <GeneralError>{this.props.groupOfferError.message}</GeneralError>;
    }
    if (!this.props.groupOffer) {
      return <i className="fas fa-spinner fa-pulse" />;
    }
    return (
      <Fragment>
        <H2>
          <FormattedMessage
            {...messages.confirmTitle}
            values={{
              event: this.props.groupOffer
                ? this.props.groupOffer.eventName
                : "",
              group: this.props.group ? this.props.group.name : ""
            }}
          />
        </H2>
        <Row>
          <Col sm={7}>
            <FormGroup>
              <Field
                name="station.name"
                type="text"
                placeholder={this.props.intl.formatMessage(messages.form.name)}
                component={Input}
                readonly
              />
              <Field
                name="station.start"
                type="date"
                placeholder={this.props.intl.formatMessage(messages.form.date)}
                component={DatePicker}
                readonly
              />
              <Field
                name="station.start"
                type="date"
                placeholder={this.props.intl.formatMessage(messages.form.start)}
                component={DatePicker}
                time
                date={false}
                readonly
              />
              <Field
                name="station.end"
                time
                date={false}
                type="date"
                placeholder={this.props.intl.formatMessage(messages.form.end)}
                component={DatePicker}
                readonly
              />
              <TextLabel>
                {this.props.initialValues.station.markerLocation ? this.props.initialValues.station.markerLocation : "" } {/*TODO: messages.js */}
              </TextLabel>
              <TextLabel>
                {this.props.initialValues.station.markerCoordinates ? formatCoordinates(this.props.initialValues.station.markerCoordinates) : "" }
              </TextLabel>
              {this.props.initialValues.station.markerCoordinates &&
                <MapsComponent
                  center={this.props.initialValues.station.markerCoordinates}
                  zoom={15}
                  markerLocation={this.props.initialValues.station.markerCoordinates}
                  showSearchBox={false}
                  readOnly={true}
                />
              }
              <Field
                name="volunteers"
                type="number"
                placeholder={this.props.intl.formatMessage(
                  messages.form.officialQuantity
                )}
                component={Input}
                readonly
              />
              <Field
                name="station.description"
                type="text"
                placeholder={this.props.intl.formatMessage(
                  messages.form.description
                )}
                component={TextArea}
                readonly
              />
              {this.props.groupOffer.station.paymentType !== "NOCOMPENSATION" && (
                <Field
                  name="station.paymentType"
                  type="text"
                  initialValue={this.props.intl.formatMessage({
                    id: `lists.payments.${
                      this.props.groupOffer.station.paymentType
                    }`
                  })}
                  placeholder={this.props.intl.formatMessage(
                    messages.form.paymentType
                  )}
                  component={Input}
                  readonly/>
              )}
              {this.props.groupOffer.station.paymentType === "GROUPPAYMENT" && (
                <Field
                  name="station.groupPayment"
                  type="number"
                  placeholder={this.props.intl.formatMessage(
                    messages.form.groupPayment
                  )}
                  component={Input}
                  readonly
                />
              )}
              {(this.props.groupOffer.station.paymentType === "PERPERSON" ||
              this.props.groupOffer.station.paymentType === "HOURLYRATE") && (
                <Fragment>
                  <Field
                    name="station.paymentAdult"
                    type="number"
                    placeholder={this.props.intl.formatMessage(
                      messages.form.paymentAdult
                    )}
                    component={Input}
                    readonly
                  />
                  <Field
                    name="station.paymentChild"
                    type="number"
                    placeholder={this.props.intl.formatMessage(
                      messages.form.paymentChild
                    )}
                    component={Input}
                    readonly
                  />
                </Fragment>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={9}>
            <TextSection>
              <P>
                <FormattedMessage {...messages.confirmText} />
              </P>
            </TextSection>
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            {this.renderAcceptError()}
            {this.renderRejectError()}
            {this.renderStatusBasedContent(this.props.groupOffer.status)}
          </Col>
        </Row>
      </Fragment>
    );
  }

    render() {
    return (
      <ContentWrapper>
        <Container>
          {this.renderContent()}
          {/* <BigGrayButton
              onClick={() => this.props.updateOfferAttendees(
                {eventId: this.props.groupOffer.eventId, 
                  groupId: this.props.groupOffer.groupId,
                  stationId: this.props.groupOffer.stationId,
                  attendees: [5069]})}
              label={this.props.intl.formatMessage(messages.rejectButton)}
            /> */}
          {this.props.groupOffer &&
            this.props.groupOffer.status === "ACCEPTED" && (
              <GroupAttendees
                offer={this.props.groupOffer}
		offers={this.props.groupOffers}
                group={this.props.group}
              />
            )}

          <ConfirmationModal
            buttonText={this.props.intl.formatMessage(
              messages.confirmModalRejectButton
            )}
            label={this.props.intl.formatMessage(
              messages.confirmModalCancelButton
            )}
            show={this.state.showConfirmModal}
            handleConfirm={this.onConfirmOffer}
            handleHide={this.toggleConfirmModal}
            title={this.props.intl.formatMessage(messages.confirmModalTitle)}
            text={
              <FormattedMessage
                {...messages.confirmModalText}
                values={{
                  name: this.props.groupOffer
                    ? this.props.groupOffer.eventName
                    : ""
                }}
              />
            }
            busy={this.state.confirming}
          />
          <ConfirmationModal
            buttonText={this.props.intl.formatMessage(
              messages.rejectModalRejectButton
            )}
            label={this.props.intl.formatMessage(
              messages.rejectModalCancelButton
            )}
            show={this.state.showRejectModal}
            handleConfirm={this.onRejectOffer}
            handleHide={this.toggleRejectModal}
            title={this.props.intl.formatMessage(messages.rejectModalTitle)}
            text={
              <FormattedMessage
                {...messages.rejectModalText}
                values={{
                  name: this.props.groupOffer
                    ? this.props.groupOffer.eventName
                    : ""
                }}
              />
            }
            busy={this.state.rejecting}
          />
        </Container>
      </ContentWrapper>
    );
  }
}

const GroupEngagementWithForm = reduxForm({
  enableReinitialize: true,
  form: "group-confirm"
})(GroupEngagement);

export default injectIntl(GroupEngagementWithForm);
