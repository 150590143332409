import { connect } from "react-redux";
import { withRouter } from "react-router";
import { default as GroupOffersComponent } from "./GroupOffers";
import { fetchGroupsOffers } from "./reducer";

const mapStateToProps = (state) => ({
  groupOffers: state.offers.groupOffers,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupsOffers: data => dispatch(fetchGroupsOffers(data)),
});

export const GroupOffers = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupOffersComponent));

