export const municipalities = [
  {id: '1440', name: 'Ale'},
  {id: '1489', name: 'Alingsås' },
  {id: '764', name: 'Alvesta' },
  {id: '604', name: 'Aneby'},
  {id: '1984', name: 'Arboga'},
  {id: '2506', name: 'Arjeplog'},
  {id: '2505', name: 'Arvidsjaur'},
  {id: '1784', name: 'Arvika'},
  {id: '1882', name: 'Askersund'},
  {id: '2084', name: 'Avesta'},
  {id: '1460', name: 'Bengtsfors'},
  {id: '2326', name: 'Berg'},
  {id: '2403', name: 'Bjurholm'},
  {id: '1260', name: 'Bjuv'},
  {id: '2582', name: 'Boden'},
  {id: '1443', name: 'Bollebygd'},
  {id: '2183', name: 'Bollnäs'},
  {id: '885', name: 'Borgholm'},
  {id: '2081', name: 'Borlänge'},
  {id: '1490', name: 'Borås'},
  {id: '127', name: 'Botkyrka'},
  {id: '560', name: 'Boxholm'},
  {id: '1272', name: 'Bromölla'},
  {id: '2305', name: 'Bräcke'},
  {id: '1231', name: 'Burlöv'},
  {id: '1278', name: 'Båstad'},
  {id: '1438', name: 'Dals-Ed'},
  {id: '162', name: 'Danderyd'},
  {id: '1862', name: 'Degerfors'},
  {id: '2425', name: 'Dorotea'},
  {id: '1730', name: 'Eda'},
  {id: '125', name: 'Ekerö'},
  {id: '686', name: 'Eksjö'},
  {id: '862', name: 'Emmaboda'},
  {id: '381', name: 'Enköping'},
  {id: '484', name: 'Eskilstuna'},
  {id: '1285', name: 'Eslöv'},
  {id: '1445', name: 'Essunga'},
  {id: '1982', name: 'Fagersta'},
  {id: '1382', name: 'Falkenberg'},
  {id: '1499', name: 'Falköping'},
  {id: '2080', name: 'Falun'},
  {id: '1782', name: 'Filipstad'},
  {id: '562', name: 'Finspång'},
  {id: '482', name: 'Flen'},
  {id: '1763', name: 'Forshaga'},
  {id: '1439', name: 'Färgelanda'},
  {id: '2026', name: 'Gagnef'},
  {id: '662', name: 'Gislaved'},
  {id: '461', name: 'Gnesta'},
  {id: '617', name: 'Gnosjö'},
  {id: '980', name: 'Gotland'},
  {id: '1764', name: 'Grums'},
  {id: '1444', name: 'Grästorp'},
  {id: '1447', name: 'Gullspång'},
  {id: '2523', name: 'Gällivare'},
  {id: '2180', name: 'Gävle'},
  {id: '1480', name: 'Göteborg'},
  {id: '1471', name: 'Götene'},
  {id: '643', name: 'Habo'},
  {id: '1783', name: 'Hagfors'},
  {id: '1861', name: 'Hallsberg'},
  {id: '1961', name: 'Hallstahammar'},
  {id: '1380', name: 'Halmstad'},
  {id: '1761', name: 'Hammarö'},
  {id: '136', name: 'Haninge'},
  {id: '2583', name: 'Haparanda'},
  {id: '331', name: 'Heby'},
  {id: '2083', name: 'Hedemora'},
  {id: '1283', name: 'Helsingborg'},
  {id: '1466', name: 'Herrljunga'},
  {id: '1497', name: 'Hjo'},
  {id: '2104', name: 'Hofors'},
  {id: '126', name: 'Huddinge'},
  {id: '2184', name: 'Hudiksvall'},
  {id: '860', name: 'Hultsfred'},
  {id: '1315', name: 'Hylte'},
  {id: '305', name: 'Håbo'},
  {id: '1863', name: 'Hällefors'},
  {id: '2361', name: 'Härjedalen'},
  {id: '2280', name: 'Härnösand'},
  {id: '1401', name: 'Härryda'},
  {id: '1293', name: 'Hässleholm'},
  {id: '1284', name: 'Höganäs'},
  {id: '821', name: 'Högsby'},
  {id: '1266', name: 'Hörby'},
  {id: '1267', name: 'Höör'},
  {id: '2510', name: 'Jokkmokk'},
  {id: '123', name: 'Järfälla'},
  {id: '680', name: 'Jönköping'},
  {id: '2514', name: 'Kalix'},
  {id: '880', name: 'Kalmar'},
  {id: '1446', name: 'Karlsborg'},
  {id: '1082', name: 'Karlshamn'},
  {id: '1883', name: 'Karlskoga'},
  {id: '1080', name: 'Karlskrona'},
  {id: '1780', name: 'Karlstad'},
  {id: '483', name: 'Katrineholm'},
  {id: '1715', name: 'Kil'},
  {id: '513', name: 'Kinda'},
  {id: '2584', name: 'Kiruna'},
  {id: '1276', name: 'Klippan'},
  {id: '330', name: 'Knivsta'},
  {id: '2282', name: 'Kramfors'},
  {id: '1290', name: 'Kristianstad'},
  {id: '1781', name: 'Kristinehamn'},
  {id: '2309', name: 'Krokom'},
  {id: '1881', name: 'Kumla'},
  {id: '1384', name: 'Kungsbacka'},
  {id: '1960', name: 'Kungsör'},
  {id: '1482', name: 'Kungälv'},
  {id: '1261', name: 'Kävlinge'},
  {id: '1983', name: 'Köping'},
  {id: '1381', name: 'Laholm'},
  {id: '1282', name: 'Landskrona'},
  {id: '1860', name: 'Laxå'},
  {id: '1814', name: 'Lekeberg'},
  {id: '2029', name: 'Leksand'},
  {id: '1441', name: 'Lerum'},
  {id: '761', name: 'Lessebo'},
  {id: '186', name: 'Lidingö'},
  {id: '1494', name: 'Lidköping'},
  {id: '1462', name: 'LillaEdet'},
  {id: '1885', name: 'Lindesberg'},
  {id: '580', name: 'Linköping'},
  {id: '781', name: 'Ljungby'},
  {id: '2161', name: 'Ljusdal'},
  {id: '1864', name: 'Ljusnarsberg'},
  {id: '1262', name: 'Lomma'},
  {id: '2085', name: 'Ludvika'},
  {id: '2580', name: 'Luleå'},
  {id: '1281', name: 'Lund'},
  {id: '2481', name: 'Lycksele'},
  {id: '1484', name: 'Lysekil'},
  {id: '1280', name: 'Malmö'},
  {id: '2023', name: 'Malung-Sälen'},
  {id: '2418', name: 'Malå'},
  {id: '1493', name: 'Mariestad'},
  {id: '1463', name: 'Mark'},
  {id: '767', name: 'Markaryd'},
  {id: '1461', name: 'Mellerud'},
  {id: '586', name: 'Mjölby'},
  {id: '2062', name: 'Mora'},
  {id: '583', name: 'Motala'},
  {id: '642', name: 'Mullsjö'},
  {id: '1430', name: 'Munkedal'},
  {id: '1762', name: 'Munkfors'},
  {id: '1481', name: 'Mölndal'},
  {id: '861', name: 'Mönsterås'},
  {id: '840', name: 'Mörbylånga'},
  {id: '182', name: 'Nacka'},
  {id: '1884', name: 'Nora'},
  {id: '1962', name: 'Norberg'},
  {id: '2132', name: 'Nordanstig'},
  {id: '2401', name: 'Nordmaling'},
  {id: '581', name: 'Norrköping'},
  {id: '188', name: 'Norrtälje'},
  {id: '2417', name: 'Norsjö'},
  {id: '881', name: 'Nybro'},
  {id: '140', name: 'Nykvarn'},
  {id: '480', name: 'Nyköping'},
  {id: '192', name: 'Nynäshamn'},
  {id: '682', name: 'Nässjö'},
  {id: '2101', name: 'Ockelbo'},
  {id: '1060', name: 'Olofström'},
  {id: '2034', name: 'Orsa'},
  {id: '1421', name: 'Orust'},
  {id: '1273', name: 'Osby'},
  {id: '882', name: 'Oskarshamn'},
  {id: '2121', name: 'Ovanåker'},
  {id: '481', name: 'Oxelösund'},
  {id: '2521', name: 'Pajala'},
  {id: '1402', name: 'Partille'},
  {id: '1275', name: 'Perstorp'},
  {id: '2581', name: 'Piteå'},
  {id: '2303', name: 'Ragunda'},
  {id: '2409', name: 'Robertsfors'},
  {id: '1081', name: 'Ronneby'},
  {id: '2031', name: 'Rättvik'},
  {id: '1981', name: 'Sala'},
  {id: '128', name: 'Salem'},
  {id: '2181', name: 'Sandviken'},
  {id: '191', name: 'Sigtuna'},
  {id: '1291', name: 'Simrishamn'},
  {id: '1265', name: 'Sjöbo'},
  {id: '1495', name: 'Skara'},
  {id: '2482', name: 'Skellefteå'},
  {id: '1904', name: 'Skinnskatteberg'},
  {id: '1264', name: 'Skurup'},
  {id: '1496', name: 'Skövde'},
  {id: '2061', name: 'Smedjebacken'},
  {id: '2283', name: 'Sollefteå'},
  {id: '163', name: 'Sollentuna'},
  {id: '184', name: 'Solna'},
  {id: '2422', name: 'Sorsele'},
  {id: '1427', name: 'Sotenäs'},
  {id: '1230', name: 'Staffanstorp'},
  {id: '1415', name: 'Stenungsund'},
  {id: '180', name: 'Stockholm'},
  {id: '1760', name: 'Storfors'},
  {id: '2421', name: 'Storuman'},
  {id: '486', name: 'Strängnäs'},
  {id: '1486', name: 'Strömstad'},
  {id: '2313', name: 'Strömsund'},
  {id: '183', name: 'Sundbyberg'},
  {id: '2281', name: 'Sundsvall'},
  {id: '1766', name: 'Sunne'},
  {id: '1907', name: 'Surahammar'},
  {id: '1214', name: 'Svalöv'},
  {id: '1263', name: 'Svedala'},
  {id: '1465', name: 'Svenljunga'},
  {id: '1785', name: 'Säffle'},
  {id: '2082', name: 'Säter'},
  {id: '684', name: 'Sävsjö'},
  {id: '2182', name: 'Söderhamn'},
  {id: '582', name: 'Söderköping'},
  {id: '181', name: 'Södertälje'},
  {id: '1083', name: 'Sölvesborg'},
  {id: '1435', name: 'Tanum'},
  {id: '1472', name: 'Tibro'},
  {id: '1498', name: 'Tidaholm'},
  {id: '360', name: 'Tierp'},
  {id: '2262', name: 'Timrå'},
  {id: '763', name: 'Tingsryd'},
  {id: '1419', name: 'Tjörn'},
  {id: '1270', name: 'Tomelilla'},
  {id: '1737', name: 'Torsby'},
  {id: '834', name: 'Torsås'},
  {id: '1452', name: 'Tranemo'},
  {id: '687', name: 'Tranås'},
  {id: '1287', name: 'Trelleborg'},
  {id: '1488', name: 'Trollhättan'},
  {id: '488', name: 'Trosa'},
  {id: '138', name: 'Tyresö'},
  {id: '160', name: 'Täby'},
  {id: '1473', name: 'Töreboda'},
  {id: '1485', name: 'Uddevalla'},
  {id: '1491', name: 'Ulricehamn'},
  {id: '2480', name: 'Umeå'},
  {id: '114', name: 'Upplands Väsby'},
  {id: '139', name: 'Upplands-Bro'},
  {id: '380', name: 'Uppsala'},
  {id: '760', name: 'Uppvidinge'},
  {id: '584', name: 'Vadstena'},
  {id: '665', name: 'Vaggeryd'},
  {id: '563', name: 'Valdemarsvik'},
  {id: '115', name: 'Vallentuna'},
  {id: '2021', name: 'Vansbro'},
  {id: '1470', name: 'Vara'},
  {id: '1383', name: 'Varberg'},
  {id: '187', name: 'Vaxholm'},
  {id: '1233', name: 'Vellinge'},
  {id: '685', name: 'Vetlanda'},
  {id: '2462', name: 'Vilhelmina'},
  {id: '884', name: 'Vimmerby'},
  {id: '2404', name: 'Vindeln'},
  {id: '428', name: 'Vingåker'},
  {id: '1442', name: 'Vårgårda'},
  {id: '1487', name: 'Vänersborg'},
  {id: '2460', name: 'Vännäs'},
  {id: '120', name: 'Värmdö'},
  {id: '683', name: 'Värnamo'},
  {id: '883', name: 'Västervik'},
  {id: '1980', name: 'Västerås'},
  {id: '780', name: 'Växjö'},
  {id: '512', name: 'Ydre'},
  {id: '1286', name: 'Ystad'},
  {id: '1492', name: 'Åmål'},
  {id: '2260', name: 'Ånge'},
  {id: '2321', name: 'Åre'},
  {id: '1765', name: 'Årjäng'},
  {id: '2463', name: 'Åsele'},
  {id: '1277', name: 'Åstorp'},
  {id: '561', name: 'Åtvidaberg'},
  {id: '765', name: 'Älmhult'},
  {id: '2039', name: 'Älvdalen'},
  {id: '319', name: 'Älvkarleby'},
  {id: '2560', name: 'Älvsbyn'},
  {id: '1292', name: 'Ängelholm'},
  {id: '1407', name: 'Öckerö'},
  {id: '509', name: 'Ödeshög'},
  {id: '1880', name: 'Örebro'},
  {id: '1257', name: 'Örkelljunga'},
  {id: '2284', name: 'Örnsköldsvik'},
  {id: '2380', name: 'Östersund'},
  {id: '117', name: 'Österåker'},
  {id: '382', name: 'Östhammar'},
  {id: '1256', name: 'Östra Göinge'},
  {id: '2513', name: 'Överkalix'},
  {id: '2518', name: 'Övertorneå'},
];
