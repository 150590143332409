import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmTitle: {
    id: 'confirm-group.title',
  },
  confirmText2: {
    id: 'new-group.confirmation.text-2',
  },
  group: {
    numberOfMembers: {
      id: 'confirm-group.number-of-members.placeholder',
    },
  },
  confirmationModalTitle: {
    id: 'confirm-group.confirmation-modal.title',
  },
  confirmationModalText: {
    id: 'confirm-group.confirmation-modal.text',
  },
  cancelConfirmButton: {
    id: 'confirm-group.cancel-confirm-button',
  },
  confirmModalButton: {
    id: 'confirm-group.confirm-button',
  },
  confirmButton: {
    id: 'confirm-group-button',
  },
  nextButton: {
    id: 'confirm-next-button',
  },
});
