const shirtSizes = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
];

export default shirtSizes;
