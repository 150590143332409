import React, { Component, Fragment } from "react";
import {
  H1,
  H2,
  Input,
  DatePicker,
  FormGroup,
  TimePicker,
  ConfirmationModal,
} from "../../../components";
import { Button } from "../../../components/Buttons";
import { Row, Col } from "react-bootstrap/lib";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { required, minLength3, maxLength250 } from "../../../validators";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";

class EventCopy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitClicked: false,
      nextClicked: false,
      userAdded: false,
      showModal: false,
    };

    this.handleNext = this.handleNext.bind(this);
    this.makeEvent = this.makeEvent.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount () {
    const event = this.props.newEvent ? this.props.newEvent : this.props.event
    this.props.initialize({ name: event.name, 
      city: event.city, 
      newStart: new Date(event.startTime), 
      date: this.props.newEvent ? new Date(event.date) : ''});
  }

  dateDayDifference(newDate, oldDate) {
    const date1 = new Date(newDate);
    const date2 = new Date(oldDate);

    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc1 - utc2) / (1000 * 60 * 60 * 24));
  }

  incrementDate(oldDate, daysDiff, hoursDiff, minsDiff) {
    const date = new Date(oldDate);
    date.setDate(date.getDate() + daysDiff)
    date.setHours(date.getHours() + hoursDiff);
    date.setMinutes(date.getMinutes() + minsDiff);

    return date
  }

  makeEvent(fieldValues) {
    let newEvent = Object.assign({}, this.props.event);
    newEvent = Object.assign(newEvent, fieldValues);
    newEvent.id = null;
    newEvent.status = "DRAFT";

    // Update station start/end time according to new event start time
    const newStart = new Date(fieldValues.newStart);
    const oldStart = new Date(this.props.event.startTime);
    const hoursDiff = newStart.getHours() - oldStart.getHours();
    const minsDiff = newStart.getMinutes() - oldStart.getMinutes();
    
    const daysDiff = this.dateDayDifference(fieldValues.date, this.props.event.date);

    const stations = JSON.parse(JSON.stringify(this.props.event.stations));
    if (stations) {
      stations.forEach(s => {
        s.start = this.incrementDate(s.start, daysDiff, hoursDiff, minsDiff);
        s.end = this.incrementDate(s.end, daysDiff, hoursDiff, minsDiff);
 
        s.id = Math.floor(Math.random() * 10000);
      });
    }

    newEvent.startTime = fieldValues.newStart;
    newEvent.stations = [];

    return {
      ...newEvent,
      stations: stations,
    };
  }

  handleNext(fieldValues) {
    this.setState({
      fieldValues: fieldValues
    });
    if(fieldValues.name === this.props.event.name) {
      this.toggleModal();
    } else {
      this.setState({
        nextClicked: true
      });
      this.props.onClickNext(this.makeEvent(fieldValues), 2);
    }
  }

  toggleModal(e) {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  handleConfirm() {
    this.setState({
      nextClicked: true
    });
    this.props.onClickNext(this.makeEvent(this.state.fieldValues), 2);
  }

  render() {
    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1>
              <FormattedMessage {...messages.copyEvent.settingsTitle} />
            </H1>
          </Col>
        </Row>
        <form onSubmit={this.props.handleSubmit(this.handleNext)}>
          <Row>
            <Col sm={5}>
              <H2>
                <FormattedMessage
                  {...messages.registerEvent.form.event.title}
                />
              </H2>
              <FormGroup>
                <Field
                  name="name"
                  placeholder={this.props.intl.formatMessage(
                    messages.copyEvent.form.name
                  )}
                  type="text"
                  component={Input}
                  validate={[required, minLength3, maxLength250]}
                />
                <Field
                  name="date"
                  placeholder={this.props.intl.formatMessage(
                    messages.copyEvent.form.date
                  )}
                  component={DatePicker}
                  validate={[required]}
                />
                <Field
                  name="city"
                  placeholder={this.props.intl.formatMessage(
                    messages.copyEvent.form.city
                  )}
                  defaultValue={this.props.event.city}
                  component={Input}
                  type="text"
                  validate={[required, maxLength250]}
                />
                <Field
                  name="newStart"
                  placeholder={this.props.intl.formatMessage(
                    messages.copyEvent.form.startTime
                  )}
                  component={TimePicker}
                  validate={[required]}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={10}>
              <Button
                type="submit"
                busy={this.state.nextClicked}
                label={this.props.intl.formatMessage(
                  messages.copyEvent.nextButton
                )}
              />
            </Col>
          </Row>
          <ConfirmationModal
            title={this.props.intl.formatMessage(messages.copyEvent.sameNameModal.title)}
            label={this.props.intl.formatMessage(
              messages.copyEvent.sameNameModal.cancel
            )}
            show={this.state.showModal}
            handleConfirm={this.handleConfirm}
            buttonText={this.props.intl.formatMessage(
              messages.copyEvent.sameNameModal.confirm
            )}
            handleHide={this.toggleModal}
            text={this.props.intl.formatMessage(messages.copyEvent.sameNameModal.text)}
            theme="pink"
          />
        </form>
      </Fragment>
    );
  }
}

EventCopy = reduxForm({
  form: "event"
})(EventCopy);

// Decorate with connect to read form values
const selector = formValueSelector("event"); // <-- same as form name

EventCopy = connect(state => {
  const included = selector(state, "included");

  return {
    included
  };
})(EventCopy);

export default injectIntl(EventCopy);
