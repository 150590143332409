import { defineMessages } from 'react-intl';

export default defineMessages({
  eventsLink: {
    id: 'top-navigation.events-link',
  },
  teamLeadersLink: {
    id: 'top-navigation.teamleaders-link',
  },
  volunteersLink: {
    id: 'top-navigation.volunteers-link',
  },
  list: {
    title: {
      id: 'teamleader.list.title'
    }
  }
});
