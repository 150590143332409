import { connect } from 'react-redux';
import { reset, submitGroup, confirmGroup } from '../reducer';
import GroupConfirm from './GroupConfirm';

const mapStateToProps = (state, ownProps) => ({
  group: ownProps.group,
  status: state.group.status,
  initialValues: ownProps.group,
});

const mapDispatchToProps = dispatch => ({
  submitGroup: data => dispatch(submitGroup(data)),
  resetEvent: () => dispatch(reset()),
  confirmGroup: data => dispatch(confirmGroup(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupConfirm);
