import { call, put } from 'redux-saga/effects';
import { REGISTER_SUCCESS, REGISTER_FAILED } from './reducer';
import { signUp } from './service';

export function* registerSaga(action) {
  try {
    const response = yield call(() => signUp(action.payload));
    yield put({type: REGISTER_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: REGISTER_FAILED, payload: e});
  }
}
