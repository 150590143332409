import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Input, Select } from "../../../components/index";
import { SmallPinkButton } from "../../../components/Buttons";
import { SmallGrayLinkButton } from "../../../components/Links";
import { status } from "./reducer";
import { P } from "../../../components";
import { required, password } from "../../../validators/index";
import Wrapper from "../Components/Wrapper";
import ButtonWrapper from "../Components/ButtonWrapper";
import styled from "styled-components";
import { colors } from "../../../constants/styling-constants";
import { Row, Col } from "react-bootstrap/lib";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { countryCodes } from "../../../constants/country-codes";
import PasswordVerification from "../Components/PasswordVerification";

const TextElement = styled(P)`
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  text-align: ${props => props.textAlign};
  padding-top: ${props => props.paddingTop};
`;

const StyledError = styled.p`
  color: ${colors.pink};
`;


const validate = (values) => {
  const errors = {};

  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = "Lösenorden är inte likadana";
  }

  return errors;
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
    };

    // this.passWordIsValid = this.passWordIsValid.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  componentWillUnmount() {
    this.props.resetRegister();
  }

  // Wait and see if we need this logic
  /* passWordIsValid(password) {
   if (!password) return true;
   const testString = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
   return !password.match(testString);
   }*/

  onPasswordChange(e, fieldValue) {
    this.setState({ password: fieldValue });
  }

  renderError() {
    if (this.props.status === status.REGISTER_FAILED) {
      let message = null;

      if (this.props.registerError.code === "InvalidParameterException" && this.props.registerError.message.indexOf("phone") !== -1) {
        message = messages.error.InvalidPhoneNumber;
      } else {
        message = messages.error[this.props.registerError.code];
      }
      if (!message) {
        message = messages.error.default;
        console.warn("label not found for error:", this.props.registerError.code);
      }
      return <StyledError><FormattedMessage {...message} /></StyledError>;
    }

    return null;
  }

  render() {
    if (this.props.status === status.REGISTERED) {
      return <Redirect to="/verify-email" />;
    }

    return (
      <Wrapper>
        <TextElement
          fontSize="14px"
          textAlign="left"
          color={colors.lightGray3}
        ><FormattedMessage {...messages.introText1} /></TextElement>
        <TextElement
          fontSize="14px"
          textAlign="left"
          color={colors.lightGray3}
        ><FormattedMessage {...messages.introText2} /></TextElement>
        <TextElement
          fontSize="14px"
          textAlign="left"
          color={colors.lightGray3}
        ><FormattedMessage {...messages.introText3} /></TextElement>
        <form
          onSubmit={this.props.handleSubmit}
          noValidate
        >
          <Field
            name="email"
            placeholder={this.props.intl.formatMessage(messages.form.email)}
            type="email"
            component={Input}
            validate={[required]}
          />
          <Field
            name="firstName"
            placeholder={this.props.intl.formatMessage(messages.form.firstName)}
            type="text"
            component={Input}
            validate={[required]}
          />
          <Field
            name="lastName"
            placeholder={this.props.intl.formatMessage(messages.form.lastName)}
            type="text"
            component={Input}
            validate={[required]}
          />
          <Row>
            <Col xs={5}>
              <Field
                name="countryCode"
                placeholder={this.props.intl.formatMessage(messages.form.countryCode)}
                component={Select}
                validate={[required]}
                data={countryCodes}
              />
            </Col>
            <Col xs={7}>
              <Field
                name="phone"
                placeholder={this.props.intl.formatMessage(messages.form.phone)}
                type="tel"
                component={Input}
                validate={[required]}
              />
            </Col>
          </Row>
          <Field
            name="password"
            placeholder={this.props.intl.formatMessage(messages.form.password)}
            type="password"
            component={Input}
            validate={[required, password]}
            onChange={this.onPasswordChange}
          />
          <Field
            name="repeatPassword"
            placeholder={this.props.intl.formatMessage(messages.form.repeatPassword)}
            type="password"
            component={Input}
          />
          {this.renderError()}
          <PasswordVerification
            intl={this.props.intl}
            password={this.state.password}
          />
          <ButtonWrapper>
            <SmallPinkButton label={this.props.intl.formatMessage(messages.registerButton)} />
            <SmallGrayLinkButton to="/login"><FormattedMessage {...messages.loginButton} /></SmallGrayLinkButton>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  }
}

Register.propTypes = {
  status: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired, // Redux form function that will call this.props.onSubmit if form is valid
};

export default reduxForm({
  form: "register",
  validate,
})(injectIntl(Register));
