import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchSendOut } from "../reducer";
import SendOut from "./SendOut";

const mapStateToProps = (state) => ({
  status: state.sendOuts.fetchSingleStatus,
  sendOut: state.sendOuts.sendOut,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSendOut: data => dispatch(fetchSendOut(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SendOut));
