import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { submitGroup } from "../reducer";
import messages from "./messages";
import { connect } from "react-redux";
import { updateOfferAttendees, fetchGroupOffers } from "../../Offer/reducer";
import { DeleteButton, BigGreenButton } from "../../../../components/Buttons";
import BootstrapTable from "react-bootstrap-table-next";
import { H3 } from "../../../../components";
import GroupAttendeesForm from "./GroupAttendeesForm";

class GroupAttendees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addVolunteersPopup: false
    };
    this.toggleAddVolunteersModal = this.toggleAddVolunteersModal.bind(this);
    this.deleteAttendees = this.deleteAttendees.bind(this);
  }
  getColumns(intl) {
    const columns = [
      { dataField: "id", text: "id", hidden: true },
      {
        dataField: "name",
        text: intl.formatMessage(messages.attendees.name),
        sort: true
      },
      {
        dataField: "email",
        text: intl.formatMessage(messages.attendees.email),
        sort: true
      },
      {
        dataField: "age",
        text: intl.formatMessage(messages.attendees.age),
        sort: true
      },
      /*
      {
        dataField: "specialDiets",
        text: intl.formatMessage(messages.attendees.specialDiets.title),
        sort: true
      },
      */
      {
        dataField: "button",
        text: "",
        classes: "hidden-column",
        headerStyle: { backgroundColor: "#e2f0f1", borderWidth: 0 }
      }
    ];
    return columns;
  }

  toggleAddVolunteersModal() {
    this.setState({ addVolunteersPopup: !this.state.addVolunteersPopup });
  }

  getSpecialDiets(group, intl) {
    if (group && group.specialDiets && group.specialDiets.length > 0) {
      return group.specialDiets.map((specialDiet, index) => {
        return index === 0
          ? intl.formatMessage({ id: `food.${specialDiet.toLowerCase()}` })
          : ", " +
              intl.formatMessage({
                id: `food.${specialDiet.toLowerCase()}`
              });
      });
    }
  }

  deleteAttendees({ id }) {
    const { updateOfferAttendees, fetchGroupOffers, offer } = this.props;
    /*const newAttendees = _.remove(offer.attendees, attendee => {
      return attendee % id === 0;
    });*/
    updateOfferAttendees({
      eventId: offer.eventId,
      groupId: offer.groupId,
      stationId: offer.stationId,
      attendees: offer.attendees
    });

    fetchGroupOffers({
	    eventId: offer.eventId,
	    groupId: offer.groupId
    })
  }

  render() {
    const { offer, offers, group, intl } = this.props;
    const currentAttendees = (offer &&
    offer.attendees &&
    offer.attendees.length > 0 &&
    group &&
    group.volunteers.length > 0
      ? offer.attendees.map(attendee => {
          let existingGroup = group.volunteers.find(
            volunteer => volunteer.id === attendee
          );
          existingGroup = existingGroup ? existingGroup : {};
          return {
            id: existingGroup.id,
            name: existingGroup.name,
            email: existingGroup.email,
            age: existingGroup.age,
            //specialDiets: this.getSpecialDiets(existingGroup, intl),
            button: (
              <DeleteButton
                onClick={() => this.deleteAttendees({ id: existingGroup.id })}
              />
            ) // TODO
          };
        })
      : []
    ).filter(exist => exist);

    return (
      <Fragment>
        <H3>{intl.formatMessage(messages.attendees.title)}</H3> {/* //TODO */}
        <BootstrapTable
          keyField="id"
          data={currentAttendees}
          columns={this.getColumns(intl)}
          classes="table-test"
          wrapperClasses="responsive-table"
        />
        <BigGreenButton
          onClick={this.toggleAddVolunteersModal}
          label={intl.formatMessage(messages.attendees.addButton)} // Anmäl deltagare
        />
            <GroupAttendeesForm
        offer={offer}
	offers={offers}
        group={group}
        update={this.props.updateOfferAttendees}
	updateOffers={this.props.fetchGroupOffers}
        saveGroup={this.props.submitGroup}
	showPopup={this.state.addVolunteersPopup}
	handleHide={this.toggleAddVolunteersModal}
            />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  initialValues: state.offers.groupOffer,
  groupOffer: state.offers.groupOffer,
  group: state.group.group,
  groupOfferError: state.offers.groupOfferError,
  groupOfferAcceptError: state.offers.groupOfferAcceptError,
  groupOfferRejectError: state.offers.groupOfferRejectError
});

const mapDispatchToProps = dispatch => ({
  updateOfferAttendees: data => dispatch(updateOfferAttendees(data)),
  submitGroup: data => dispatch(submitGroup(data)),
  fetchGroupOffers: data => dispatch(fetchGroupOffers(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupAttendees));
