import { Auth } from 'aws-amplify';

export const register = data => new Promise(((resolve, reject) => {
  setTimeout(() => {
    resolve({
      user: {
        name: 'Staffan',
      },
    });
  }, 1000);
}));

export const signUp = (data) => {
  const {
    email,
    firstName,
    lastName,
    countryCode,
    phone,
    password,
  } = data;

  const phoneWithCountryCode = countryCode + ((phone.substr(0,1) === '0') ? phone.substr(1) : phone);

  return Auth
    .signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLowerCase(),
        phone_number: phoneWithCountryCode,
        given_name: firstName,
        family_name: lastName,
      },
      custom: {
        language: 'sv', // this shall be retrieved from store
      },
    })
    .then((data) => {
      return data;
    });
};
