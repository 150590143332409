import React, { Component } from "react";
import {
  ContentWrapper,
  Container,
  ProgressBar,
} from "../../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import EventCopy from "../EventCopy";
import StationsList from "../Station/StationsList";
import messages from "../messages";
import { colors } from "../../../../constants/styling-constants";
import { FormattedMessage, injectIntl } from "react-intl";
import { eventStatus } from "../reducer";
import EventList from "../List";
import StaffPerStation from "../Station/StaffPerStation";
import { Button } from "../../../../components/Buttons/index";

const states = [
  {
    step: 0,
    name: <FormattedMessage {...messages.progressBar.chooseEvent} />,
  },
  {
    step: 1,
    name: <FormattedMessage {...messages.progressBar.adjustEvent} />,
  },
  {
    step: 2,
    name: <FormattedMessage {...messages.progressBar.newStations} />,
  },
  {
    step: 3,
    name: <FormattedMessage {...messages.progressBar.staffStation} />,
  },
];

class EventCopyBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      submitClicked: false,
      nextStep: null,
      eventOffersToBeSubmitted: [],
    };

    this.goToStep = this.goToStep.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleEventPicked = this.handleEventPicked.bind(this);
    this.saveEvent = this.saveEvent.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.status === eventStatus.SUCCESS &&
      this.props.status === eventStatus.SUBMITTING &&
      this.state.submitClicked
    )
      this.props.history.push("/my-pages/events");
  }

  componentWillUnmount() {
    this.props.reset();
  }

  goToStep(step) {
    if (step === 0) {
      this.props.reset();
    }
    if (step >= 0 && step <= states.length) {
      this.setState({
        currentStep: step,
      });
      if (states[step].link) {
        this.props.history.push(states[step].link);
      }
    }
  }

  handleNextClick(newEvent, step) {
    this.setState({
      event: newEvent,
      nextStep: step,
    });
    this.goToStep(step);
  }

  handleEventPicked(row) {
    this.props.fetchEventOffers({ eventId: row.id });
    this.props.fetchEvent({ id: row.id });
    this.goToStep(1);
  }

  saveEvent() {
    if (this.state.submitClicked) {
      return;
    }
    this.setState({
      submitClicked: true,
    });

    this.props.submitEvent({
      ...this.state.event,
      eventOffers: this.state.eventOffersToBeSubmitted,
    });
  }

  renderPage() {
    switch (this.state.currentStep) {
      case 0:
        return (
          <EventList
            title={messages.copyEvent.listTitle}
            buttonTitle={messages.copyEvent.choose}
            handleClick={this.handleEventPicked}
          />
        );
      case 1:
        return (
          (this.props.event && (
            <EventCopy
              {...this.props}
              newEvent={this.state.event}
              onClickNext={(event) => this.handleNextClick(event, 2)}
            />
          )) || <i className="fas fa-spinner fa-pulse" />
        );
      case 2:
        return (
          <Row>
            <StationsList
              {...this.props}
              hideStatus={true}
              title={messages.copyEvent.stationsListTitle}
              stationsList={
                this.state.event && this.state.event.stations
                  ? this.state.event.stations
                  : []
              }
            />
            <Button
              type="button"
              busy={this.state.submitClicked}
              onClick={() => this.handleNextClick(this.state.event, 3)}
              label={this.props.intl.formatMessage(
                messages.copyEvent.nextButton
              )}
            />
          </Row>
        );
      case 3:
        return (
          <Row>
            <StaffPerStation
              {...this.props}
              setEventOffersToBeSubmitted={(newOffers) => {
                this.setState({ eventOffersToBeSubmitted: newOffers });
              }}
            />
            <Button
              type="button"
              busy={this.state.submitClicked}
              onClick={() => this.saveEvent()}
              label={this.props.intl.formatMessage(
                messages.copyEvent.saveButton
              )}
            />
          </Row>
        );
      default:
        return <EventCopy {...this.props} onClickNext={this.goToStep} />;
    }
  }
  render() {
    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col sm={9}>
              <ProgressBar
                states={states}
                currentStep={this.state.currentStep}
                goToStep={this.goToStep}
                bottomBarBg={colors.mediumPink}
                topBarBg={colors.pink}
                stepNotActiveBorder={colors.mediumPink}
                stepNotActiveColor={colors.mediumPink}
                wrapperBorder={colors.pink}
                stepColor={colors.pink}
                wrapperBg={colors.lightPink}
              />
            </Col>
          </Row>
          {this.renderPage()}
        </Container>
      </ContentWrapper>
    );
  }
}

export default injectIntl(EventCopyBase);
