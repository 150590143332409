import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getEventsSummaries, removeEvent } from "../reducer";
import EventList from "./EventList";

const mapStateToProps = state => ({
  eventsSummaries: state.event.eventsSummaries,
});

const mapDispatchToProps = (dispatch) => ({
  getEventsSummaries: data => dispatch(getEventsSummaries(data)),
  removeEvent: data => dispatch(removeEvent(data)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(EventList));
