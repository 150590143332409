import React, { Component } from "react";
import { ContentWrapper, Container, H1, P } from "../../../components";
import { CircleLinkButton } from "../../../components/Links";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import { breakPoints } from "../../../constants/styling-constants";
import MyGroupsList from "./MyGroupsList";
import TopNavigation from "../TopNavigation";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { GroupOffers } from "../Offer";

const IconWrapper = styled(Col)`
  display: flex;
  @media (max-width: ${breakPoints.xs}px) {
    flex-direction: column;
    align-items: center;
    a {
      margin-bottom: 25px;
    }
  }
`;

class VolunteerContextStart extends Component {
  render() {
    return (
      <ContentWrapper>
        <TopNavigation />
        <Container>
          <Row>
            <Col sm={9}>
              <H1><FormattedMessage {...messages.title} /></H1>
              <P><FormattedMessage {...messages.text} /></P>
            </Col>
          </Row>
          <Row>
            <Col
              sm={9}
              style={{ marginTop: 50 }}
            >
              <GroupOffers />
              <MyGroupsList />
            </Col>
          </Row>
          <Row>
            <IconWrapper sm={12}>
              <CircleLinkButton
                to="/my-pages/volunteers/new"
                label={this.props.intl.formatMessage(messages.newGroupLink)}
                className="green"
              />
            </IconWrapper>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }
}

export default injectIntl(VolunteerContextStart);
