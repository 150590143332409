import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import _ from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import { BigGreenButton } from "../../../../components/Buttons";
import VolunteerForm from "../Volunteers/VolunteerForm";
import { GeneralError, PopUpModal } from "../../../../components";

class GroupAttendeesForm extends Component {
    constructor(props) {
	super(props);
	this.state = {
	    selected: [],
	    createNewVoluntee: false
	};
	this.selectRow = this.selectRow.bind(this);
	this.selectAll = this.selectAll.bind(this);
	this.goBackToList = this.goBackToList.bind(this);
	this.createNewVoluntee = this.createNewVoluntee.bind(this);
	this.submitNewAttendees = this.submitNewAttendees.bind(this);
    }

    submitNewAttendees() {
	this.setState({ error: false });
	const { offer, update, updateOffers} = this.props;
	const { attendees = [] } = offer;
	const newAttendees = this.state["selected"];
	if (!newAttendees.length > 0) {
	    this.setState({ error: true });
	    return;
	}
	attendees.push(...newAttendees);
	update({
	    eventId: offer.eventId,
	    groupId: offer.groupId,
	    stationId: offer.stationId,
	    attendees: attendees
	});
	updateOffers({
	    eventId: offer.eventId,
	    groupId: offer.groupId,
	});
	this.setState({ selected: [] });
	this.props.handleHide()
    }

    selectRow(row) {
	var selected = this.state["selected"];
	if (
	    !selected.find(function(el) {
		return el === row.id;
	    })
	) {
	    selected.push(row.id);
	} else {
	    _.remove(selected, function(el) {
		return el === row.id;
	    });
	}
	this.setState(selected);
    }

    selectAll(isSelect, rows) {
	var selected = [];
	if (isSelect) {
	    rows.forEach(row => selected.push(row.id));
	}
	this.setState({selected: selected})
    }

    getColumns(intl) {
	const columns = [
	    { dataField: "id", text: "id", hidden: true },
	    {
		dataField: "name",
		text: intl.formatMessage(messages.attendees.name),
		sort: true
	    },
	    {
		dataField: "email",
		text: intl.formatMessage(messages.attendees.email),
		sort: true
	    },
	    {
		dataField: "age",
		text: intl.formatMessage(messages.attendees.age),
		sort: true
	    }
	    /*
	      {
              dataField: "specialDiets",
              text: intl.formatMessage(messages.attendees.specialDiets.title),
              sort: true
	      }
	    */
	];
	return columns;
    }

    goBackToList() {
	this.setState({ createNewVoluntee: false });
    }

    getSpecialDiets(group, intl) {
	if (group && group.specialDiets && group.specialDiets.length > 0) {
	    return group.specialDiets.map((specialDiet, index) => {
		return index === 0
		    ? intl.formatMessage({ id: `food.${specialDiet.toLowerCase()}` })
		: ", " +
		    intl.formatMessage({ id: `food.${specialDiet.toLowerCase()}` });
	    });
	}
    }

    createNewVoluntee() {
	this.setState({ createNewVoluntee: true });
    }

    render() {
	const { intl, group, offer, offers } = this.props;
	/*const nonNullOfferAttendees =
	      offer && offer.attendees && offer.attendees.length > 0
              ? offer.attendees
              : [];
	const availableAttendee =
	      group && group.volunteers && group.volunteers.length > 0
              ? group.volunteers.filter(
		  volunteer =>
		      !nonNullOfferAttendees.find(attendee => attendee === volunteer.id)
              )
              : [];*/
	const noOtherStations =
	      offers && group && group.volunteers && group.volunteers.length > 0
	      ? group.volunteers.filter(
	    volunteer => 
		!offers.reduce((acc, station) => acc |= (station.attendees && station.attendees.length > 0 && station.attendees.find(attendee => attendee === volunteer.id)), false)
	    )
	      : [];
	const formattedAttendee = noOtherStations.map(attendee => {
	    const { specialDiets, ...rest } = attendee;
	    const newDiets = this.getSpecialDiets(attendee, intl);
	    return { specialDiets: newDiets, ...rest };
	});
	return (
		<PopUpModal
            title={this.state.createNewVoluntee ? intl.formatMessage(messages.modal.newVolunteersCreate) : intl.formatMessage(messages.modal.newVolunteers)}
            id="addVolunteerPopup"
            bgColor="#e2f0f1"
	    show={this.props.showPopup}
	    handleHide={this.props.handleHide}
		>
		<Fragment> 
		{this.state.createNewVoluntee && (
			<VolunteerForm
		    group={group}
		    offer={offer}
		    goBackToList={this.goBackToList}
		    saveGroup={volunteersList => {
			const groupToSubmit = this.props.group;
			groupToSubmit.volunteers = volunteersList;
			this.props.saveGroup(groupToSubmit);
		    }}
			/>
		)}
            {!this.state.createNewVoluntee && (
		    <Fragment>
		    <BootstrapTable
		selectRow={{ mode: "checkbox", onSelect: this.selectRow, onSelectAll: this.selectAll }}
		keyField="id"
		columns={this.getColumns(intl)}
		data={formattedAttendee}
		classes="table-test"
		wrapperClasses="responsive-table"
		    />
		    {this.state.error && (
			    <GeneralError>
			    {intl.formatMessage(messages.attendees.notEnoughAttendee)}
			</GeneralError>
		    )}
		    <div style={{ display: "flex", justifyContent: "center" }}>
		    <BigGreenButton
                label={intl.formatMessage(messages.attendees.submitButton)}
                onClick={this.submitNewAttendees}
		    />
		    <BigGreenButton
                label={intl.formatMessage(messages.attendees.createButton)}
                onClick={this.createNewVoluntee}
		    />
		    </div>
		    </Fragment>
            )}
	    </Fragment>
		</PopUpModal>
	);
    }
}

export default injectIntl(GroupAttendeesForm);
