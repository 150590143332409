import React, { Component, Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import messages from "./messages";
import { Row, Col } from "react-bootstrap/lib";
import { DeleteButton } from "../../../../components/Buttons/index";
import { groupStatus } from "../reducer";
import styled from "styled-components";
import { BorderTable, ConfirmationModal } from "../../../../components/index";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const Table = styled(BorderTable)`
  display: inline-block;
  float: left;
`;

const DeleteRowButton = ({ row, callback }) => {
  const onClick = () => {
    callback(row);
  };

  return <DeleteButton onClick={onClick} />;
};

class VolunteerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      deleting: false,
      volunteerToDelete: null,
      volunteers: this.props.group ? this.props.group.volunteers : null
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.renderVolunteersTableRow = this.renderVolunteersTableRow.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === groupStatus.SUCCESS && this.state.deleting) {
      this.setState({
        deleting: false,
        showModal: false,
        volunteers: this.props.group.volunteers
      });
    }

    if (
      nextProps.status === groupStatus.SUCCESS &&
      this.props.submittingVolunteerForm
    ) {
      this.setState({
        volunteers: this.props.group.volunteers
      });
    }
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  onDelete(row) {
    this.setState({
      showModal: true,
      volunteerToDelete: row
    });
  }

  onConfirmDelete() {
    this.setState({
      deleting: true
    });

    const updatedList = this.state.volunteers.slice();
    const indexToRemove = updatedList.indexOf(this.state.volunteerToDelete);
    updatedList.splice(indexToRemove, 1);
    this.props.saveGroup(updatedList);
  }

  renderVolunteersTableRow(row, key) {
    // TODO - find better solution for row click event - delete column needs to be excluded from row click event
    return (
      <tr key={key}>
        <td onClick={() => this.props.onVolunteerRowClick(key, row)}>
          {row.name}
        </td>
        <td onClick={() => this.props.onVolunteerRowClick(key, row)}>
          {row.email.toLowerCase()}
        </td>
        <td onClick={() => this.props.onVolunteerRowClick(key, row)}>
          {row.phone}
        </td>
        <td onClick={() => this.props.onVolunteerRowClick(key, row)}>
          {row.age}
        </td>
        {/*<td onClick={() => this.props.onVolunteerRowClick(key, row)}>{row.specialDiets ? row.specialDiets.map(diet => this.props.intl.formatMessage({ id: `food.${diet.toLowerCase()}` })).join(", ") : null}</td>
        <td onClick={() => this.props.onVolunteerRowClick(key, row)}>{row.shirtSize ? this.props.intl.formatMessage({ id: `shirt-size.${row.shirtSize.toLowerCase()}` }) : null}</td>
        */}
        <td className="no-background">
          <DeleteRowButton row={row} callback={this.onDelete} />
        </td>
      </tr>
    );
  }

  renderVolunteers() {
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...messages.volunteers.list.name} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.volunteers.list.email} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.volunteers.list.phone} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.volunteers.list.age} />
                  </th>
                  {/*
                  <th>
                    <FormattedMessage
                      {...messages.volunteers.list.specialDiets}
                    />
                  </th>
                  <th>
                    <FormattedMessage {...messages.volunteers.list.shirtSize} />
                  </th>
                  */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.state.volunteers.map(this.renderVolunteersTableRow)}
              </tbody>
            </Table>
          </Col>
        </Row>
        <ConfirmationModal
          title={this.props.intl.formatMessage(
            messages.volunteers.deleteVolunteerTitle
          )}
          label={this.props.intl.formatMessage(
            messages.volunteers.cancelDeleteButton
          )}
          show={this.state.showModal}
          handleConfirm={this.onConfirmDelete}
          handleHide={this.toggleModal}
          text={this.props.intl.formatMessage(
            messages.volunteers.deleteVolunteerMessage,
            {
              name: this.state.volunteerToDelete
                ? this.state.volunteerToDelete.name
                : ""
            }
          )}
          busy={this.state.deleting}
          theme="green"
        />
      </Wrapper>
    );
  }

  render() {
    return (
      <Fragment>
        {this.state.volunteers && this.state.volunteers.length > 0 ? (
          this.renderVolunteers()
        ) : (
          <FormattedMessage {...messages.volunteers.noVolunteersMessage} />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(VolunteerList);
