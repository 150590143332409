import { defineMessages } from 'react-intl';

export default defineMessages({
  headerTitle: {
    id: 'about.header-title',
  },
  pageTitle: {
    id: 'about.page-title',
  },
  section1: {
    id: 'about.section1.text',
  },
  section2: {
    id: 'about.section2.text',
  },
  section3: {
    id: 'about.section3.text',
  },
  section4: {
    id: 'about.section4.text',
  },
  section5: {
    id: 'about.section5.text',
  },
  section6: {
    id: 'about.section6.text',
  },
  section7: {
    id: 'about.section7.text',
  },
  section8: {
    id: 'about.section8.text',
  },
  section9: {
    id: 'about.section9.text',
  },
});
