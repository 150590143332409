import React from 'react';
import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const StyledError = styled.p`
  margin-bottom: 10px;
  color: ${colors.pink};
  text-align: right;
  font-style: italic;
  font-size: 14px;
`;

const Error = ({error, className = ''}) => (error ? <StyledError className={className}>{error}</StyledError> : null);

const SmallError = styled(Error)`
  margin-bottom: 6px;
  font-size: 10px;
`;

export default Error;

export {
  SmallError
};
