import React, { Component, Fragment } from "react";
import moment from "moment";
import { DeleteButton } from "../../../../components/Buttons/index";
import { eventStatus } from "../reducer";
import { Row, Col } from "react-bootstrap/lib";
import styled from "styled-components";
import { colors } from "../../../../constants/styling-constants";
import {
  H2,
  BorderTable,
  ConfirmationModal
} from "../../../../components/index";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import { municipalities } from "../../../../constants/municipalities";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

const Status = styled.div`
  padding: 8px;
  background-color: ${colors.gray};
  width: 100%;
  color: #FFF;
  text-align: center;
`;

const StatusUnmanned = Status.extend`
  background-color: ${colors.pink};
`;

const DeleteListItemButton = ({ row, callback }) => {
  const onClick = () => {
    callback(row);
  };

  return (
    <DeleteButton onClick={onClick} />
  );
};

const Table = styled(BorderTable)`
    display: inline-block;
    float: left;
`;

const TableRowWithClick = styled("tr")`
  cursor: pointer;
`;

class StationsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      stationToDelete: null,
      deleting: false,
    };

    this.renderRow = this.renderRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.renderStationList = this.renderStationList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === eventStatus.SUCCESS && this.props.status === eventStatus.SUBMITTING && this.state.deleting) {
      this.setState({
        showModal: false,
        stationToDelete: null,
        deleting: false,
      });
    }
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  onDelete(row) {
    this.setState({
      showModal: true,
      stationToDelete: row,
    });
  }

  onConfirmDelete() {
    this.setState({
      deleting: true,
    });

    const updatedList = this.props.event.stations.slice();
    const indexToRemove = updatedList.indexOf(this.state.stationToDelete);
    updatedList.splice(indexToRemove, 1);
    this.props.onDeleteStation(updatedList);
  }

  /**
   * Calculate if the station is manned by checking if number of accepted proposals are higher than officialQuantity
   *
   * This should probably be handled by backend instead.
   */
  renderStatus(station) {
    const offers = this.props.eventOffers.hasOwnProperty(this.props.event.eventId) ? this.props.eventOffers[this.props.event.eventId] : null;
    let manned = 0;

    if (offers) {
      for (let o of offers) {
        if (o.stationId === station.id && o.status === "ACCEPTED") {
          manned = manned + parseInt(o.volunteers, 10);
        }
      }
    }

    if (manned >= parseInt(station.officialQuantity, 10)) {
      return <Status><FormattedMessage {...messages.status.manned} /></Status>;
    }

    return <StatusUnmanned><FormattedMessage {...messages.status.unmanned} /></StatusUnmanned>;
  }

  renderRow(row, key) {
    return (
      <TableRowWithClick
        key={key}
        onClick={() => this.props.onStationClick(key, row)}
      >
        <td>{row.name}</td>
        <td>{moment(row.start).format("HH:mm")}</td>
        <td>{moment(row.end).format("HH:mm")}</td>
        <td>{(municipalities.find(municipality => municipality.id === row.municipality) || {}).name}</td>
        <td>{row.officialQuantity}</td>
        <td>{row.officialMiniumumAge}</td>
        <td>{this.props.intl.formatMessage({ id: `station.payment-type.${row.paymentType}` })}</td>
        <td>{row.currency}</td>
        <td>{row.groupPayment}</td>
        <td>{row.paymentAdult}</td>
        <td>{row.paymentChild}</td>
        <td>{row.skills ? row.skills.map(skill => this.props.intl.formatMessage({ id: `lists.skills.${skill}` })).join(", ") : null}</td>
        <td>{this.renderStatus(row)}</td>
        <td className="no-background"><DeleteListItemButton
          row={row}
          callback={this.onDelete}
        /></td>
      </TableRowWithClick>

    );
  }

  renderStationList() {
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <H2><FormattedMessage {...messages.stationEdit.title} /></H2>
            {this.props.event.stations ?
              <Table>
                <thead>
                  <tr>
                    <th><FormattedMessage {...messages.myStation.list.id} /></th>
                    <th><FormattedMessage {...messages.myStation.list.startTime} /></th>
                    <th><FormattedMessage {...messages.myStation.list.endTime} /></th>
                    <th><FormattedMessage {...messages.myStation.list.municipality} /></th>
                    <th><FormattedMessage {...messages.myStation.list.amount} /></th>
                    <th><FormattedMessage {...messages.myStation.list.minimumAge} /></th>
                    <th><FormattedMessage {...messages.myStation.list.type} /></th>
                    <th><FormattedMessage {...messages.myStation.list.currency} /></th>
                    <th><FormattedMessage {...messages.myStation.list.groupPayment} /></th>
                    <th><FormattedMessage {...messages.myStation.list.paymentAdult} /></th>
                    <th><FormattedMessage {...messages.myStation.list.paymentChild} /></th>
                    <th><FormattedMessage {...messages.myStation.list.skills} /></th>
                    <th><FormattedMessage {...messages.myStation.list.status} /></th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.props.event.stations.map(this.renderRow)}
                </tbody>
              </Table>
              :
              <p><FormattedMessage {...messages.stationEdit.noStation} /></p>
            }
          </Col>
        </Row>
        <ConfirmationModal
          label={this.props.intl.formatMessage(messages.cancelDeleteButton)}
          show={this.state.showModal}
          handleConfirm={this.onConfirmDelete}
          handleHide={this.toggleModal}
          title={this.props.intl.formatMessage(messages.deleteTitle)}
          text={
            <FormattedMessage {...messages.deleteMessage} values={{ name: this.state.stationToDelete ? this.state.stationToDelete.name : "" }} />}
          busy={this.state.deleting}
          theme="pink"
        />
      </Wrapper>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderStationList()}
      </Fragment>
    );
  }
}

export default injectIntl(StationsEdit);
