import React, { Component, Fragment } from "react";
import {
  ContentWrapper,
  Container,
  WhiteH1,
  H2,
  H3,
  P,
  TextSection,
  ImageBackground
} from "../../components/index";
import { GreenLinkButton } from "../../components/Links/index";
import { Row, Col } from "react-bootstrap/lib";
import img from "../../images/Volex_bild_undersida2.jpg";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";

const StyledList = styled.ul`
  list-style: circle;
  padding-left: 2em;
  line-height: 1.5;
`;

const Background = ImageBackground.extend`
  background-image: url(${img});
`;

const H3WithMarginBottom = styled(H3)`
  margin-bottom: 70px;
`;

class HowItWorksVolunteer extends Component {

  render() {
    return (
      <Fragment>
        <Background>
          <Container>
            <WhiteH1><FormattedMessage {...messages.title} /></WhiteH1>
          </Container>
        </Background>
        <ContentWrapper>
          <Container>
            <Row>
              <Col sm={9}>
                <H3WithMarginBottom><FormattedMessage {...messages.pageIntro} /></H3WithMarginBottom>
                <HowItWorksGenerator sections={[...generateSections(this.props.intl.formatMessage)]} />
                {
                  <GreenLinkButton to={this.props.auth && this.props.auth.status !== "LOGGED_IN" ? "/register" : "/my-pages/volunteers"}><FormattedMessage {...messages.registerButton} /></GreenLinkButton>}
              </Col>
            </Row>
          </Container>
        </ContentWrapper>
      </Fragment>
    )
  }
}

function* generateSections(formatMessage) {
  for (let section = 1; true; section++) {
    const sectionTitleKey = `how-it-works-volunteer.section-${section}.title-1`;
    const title = formatMessage({ id: sectionTitleKey });
    if (sectionTitleKey === title) {
      return;
    }
    yield {
      id: section,
      titles: [...generateTitles(section, formatMessage)],
      listItems: [...generateListItems(section, formatMessage)],
      paragraphs: [...generateParagraphs(section, formatMessage)],
    };
  }
}

function* generateParagraphs(section, formatMessage) {
  for (let i = 1; true; i++) {
    const listElementCode = `how-it-works-volunteer.section-${section}.paragraph-${i}`;
    const paragraph = formatMessage({ id: listElementCode });
    if (listElementCode === paragraph) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: paragraph
    };
  }
}

function* generateTitles(section, formatMessage) {
  for (let i = 1; true; i++) {
    const listElementCode = `how-it-works-volunteer.section-${section}.title-${i}`;
    const title = formatMessage({ id: listElementCode });
    if (listElementCode === title) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: title
    };
  }
}

function* generateListItems(section, formatMessage) {
  for (let i = 1; true; i++) {
    const listItemCode = `how-it-works-volunteer.section-${section}.list-element-${i}`;
    const item = formatMessage({ id: listItemCode });
    if (listItemCode === item) {
      return;
    }
    yield {
      id: `${section}-${i}`,
      label: item
    };
  }
}

const Paragraph = ({ paragraph }) => (
  <P>
    {paragraph.label}
  </P>
);

const Title = ({ title }) => (
  <H2 id={title.label.toLowerCase()}>
    {title.label}
  </H2>
);

const ListItem = ({ item }) => (
  <li>
    {item.label}
  </li>
);

const ListItems = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <StyledList>
      {items.map(item => <ListItem
        key={item.id}
        item={item}
      />)}
    </StyledList>
  );
};

const Section = ({ section }) => (
  <TextSection>
    {section.titles.map(paragraph => (<Title
      key={paragraph.id}
      title={paragraph}
    />))}
    {section.paragraphs.map(paragraph => (<Paragraph
      key={paragraph.id}
      paragraph={paragraph}
    />))}
    <ListItems items={section.listItems} />
  </TextSection>
);

const HowItWorksGenerator = ({ sections }) => (
  <Fragment>
    {sections.map(section => (<Section
      key={section.id}
      section={section}
    />))}
  </Fragment>
);

export default injectIntl(HowItWorksVolunteer);
