import styled from 'styled-components';
import { colors } from '../../constants/styling-constants';

const P = styled.p`
  color: ${colors.text};
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 20px;
`;

const ItalicP = P.extend`
  font-style: italic;
`;

const WhiteP = P.extend`
  color: #FFF;
`;

const BoldP = P.extend`
  font-weight: bold;
`;

export {
  WhiteP,
  ItalicP,
  BoldP,
}


export default P;
