import { connect } from "react-redux";
import { fetchEvents } from "./reducer";
import React, { Component, Fragment } from "react";
import Hero from "./Hero";
import InfoSection from "./InfoSection";
import { Auth } from "aws-amplify";
import { status } from "../../auth/reducer";

class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
	name: "",
	b: true
    }
      this.buttonpress = this.buttonpress.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.status === status.LOGGED_IN || this.props.auth.status === status.PENDING) {
      Auth.currentSession()
        .then(async (session) => {
          let info = await Auth.currentUserInfo();
          this.setState({
            loggedUser: session.idToken.jwtToken,
            name: info.attributes.given_name,
          });
        });
    }
  }

  buttonpress() {
	  this.setState({b: !this.state.b})
  }

  render() {
    const { loggedUser, name } = this.state;
    return (
      <Fragment>
        <Hero
          loggedUser={loggedUser}
          name={name}
        />
        {loggedUser ? null : <InfoSection />}

	</Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchEvents: data => dispatch(fetchEvents(data)),
});

const mapStateToProps = (state) => ({
  auth: state.auth,
  events: state.start.events,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Start);

