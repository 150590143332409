import { defineMessages } from 'react-intl';

export default defineMessages({
  link: {
    about: {
      id: 'footer.about-link',
    },
    faq: {
      id: 'footer.faq-link',
    },
    terms: {
      id: 'footer.terms-link',
    },
    personalData: {
      id: 'footer.personal-data-link',
    },
    contact: {
      id: 'footer.contact-link',
    },
  },
  copyRight: {
    id: 'footer.copy-right-text',
  },
});
