import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'register-station.page.title',
  },
  text: {
    id: 'register-station.text',
  },
  station: {
    title: {
      id: 'register-station.form.title',
    },
    signedUp: {
      id: "group.volunteer.table.signedUp.title"
    },
    present: {
      id: "group.volunteer.table.present.title"
    },
    form: {
      title: {
        id: 'register-station.station.form.title',
      },
      id: {
        id: 'register-station.form.station.id.placeholder',
      },
      description: {
        id: 'register-station.form.station.description.placeholder',
      },
      noMarker: {
        id: 'register-station.form.station.no-marker',
      },
      markerTitle: {
        id: 'register-station.form.station.marker-title'
      },
      mapTitle: {
        id: 'register-station.form.station.map-title',
      },
      municipality: {
        id: 'register-station.form.station.municipality.placeholder',
      },
      startTime: {
        id: 'register-station.form.station.start-time.placeholder',
      },
      endTime: {
        id: 'register-station.form.station.end-time.placeholder',
      },
    },
    noStationsError: {
      id: 'register-station.form.no-stations-message',
    },
    workStation: {
      id: `station-allocation.table.workStation`
    },
    officialQuantity: {
      id: "station-allocation.table.officialQuantity",
    },
  },
  group: {
    title: {
      id: "station-allocation.table.header.groupName",
    },
  },
  stationEdit: {
    title: {
      id: 'edit-station.title',
    },
    form: {
      id: {
        id: 'edit-station.form.station.id.placeholder',
      },
      description: {
        id: 'edit-station.form.station.description.placeholder',
      },
      startTime: {
        id: 'edit-station.form.station.start-time.placeholder',
      },
      endTime: {
        id: 'edit-station.form.station.end-time.placeholder',
      },
    },
    editStationButton: {
      id: 'edit-station-button',
    },
    cancelEditButton: {
      id: 'cancel-edit-station-button',
    },
    addNewStationButton: {
      id: 'edit-station.add-new-station-button',
    },
    noStation: {
      id: 'edit-station.list.no-stations-message',
    },
    formTitle: {
      id: 'edit-station.form-title',
    },
  },
  teamLeader: {
    form: {
      title: {
        id: 'register-station.team-leader.form.title',
      },
      name: {
        id: 'register-station.form.team-leader.name.placeholder',
      },
      email: {
        id: 'register-station.form.team-leader.email.placeholder',
      },
      phone: {
        id: 'register-station.form.team-leader.phone.placeholder',
      },
    },
  },
  volunteer: {
    form: {
      title: {
        id: 'register-station.volunteer.form.title',
      },
      officialQuantity: {
        id: 'register-station.form.volunteer.quantity.placeholder',
      },
      officialMinimumAge: {
        id: 'register-station.form.volunteer.minimum-age.placeholder',
      },
      skills: {
        id: 'register-station.form.volunteer.skills.placeholder',
      },
      officialGroup: {
        id: 'register-station.form.volunteer.phone.placeholder',
      },
    },
  },
  payment: {
    form: {
      title: {
        id: 'register-form.payment.form.title',
      },
      paymentType: {
        id: 'register-station.form.payment.payment-type.placeholder',
      },
      currency: {
        id: 'register-station.form.payment.currency.placeholder',
      },
      groupPayment: {
        id: 'register-station.form.payment.groupPayment.placeholder',
      },
      paymentAdult: {
        id: 'register-station.form.payment.payment-adult.placeholder',
      },
      paymentChild: {
        id: 'register-station.form.payment.payment-child.placeholder',
      },
    },
  },
  addStationButton: {
    id: 'register-station.add-station-button',
  },
  myStation: {
    title: {
      id: 'register-station.my-station.table.title',
    },
    list: {
      id: {
        id: 'register-station.table.my-station.id.placeholder',
      },
      description: {
        id: 'register-station.table.my-station.description.placeholder',
      },
      startTime: {
        id: 'register-station.table.my-station.start-time.placeholder',
      },
      endTime: {
        id: 'register-station.table.my-station.end-time.placeholder',
      },
      municipality: {
        id: 'register-station.table.my-station.municipality.placeholder',
      },
      amount: {
        id: 'register-station.table.my-station.amount.placeholder',
      },
      minimumAge: {
        id: 'register-station.table.my-station.minimum-age.placeholder',
      },
      type: {
        id: 'register-station.table.my-station.type.placeholder',
      },
      currency: {
        id: 'register-station.table.my-station.currency.placeholder',
      },
      paymentGroup: {
        id: 'register-station.table.my-station.payment-group.placeholder',
      },
      paymentAdult: {
        id: 'register-station.table.my-station.payment-adult.placeholder',
      },
      paymentChild: {
        id: 'register-station.table.my-station.payment-child.placeholder',
      },
      skills: {
        id: 'register-station.table.my-station.skills.placeholder',
      },
      officialGroup: {
        id: 'register-station.table.my-station.official-group.placeholder',
      },
      status: {
        id: 'register-station.table.my-station.status.placeholder',
      },
      groupPayment: {
        id: 'register-station.table.my-station.group-payment.placeholder',
      },
    },
  },
  deleteTitle: {
    id: 'register-event.my-station.delete.title',
  },
  deleteMessage: {
    id: 'register-event.my-station.delete.text',
  },
  nextButton: {
    id: 'register-event.my-station.next-button',
  },
  cancelDeleteButton: {
    id: 'message.cancel-delete-button',
  },
  noStationsMessage: {
    id: 'register-event.no-stations-yet-message',
  },
  stationNameAlreadyExistError: {
    id: 'station.station-name-already-exist-error',
  },
  status: {
    manned: {
      id: 'register-event.my-event.status.manned',
    },
    unmanned: {
      id: 'register-event.my-event.status.unmanned',
    },
  },
  paymentTypeList: {
    groupPayment: { 
      value: {
        id: 'station.payment-type.GROUPPAYMENT',
      },
    },
    perPerson: {
      value: {
        id: 'station.payment-type.PERPERSON',
      },
    },
    hourlyRate: {
      value: {
        id: 'station.payment-type.HOURLYRATE',
      },
    },
    compensationByAgreement: {
      value: {
        id: 'station.payment-type.COMPENSATIONBYAGREEMENT',
      },
    },
  },
  staffPerStation: {
    title: {
      id: "progress-bar.staff-station"
    },
    info: {
      id: "progress-bar.staff-station.info"
    }
  },
});
