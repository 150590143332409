import React, { Component, Fragment } from "react";
import styled from "styled-components";
import img from "../../images/hero.jpg";
import { Grid } from "react-bootstrap/lib";
import { BigHashLinkButton, BigHashGreenLinkButton } from "../../components/Links";
import { breakPoints } from "../../constants/styling-constants";
import messages from "./messages";
import { FormattedMessage } from "react-intl";
import { ImageBackground } from "../../components";
import PropTypes from "prop-types";

const maxWidth = "900px";

const Background = styled.section`
  height: 900px;
  background-image: url(${img});
  background-size: cover;
    @media (max-width: ${breakPoints.sm}px) {
    height: 700px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    font-size: 40px;
    height: 484px;
  }
`;

const BackgroundLogged = ImageBackground.extend`
  background-image: url(${img});
`;

const Wrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`;

const Slogan = styled.h1`
  font-family: 'Pacifico', cursive;
  color: #FFF;
  font-size: 90px;
  text-align: center;
  max-width: ${maxWidth};
  margin: 0 auto;
  position: relative;
  @media (max-width: ${breakPoints.sm}px) {
    font-size: 70px;
  }
    @media (max-width: ${breakPoints.xs}px) {
    font-size: 45px;
  }
`;

const SloganLogged = styled.h1`
  font-family: 'Pacifico', cursive;
  color: #FFF;
  font-size: 50px;
  line-height: 80px;
  text-align: center;
  max-width: ${maxWidth};
  margin: 0 auto;
  position: relative;
  @media (max-width: ${breakPoints.sm}px) {
    font-size: 70px;
  }
    @media (max-width: ${breakPoints.xs}px) {
    font-size: 45px;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  max-width: ${maxWidth};
  width: 100%;
  max-width: 630px;
  bottom: 115px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
   @media (max-width: ${breakPoints.sm}px) {
    max-width: 460px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    bottom: 30px;
    max-width: 400px;
  }
`;

const ButtonWrapperLogged = styled.div`
  position: absolute;
  max-width: ${maxWidth};
  width: 100%;
  max-width: 630px;
  bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
   @media (max-width: ${breakPoints.sm}px) {
    max-width: 460px;
  }
  @media (max-width: ${breakPoints.xs}px) {
    bottom: 30px;
    max-width: 400px;
  }
`;

class Hero extends Component {
  render() {
    const { name, loggedUser } = this.props;
    return (
      <Fragment>
        {loggedUser ?
          <BackgroundLogged>
            <Wrapper>
              <SloganLogged><FormattedMessage {...messages.sloganLoggedIn} />, {name}!</SloganLogged>
              <ButtonWrapperLogged>
                <BigHashLinkButton to="/my-pages/events"><FormattedMessage {...messages.organizerButton} /></BigHashLinkButton>
                <BigHashGreenLinkButton to="/my-pages/volunteers"><FormattedMessage {...messages.volunteerButtonLoggedIn} />
                </BigHashGreenLinkButton>
              </ButtonWrapperLogged>
            </Wrapper>
          </BackgroundLogged> :
          <Background>
            <Wrapper>
              <Slogan><FormattedMessage {...messages.slogan} /></Slogan>
              <ButtonWrapper>
                <BigHashLinkButton to="/how-it-works-organizer"><FormattedMessage {...messages.organizerButton} /></BigHashLinkButton>
                <BigHashGreenLinkButton to="/how-it-works-volunteer">
                  <FormattedMessage {...messages.volunteerButton} />
                </BigHashGreenLinkButton>
              </ButtonWrapper>
            </Wrapper>
          </Background>}
      </Fragment>
    );
  }
}

Hero.propTypes = {
  name: PropTypes.string.isRequired,
  loggedUser: PropTypes.string,
};

export default Hero;
