import { connect } from 'react-redux';
import { submitGroup, reset, status, confirmGroup } from '../reducer';
import Volunteers from './Volunteers';

const mapStateToProps = (state) => {
  const obj = {
    group: state.group.group,
    status: state.group.status,
    user: state.auth.user,
  };
  if (state.group.status === status.FETCHED) {
    obj.initialValues = state.group.volunteers;
  }

  return obj;
};

const mapDispatchToProps = dispatch => ({
  submitGroup: data => dispatch(submitGroup(data)),
  resetGroup: () => dispatch(reset()),
  confirmGroup: data => dispatch(confirmGroup(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Volunteers);
