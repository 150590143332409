import "./index.css";
import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { reduxForm, Field } from "redux-form";
import messages from "./messages";
import styled from "styled-components";
import {
  SmallDatePicker,
  SmallSelect,
  FormGroup
} from "../../../../components/index";
import { BigGreenButton } from "../../../../components/Buttons";
import { municipalities } from "../../../../constants/municipalities";
import { required } from "../../../../validators";
import moment from "moment";

const TableDatePicker = styled(SmallDatePicker)``;
const StyledButton = styled(BigGreenButton)`
  margin: 0 0 0 0;
  background-color: #009aa2;
  background: linear-gradient(#00adba,#009aa2);
`;

class AddAvailabilityFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      event: null
    };
  }

  render() {
    const {
      intl,
      events,
      fetchEvent,
      completeEvents = [],
      handleSubmit
    } = this.props || {};
    const sortedEventList = events.sort((a, b) => a.name.localeCompare(b.name));
    let mun = municipalities;
    if (this.state.type && this.state.type === "EVENT" && this.state.event) {
      const event = completeEvents.find(e => e.eventId === this.state.event);
      if (!event) {
        fetchEvent(this.state.event);
      } else {
        mun = municipalities.filter(m => {
          return event.stations && event.stations.find(s => s.municipality === m.id);
        });
      }
    }
    const typeOptions = ["PERIOD", "EVENT"].map(k => ({
      value: k,
      text: intl.formatMessage({
        id: `group-availability.table.input.type.${k}`
      })
    }));
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Field
            name={`type`}
            placeholder={intl.formatMessage(messages.table.input.type)}
            data={typeOptions}
            valueField="value"
            textField="text"
            onChange={value => this.setState({ type: value })}
            component={SmallSelect}
            validate={[required]}
          />
          {this.state.type === "PERIOD" && (
            <Fragment>
              <Field
                name="startDate"
                placeholder={intl.formatMessage(messages.table.input.startDate)}
                component={TableDatePicker}
              />
              <Field
                name="endDate"
                placeholder={intl.formatMessage(messages.table.input.endDate)}
                component={TableDatePicker}
              />
            </Fragment>
          )}
          {this.state.type === "EVENT" && (
            <Field
              name={`eventId`}
              placeholder={intl.formatMessage(messages.table.input.event)}
              data={sortedEventList}
              onChange={value => this.setState({ event: value })}
              filter
              component={SmallSelect}
              valueField="eventId"
              textField="name"
              validdate={[required]}
            />
          )}
          {this.state.type && (
            <Field
              name={`location`}
              placeholder={intl.formatMessage(messages.table.input.location)}
              component={SmallSelect}
              data={mun}
              type="text"
              filter
              valueField="id"
              textField="name"
              validate={[required]}
            />
          )}
        </FormGroup>
        <StyledButton type="submit">{intl.formatMessage(messages.addForm.submit)}</StyledButton>
      </form>
    );
  }
}

const AddAvailabilityForm = reduxForm({
  form: "addInterestForm",
  initialValues: {
    startDate: moment(),
    endDate: moment().add(1, "days")
  }
})(
  injectIntl(AddAvailabilityFormComponent)
);

export default AddAvailabilityForm;
