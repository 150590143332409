import React, { Component } from "react";
import Header from "../Header";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../";
import Start from "../../containers/Start";
import HowItWorksVolunteer from "../../containers/HowItWorksVolunteer";
import HowItWorksOrganizer from "../../containers/HowItWorksOrganizer";
import FAQ from "../../containers/FAQ";
import About from "../../containers/About";
import PrivacyPolicy from "../../containers/PrivacyPolicy";
import Contact from "../../containers/Contact";
import Terms from "../../containers/Terms";
import MyPagesRouter from "../../containers/MyPages/MyPagesRouter";
import Footer from "../../components/Footer";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Cookies from "../../components/Cookies";

import { authStoredUser } from "../../auth/reducer";
import MyProfileStart from "../../containers/MyPages/MyProfile/MyProfileStart";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  &.mobile-menu-open {
    overflow: hidden;
  }
`;

// Inner Wrapper used for Sticky Footer (https://css-tricks.com/couple-takes-sticky-footer/#article-header-id-3)
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

class Main extends Component {
  componentDidMount() {
    this.props.authStoredUser();
  }

  render() {
    return (
      <Wrapper className={`wrapper ${this.props.showMobileMenu ? "mobile-menu-open" : ""}`}>
        <InnerWrapper>
          <Header />
          <Switch>
            <Route
              exact
              path="/"
              component={Start}
            />
            <Route
              path="/how-it-works-organizer"
              component={HowItWorksOrganizer}
            />
            <Route
              path="/how-it-works-volunteer"
              component={HowItWorksVolunteer}
            />
            <Route
              path="/faq"
              component={FAQ}
            />
            <Route
              path="/about"
              component={About}
            />
            <Route
              path="/terms"
              component={Terms}
            />
            <Route
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              path="/contact"
              component={Contact}
            />
            <PrivateRoute
              path="/profile"
              component={MyProfileStart}
            />
            <PrivateRoute
              path="/my-pages"
              component={MyPagesRouter}
            />
            </Switch>
        </InnerWrapper>
        <Footer />
        <Cookies />
      </Wrapper>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  authStoredUser: data => dispatch(authStoredUser(data)),
});

const mapStateToProps = state => ({
  showMobileMenu: state.menu.showMobileMenu,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
