import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Grid } from "react-bootstrap/lib";
import styled from "styled-components";
import { DeleteButton, SmallPinkButton } from "../../../../../components/Buttons";
import {
  BorderTable,
  GeneralError,
  ConfirmationModal
} from "../../../../../components";
import messages from "./../messages";
import { reduxForm } from "redux-form";

const StyledBorderTable = styled(BorderTable)`
  margin-bottom: 20px;
  font-size: 18px;
`;

const validate = values => null;

const DeleteListItemButton = ({ row, callback }) => {
  const onClick = () => {
    callback(row);
  };
  return (
    <DeleteButton onClick={onClick} />
  );
};

const StationOfferRow = ({ groupId, volunteers, status, offer, availableGroups, onDeleteRow, formatMessage }) => {
  let group = null;
  if (Array.isArray(availableGroups)) {
    group = availableGroups.find(g => g.groupId === groupId);
  }
  return (
    <tr key={groupId}>
      <td>{group ? group.group.name : (offer ? offer.group.name : "")}</td>
      <td>{volunteers}</td>
      <td>{formatMessage(messages.groupTable[status.toLowerCase()])}</td>
      <td className="no-background">
        <DeleteListItemButton
          row={offer}
          callback={onDeleteRow}
        />
      </td>
    </tr>
  )
};

class ViewVolunteers1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteGroupModal: false,
      deletingOffer: false,
      offerToDelete: false,
    };
    this.onDeleteGroupOffer = this.onDeleteGroupOffer.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.confirmStationOffers = this.confirmStationOffers.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }


  componentDidMount() {
    if (!Array.isArray(this.props.availableGroups)) {
      const { municipality, start, end, id } = this.props.station;
      this.props.fetchAvailableGroups({
        eventId: this.props.eventId,
        location: municipality,
        startDate: start,
        endDate: end,
        stationId: id
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((!this.props.deleteOfferError && nextProps.deleteOfferError) || (this.props.offers && (!nextProps.offers || this.props.offers.length > nextProps.offers.length))) {
      this.setState({
        showDeleteGroupModal: false,
        deletingOffer: false
      });
    }
  }

  onConfirmDelete() {
    this.props.deleteOffer({
      eventId: this.props.eventId,
      groupId: this.state.offerToDelete.groupId,
      stationId: this.state.offerToDelete.stationId
    });
    this.setState({
      deletingOffer: true
    });
  }

  onDeleteGroupOffer(row) {
    this.toggleModal();
    this.setState({
      offerToDelete: row,
    });
  }

  toggleModal() {
    this.setState({
      showDeleteGroupModal: !this.state.showDeleteGroupModal,
    });
  }

  confirmStationOffers() {
    if (this.props.offers) {
	const offers = this.props.offers.reduce((arr, offer) => {
	    const { groupId, stationId } = offer
	    return offer.status === "DRAFT" ? arr.concat({groupId, stationId}) : arr
	}, [])

	this.props.confirmStationOffers({
	    offers: offers,
	    eventId: this.props.eventId
	})
    }
  }

  render() {
    const { intl, station, offers = [], deleteOfferError, availableGroups } = this.props;
    return (
      <Grid fluid>
        <Row>
          <Col
            sm={12}
            md={12}
            style={{padding: "0px"}}
          >
            <StyledBorderTable>
              <thead>
                <tr>
                  <th><FormattedMessage {...messages.offers.groupName} /></th>
                  <th><FormattedMessage {...messages.offers.requestedQuantity} /></th>
                  <th><FormattedMessage {...messages.offers.status} /></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {offers.filter(offer => offer.stationId === station.id).map((offer, index) =>
                  <StationOfferRow
                    key={offer.groupId + index}
                    offer={offer}
                    {...offer}
                    availableGroups={(!availableGroups || !Array.isArray(availableGroups)) ? [] : availableGroups}
                    onDeleteRow={this.onDeleteGroupOffer}
                    formatMessage={intl.formatMessage}
                  />)}
              </tbody>
            </StyledBorderTable>
            {deleteOfferError ? (
              <GeneralError>{deleteOfferError.message}</GeneralError>) : null}
          </Col>
          <SmallPinkButton
            disabled={offers.find(offer => { return offer.status === "DRAFT" ? 1 : 0 } ) === undefined}
            type="button"
            className="submit rightPos"
            onClick={this.confirmStationOffers}
            label={intl.formatMessage(messages.saveButton)}
          />
        </Row>
        <ConfirmationModal
          label={this.props.intl.formatMessage(messages.cancelConfirmModalButton)}
          show={this.state.showDeleteGroupModal}
          handleConfirm={this.onConfirmDelete}
          handleHide={this.toggleModal}
          buttonText={this.props.intl.formatMessage(messages.confirmModalButton)}
          title={this.props.intl.formatMessage(messages.confirmModalTitle)}
          text={this.props.intl.formatMessage(messages.confirmModalText, { name: this.state.offerToDelete })}
          busy={this.state.deletingOffer}
          theme="pink"
        />

      </Grid>
    )
  }
}

let ViewVolunteers = reduxForm({
  form: "view-volunteers-modal",
  validate,
})(injectIntl(ViewVolunteers1));

export default ViewVolunteers;
