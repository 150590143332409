import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import {
  H2,
  P,
  GeneralError,
  Input,
  ContentWrapper
} from "../../../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import moment from "moment";
import GroupAttendance from "../GroupAttendance";

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

class StationAttendance extends Component {

  constructor(props) {
    super(props);

    this.state = {
      station: this.props.event.stations.find(s => s.id === parseInt(this.props.stationId, 10))
    };
  }

  componentDidMount() {
    this.props.fetchStationOffers({
      eventId: this.props.eventId,
      stationId: this.props.stationId
    });
  }

  renderOffers() {
    if (this.props.offerError) {
      return (
        <GeneralError>
          <FormattedMessage {...messages.attendance.offerError} />
        </GeneralError>
      )
    }

    if (!this.props.stationOffers) {
      return <i className="fas fa-spinner fa-pulse" />;
    }

    if (this.props.stationOffers.length === 0) {
      return (
        <P><FormattedMessage {...messages.attendance.noOffers} /></P>
      );
    }

    return (
      <Fragment>
        {this.props.stationOffers
        .filter(o => o.status === 'ACCEPTED')
        .map(o => <GroupAttendance
          key={o.groupId}
          offer={o}
          form={o.groupId}
          station={this.state.station}
        />)}
      </Fragment>
    );
  }

  render() {
    return (
      <Wrapper>
        <Row>
          <Col sm={12}>
            <H2>{this.state.station.name}</H2>
            <Input
              initialValue={this.state.station.name}
              placeholder={this.props.intl.formatMessage(messages.attendance.form.name)}
              readonly
              meta={{}}
            />
            <Input
              initialValue={moment(this.state.station.start).format("HH:mm")}
              placeholder={this.props.intl.formatMessage(messages.attendance.form.start)}
              readonly
              meta={{}}
            />
            <Input
              initialValue={moment(this.state.station.end).format("HH:mm")}
              placeholder={this.props.intl.formatMessage(messages.attendance.form.end)}
              readonly
              meta={{}}
            />
            <Input
              initialValue={this.state.station.officialQuantity}
              placeholder={this.props.intl.formatMessage(messages.attendance.form.officialQuantity)}
              readonly
              meta={{}}
            />
            <Input
              initialValue={this.state.station.officialMiniumumAge}
              placeholder={this.props.intl.formatMessage(messages.attendance.form.officialMinimumAge)}
              readonly
              meta={{}}
            />
          </Col>
        </Row>
        <ContentWrapper>
          <Row>
            <Col sm={12}>
              {this.renderOffers()}
            </Col>
          </Row>
        </ContentWrapper>
      </Wrapper>
    );
  }

}

export default injectIntl(StationAttendance);
