import React, { Component, Fragment } from "react";
import StationsEditList from "./StationsEditList";
import StationEditForm from "./StationEditForm";
import { Button } from "../../../../components/Buttons";
import StationNewForm from "./StationNewForm";
import { injectIntl } from "react-intl";
import messages from "./messages";
import moment from "moment";

class StationEditBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stations: this.props.event.stations ? this.props.event.stations : [],
      showEditForm: false,
      showNewForm: false,
      stationKey: null,
      groupPayment: false,
      compensationByAgreement: false
    };

    this.handleSubmitStation = this.handleSubmitStation.bind(this);
    this.onDeleteStation = this.onDeleteStation.bind(this);
    this.submitEvent = this.submitEvent.bind(this);
    this.onStationClick = this.onStationClick.bind(this);
    this.toggleEditForm = this.toggleEditForm.bind(this);
    this.showNewStationForm = this.showNewStationForm.bind(this);
    this.handlePaymentChange = this.handlePaymentChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchEventOffers({ eventId: this.props.event.eventId });
  }

  handlePaymentChange(PaymentTypeFieldValue) {
    switch (PaymentTypeFieldValue) {
      case "COMPENSATIONBYAGREEMENT":
        this.setState({
          groupPayment: false,
          compensationByAgreement: true
        });
        return;
      case "HOURLYRATE":
        this.setState({
          groupPayment: false,
          compensationByAgreement: false
        });
        return;
      case "GROUPPAYMENT":
        this.setState({
          groupPayment: true,
          compensationByAgreement: false
        });
        return;
      case "PERPERSON":
        this.setState({
          groupPayment: false,
          compensationByAgreement: false
        });
        return;
      case "NOCOMPENSATION":
        this.setState({
          groupPayment: false,
          compensationByAgreement: true
        });
        return;
      default:
        return;
    }
  }

  showNewStationForm() {
    this.setState({
      showNewForm: true,
      showEditForm: false
    });
  }

  toggleEditForm() {
    if (!this.state.showEditForm) {
      this.setState({
        showEditForm: true,
        showNewForm: false
      });
    } else {
      this.setState({
        showEditForm: false
      });
    }
  }

  scrollToForm() {
    let elem = document.getElementById("station-edit-form");

    if (elem && window.scroll) {
      let offsetTop = 0;
      do {
        if (!isNaN(elem.offsetTop)) {
          offsetTop += elem.offsetTop;
        }
        // eslint-disable-next-line no-cond-assign
      } while ((elem = elem.offsetParent));

      window.scroll({
        top: offsetTop - 20,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  onStationClick(key, row) {
    this.handlePaymentChange(row.paymentType);
    const currentKey = this.state.stationKey;
    this.setState(
      {
        stationKey: key,
        showEditForm: true,
        showNewForm: false
      },
      () => {
        // Only scroll if user clicks on a different station
        if (currentKey !== key) {
          this.scrollToForm();
        }
      }
    );
  }

  submitEvent(stationList) {
    this.setState({
      stations: stationList
    });
    this.props.submitEvent({
      ...this.props.event,
      stations: stationList
    });
  }

  handleSubmitStation(station) {
    this.submitEvent([
      ...this.state.stations,
      {
        ...station,
        id: Math.floor(Math.random() * 10000)
      }
    ]);
  }

  onDeleteStation(stationsList) {
    this.setState({
      showEditForm: false
    });
    this.submitEvent(stationsList);
  }

  render() {
    const initialValues = {
      start: moment(this.props.event.date)
        .set({
          hour: 8,
          minute: 0
        })
        .format("YYYY-MM-DD HH:mm"),
      end: moment(this.props.event.date)
        .set({
          hour: 18,
          minute: 0
        })
        .format("YYYY-MM-DD HH:mm")
    };

    return (
      <Fragment>
        <StationsEditList
          {...this.props}
          onStationClick={this.onStationClick}
          onDeleteStation={this.onDeleteStation}
        />
        {this.props.isTeamleaderView ? null : (
          <Button
            type="button"
            onClick={() => this.showNewStationForm()}
            label={this.props.intl.formatMessage(
              messages.stationEdit.addNewStationButton
            )}
          />
        )}
        {this.state.showEditForm ? (
          <StationEditForm
            {...this.props}
            handlePaymentChange={this.handlePaymentChange}
            groupPayment={this.state.groupPayment}
            compensationByAgreement={this.state.compensationByAgreement}
            stationKey={this.state.stationKey}
            toggleEditForm={this.toggleEditForm}
          />
        ) : null}
        {this.state.showNewForm ? (
          <StationNewForm
            {...this.props}
            onSubmitStation={this.handleSubmitStation}
            initialValues={initialValues}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default injectIntl(StationEditBase);
