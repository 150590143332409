import { call, put } from 'redux-saga/effects';
import { getUserByEmail, createUser } from "./service";
import { GET_USER_BY_EMAIL_SUCCESS, GET_USER_BY_EMAIL_FAILED, CREATE_USER_SUCCESS, CREATE_USER_FAILED } from "./reducer";

export function* getUserByEmailSaga (action) {
  try {
    const response = yield call(() => getUserByEmail(action.payload));
    yield put({type: GET_USER_BY_EMAIL_SUCCESS, payload: response});
  } catch (e) {
    yield put({type: GET_USER_BY_EMAIL_FAILED, payload: e});
  }
}

export function* createUserSaga (action) {
  try {
    yield call(() => createUser(action.payload));
    yield put({type: CREATE_USER_SUCCESS, payload: action.payload});
  } catch (e) {
    yield put({type: CREATE_USER_FAILED, payload: e});
  }
}