import React, { Component, Fragment } from "react";
import { ContentWrapper, Container, ProgressBar } from "../../../../components/index";
import { Row, Col } from "react-bootstrap/lib";
import GroupForm from "../GroupForm";
import GroupConfirm from "../GroupConfirm";
import Volunteers from "../Volunteers";
import { colors } from "../../../../constants/styling-constants";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { groupStatus } from "../reducer";
import {
  H1,
  P
} from "../../../../components";
import GroupAvailability from "../GroupAvailability";

const states = [
  {
    step: 0,
    name: <FormattedMessage {...messages.progressBar.registerGroup} />,
  },
  {
    step: 1,
    name: <FormattedMessage {...messages.progressBar.registerAvailability} />,
  },
  {
    step: 2,
    name: <FormattedMessage {...messages.progressBar.registerVolunteers} />,
  },
];

class GroupNewBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      clickedNext: false,
    };


    this.goToStep = this.goToStep.bind(this);
    this.goToNextStep = this.goToNextStep.bind(this);
    this.handleGroupSubmit = this.handleGroupSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === groupStatus.SUCCESS && this.props.status === groupStatus.SUBMITTING && this.state.clickedNext) {
      this.goToStep(this.state.currentStep + 1);
      this.setState({
        clickedNext: false,
      });
    }
  }

  goToStep(step) {
    if (step >= 0 && step <= states.length) {
      this.setState({
        currentStep: step,
      });

      if (states[step].link) {
        this.props.history.push(states[step].link);
      }
    }
  }

  goToNextStep() {
    const nextStep = this.state.currentStep + 1;
    this.goToStep(nextStep);
  }

  handleGroupSubmit() {
    this.setState({
      clickedNext: true,
    });
  }

  handleAvailabilitySubmit(data) {
    if (this.props.group && this.props.group.id) {
      const updatedGroup = Object.assign({}, this.props.group, { availabilities: data });
      this.props.submitGroup(updatedGroup);
    }
  }

  renderGroupForm() {
    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1><FormattedMessage {...messages.groupForm.title} /></H1>
            <P><FormattedMessage {...messages.groupForm.text} /></P>
          </Col>
        </Row>
        <GroupForm {...this.props} onSubmit={this.handleEventSubmit}
                   onClickNext={this.handleGroupSubmit}
        />
      </Fragment>
    );
  }

  renderVolunteers() {
    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1><FormattedMessage {...messages.volunteers.title} /></H1>
            <P><FormattedMessage {...messages.volunteers.text} /></P>
          </Col>
        </Row>
        <Volunteers {...this.props} onSubmit={this.handleConfirmSubmit}
                    onClickNext={this.goToStep}
                    isNew
        />
      </Fragment>
    );
  }

  renderConfirmation() {
    return (
      <Fragment>
        <Row>
          <Col sm={9}>
            <H1><FormattedMessage {...messages.confirmation.title} /></H1>
            <P><FormattedMessage {...messages.confirmation.text} /></P>
          </Col>
        </Row>
        <GroupAvailability
          onSubmit={this.handleAvailabilitySubmit}
          onClickNext={this.goToStep}
          groupId={this.props.group && this.props.group.id}
        />
        <GroupConfirm {...this.props} onSubmit={this.handleConfirmSubmit}
                      onClickNext={this.goToStep}
                      isNew
        />
      </Fragment>
    );
  }

  renderPage() {
    return [
      this.renderGroupForm,
      this.renderConfirmation,
      this.renderVolunteers,
    ][this.state.currentStep].call(this);
  }

  render() {
    return (
      <ContentWrapper>
        <Container>
          <Row>
            <Col sm={9}>
              <ProgressBar
                states={states}
                bottomBarBg={colors.lighterGreen}
                topBarBg={colors.darkGreen}
                stepNotActiveBorder={colors.lighterGreen}
                stepNotActiveColor={colors.lighterGreen}
                wrapperBorder={colors.darkGreen}
                stepColor={colors.darkGreen}
                wrapperBg={colors.lightGreen}
                currentStep={this.state.currentStep}
                goToStep={this.goToStep}
              />
            </Col>
          </Row>
          {this.renderPage()}
        </Container>
      </ContentWrapper>
    );
  }
}

export default injectIntl(GroupNewBase);
