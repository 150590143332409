import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { Input, WhiteP } from "../../../components/index";
import { SmallPinkButton } from "../../../components/Buttons";
import { SmallGrayLinkButton } from "../../../components/Links";
import { required } from "../../../validators/index";
import Wrapper from "../Components/Wrapper";
import ButtonWrapper from "../Components/ButtonWrapper";
import ReminderModal from "../../../components/ReminderModal";
import messages from "./messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { status } from "./reducer";
import { colors } from "../../../constants/styling-constants";
import styled from "styled-components";

const StyledError = styled.p`
  color: ${colors.pink};
`;

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
    this.props.resetState();
    this.state = {
      showModal: null,
      verifyRedirect: false
    };
  }

  handleHideModal() {
    this.setState({
      showModal: false
    });
  }

  renderError() {
    if (!this.props.submitError) return null;

    const errorMessage = messages[this.props.submitError.code];
    const message = errorMessage || messages.genericError;

    if (this.props.submitError.code === "InvalidParameterException" && this.state.showModal == null) {
      this.setState({
        showModal: true
      });
    }

    return (
      <StyledError>
        <FormattedMessage {...message} />
      </StyledError>
    );
  }

  handleSubmit(data) {
    if (this.props.status === status.CODE_SENT) {
      this.props.resetPasswordSubmit(data);
    } else {
      this.props.sendForgotPasswordCode(data);
    }
  }

  render() {
    if (this.props.status === status.NEW_PASSWORD_SET) {
      return <Redirect to="/login?password-reset=true" />;
    }
    if (this.state.verifyRedirect) {
      return <Redirect to="/verify-email" />;
    }
    return (
      <Wrapper>
        <WhiteP>
          <FormattedMessage {...messages.explainingMessage} />
        </WhiteP>
        <form onSubmit={this.props.handleSubmit(this.handleSubmit)} noValidate>
          <Field
            name="email"
            placeholder={this.props.intl.formatMessage(
              messages.email.placeholder
            )}
            type="email"
            component={Input}
            validate={[required]}
          />
          {this.props.status === "CODE_SENT" ? (
            <Field
              name="code"
              placeholder={this.props.intl.formatMessage(
                messages.code.placeholder
              )}
              type="number"
              component={Input}
              validate={[required]}
            />
          ) : null}
          {this.props.status === "CODE_SENT" ? (
            <Field
              name="newPassword"
              placeholder={this.props.intl.formatMessage(
                messages.password.placeholder
              )}
              type="password"
              component={Input}
              validate={[required]}
            />
          ) : null}
          {this.props.status === "CODE_SENT" ? (
            <WhiteP>
              <FormattedMessage {...messages.codeSent} />
            </WhiteP>
          ) : null}
          <ReminderModal
            show={this.state.showModal}
            title={this.props.intl.formatMessage(messages.verifyModal.title)}
            text={this.props.intl.formatMessage(messages.verifyModal.body)}
            buttonText={this.props.intl.formatMessage(messages.verifyModal.redirectButton)}
            handleConfirm={() => this.setState({verifyRedirect: true})}
            handleHide={this.handleHideModal}
            theme={'pink'}
          />
          {this.renderError()}
          <ButtonWrapper>
            <SmallPinkButton
              label={this.props.intl.formatMessage(messages.sendCodeButton)}
            />
            <SmallGrayLinkButton to="/login">
              <FormattedMessage {...messages.loginButton} />
            </SmallGrayLinkButton>
          </ButtonWrapper>
        </form>
      </Wrapper>
    );
  }
}

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired, // Redux form function that will call this.props.onSubmit if form is valid
  status: PropTypes.string.isRequired
};

export default injectIntl(
  reduxForm({
    form: "forgotPassword"
  })(ForgotPassword)
);
